import { ajax } from 'rxjs/ajax';
import queryString from 'query-string';

const getDefaultHeaders = () => {
  let defaultHeaders = {};
  // Todo its bad, need other way to get token
  const persistedData = localStorage.getItem('persist:root');
  const data = JSON.parse(persistedData);
  const { token } = JSON.parse(data.auth);

  if (token) defaultHeaders = { Authorization: `Bearer ${token}` };

  return defaultHeaders;
};

export const get = (url, params, headers) => {
  const defaultHeaders = getDefaultHeaders();
  const stringified = queryString.stringify(params);
  const modifiedUrl = params ? `${url}?${stringified}` : url;

  return ajax.get(modifiedUrl, { ...headers, ...defaultHeaders });
};

export const post = (url, body, headers) => {
  const defaultHeaders = getDefaultHeaders();
  return ajax.post(url, body, { ...headers, ...defaultHeaders });
};

export const put = (url, body, headers) => {
  const defaultHeaders = getDefaultHeaders();
  return ajax.put(url, body, { ...headers, ...defaultHeaders });
};

export const remove = (url, params, headers) => {
  const defaultHeaders = getDefaultHeaders();
  const stringified = queryString.stringify(params);
  const modifiedUrl = params ? `${url}?${stringified}` : url;

  return ajax.delete(modifiedUrl, { ...headers, ...defaultHeaders });
};
