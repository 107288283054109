import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Login from './Login';
import { authActions } from '../../reducers/auth';
import rules from './rules';
import apiRoutes from '../../services/api.routes';

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  fetchUser: authActions.fetchUser,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withFormik({
    mapPropsToValues: () => ({
      username: '',
      password: '',
    }),
    validationSchema: rules,
    validateOnChange: false,
    handleSubmit: (values, {
      props: { fetchUser },
      setSubmitting,
      resetForm,
    }) => {
      fetchUser(values, {
        url: apiRoutes.generate2FACode,
        setSubmitting,
        resetForm,
      });
    },
    displayName: 'LogIn',
  }),
);

export default withRouter(hoistStatics(enhance)(Login));
