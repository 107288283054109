import reducer from './reducers';
import * as uploadEpics from './epics';

export {
  uploadEpics,
};

export { default as uploadSelectors } from './selectors';
export { default as uploadActions } from './actions';

export default reducer;
