import { compose, hoistStatics, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withFormik } from 'formik/dist/index';
import { toast } from 'react-toastify';
import { pipe, map, values as Rvalues, find, propEq } from 'ramda';

import NewEmail from './NewEmail';
import apiRoutes from '../../../services/api.routes';
import mailsActions from '../../../reducers/mails/actions';
import { emailTemplatesActions, emailTemplatesSelectors } from '../../../reducers/emailTemplates';
import rules from './rules';

const { getEmailTemplatesEntities } = emailTemplatesSelectors;
const { fetchEmailTemplates } = emailTemplatesActions;

const mapDispatchToProps = {
  sendMail: mailsActions.sendMail,
  fetchUserEmail: mailsActions.fetchUserEmail,
  fetchEmailTemplates,
};

const mapStateToProps = (state) => {
  const templatesList = pipe(
    getEmailTemplatesEntities,
    Rvalues,
  )(state);

  const options = map(({ subject, id }) => ({
    label: subject,
    value: id,
  }), templatesList);

  return {
    users: state.mails.users,
    loading: state.mails.loading,
    templatesList,
    options,
  };
};

const loadOptions = ({ fetchUserEmail, users, loading }) => (inputValue, callback) => {
  fetchUserEmail({ userName: inputValue }, { url: apiRoutes.searchUserByUserName });

  if (!loading) {
    callback(users);
  }
};

const handleInputChange = ({ setFieldValue }) => ({ value }, { name }) => {
  setFieldValue(name, value);
};

const handleSubjectSelect = ({ setFieldValue, templatesList }) => ({ value }, { name }) => {
  setFieldValue(name, value);
  const text = find(propEq('id', parseInt(value, 10)), templatesList);
  if (text) setFieldValue('text', text.body);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withFormik({
    mapPropsToValues: () => ({
      text: '',
      subject: '',
      to: '',
      from: 'admin@cdnow.info', // Todo: user email
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { sendMail },
      setSubmitting,
      resetForm,
    }) => {
      sendMail(values, {
        url: apiRoutes.sendEmail,
        setSubmitting,
        resetForm,
        toast,
      });
    },
  }),
  withHandlers({
    loadOptions,
    handleInputChange,
    handleSubjectSelect,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchEmailTemplates();
    },
  }),
);

export default hoistStatics(enhance)(NewEmail);
