import { compose, hoistStatics, lifecycle } from 'recompose';
import { withFormik } from 'formik';
import User from '../../../user/User';
import ModelService from '../../../../../services/user.service';
import canUpdate from '../../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../../services/api.routes';
import rules from '../../../user/rules';
import withUser from '../../../user';
import FirstLoading from '../../../../../components/FirstLoading';

const enhance = compose(
  withUser,
  withFormik({
    mapPropsToValues: ({ user }) => ({
      username: user.username,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      company_name: user.companyName,
      phone: user.phone,
      password: '',
      rolesList: user.rolesList,
      admin_language: user.adminLanguage,
      status: user.status,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { updateCdUser, userId },
      setSubmitting,
    }) => {
      const rolesList = JSON.stringify(values.rolesList);

      updateCdUser({ ...values, rolesList }, {
        url: `${apiRoutes.users}/${userId}`,
        setSubmitting,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchCdUser, userId, fetchLanguages } = this.props;

      fetchCdUser(null, {
        url: `${apiRoutes.users}/${userId}`,
        firstLoading: true,
      });

      fetchLanguages(null, {
        url: `${apiRoutes.languages}/all`,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  canUpdate(ModelService),
);

export default (hoistStatics(enhance)(User));
