import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Document from './components/document';
import DocumentModal from './components/documentModal/DocumentModal';

const Documents = ({
  t, files, toggle, toggleModal,
  getImageData, imageData, token, changeStatus, url,
}) => (
  <Card>
    <CardHeader>
      <strong><i className="fa fa-file-o pr-2" />{t('Documents')}</strong>
    </CardHeader>
    <CardBody className="documents">
      {files.result.map(value => (
        <Document
          t={t}
          key={value}
          data={files.entities[value]}
          token={token}
          changeStatus={changeStatus}
          getImageData={getImageData}
          toggle={toggle}
          toggleModal={toggleModal}
          url={url}
        />
      ))}

      <DocumentModal
        t={t}
        token={token}
        toggle={toggle}
        changeStatus={changeStatus}
        imageData={imageData}
        toggleModal={toggleModal}
        url={url}
      />
    </CardBody>
  </Card>
);

Documents.propTypes = {
  t: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  toggle: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getImageData: PropTypes.func.isRequired,
  imageData: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

export default Documents;
