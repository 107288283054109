import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchSanctionedBySwitzerland,
  fetchSanctionedBySwitzerlandSuccess,
  fetchSanctionedBySwitzerlandRejected,

  noMatch, noMatchSuccess, noMatchRejected,
} = createActions(
  {
    [types.FETCH_SANCTIONED_BY_SWITZERLAND]: [
      identity,
      nthArg(1),
    ],
    [types.NO_MATCH]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_SANCTIONED_BY_SWITZERLAND_SUCCESS,
  types.FETCH_SANCTIONED_BY_SWITZERLAND_REJECTED,
  types.NO_MATCH_SUCCESS,
  types.NO_MATCH_REJECTED,
);

export default {
  fetchSanctionedBySwitzerland,
  fetchSanctionedBySwitzerlandSuccess,
  fetchSanctionedBySwitzerlandRejected,

  noMatch,
  noMatchSuccess,
  noMatchRejected,
};
