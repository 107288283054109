import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { exportUsersActions } from './index';

export const exportUsersEpic = action$ => action$.pipe(
  ofType(types.EXPORT_USERS),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      toast.success('Export success');
      return exportUsersActions.exportUsersSuccess(response);
    }),
    catchError((error) => {
      toast.error('Error');

      return of(exportUsersActions.exportUsersRejected(error));
    }),
  )),
);

export const downloadFileEpic = action$ => action$.pipe(
  ofType(types.DOWNLOAD_FILE),
  mergeMap(action => ajax.get(`${apiUrl}/getExportFile`, getPayload(action)).pipe(
    map((response) => {
      window.location.href = response.request.url;

      return exportUsersActions.downloadFileSuccess;
    }),
    catchError(error => console.log('error', error) || of(error)),
  )),
);
