import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';


const XlsxForm = ({
  t, errors, isSubmitting, handleSubmit, setFieldValue,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col>
        <div className="form-group">
          <label htmlFor="file">{t('File upload. Only .xlsx')}</label>
          <input
            id="file"
            name="file"
            type="file"
            onChange={(event) => {
              setFieldValue('file', event.currentTarget.files[0]);
            }}
            className="form-control"
            style={{ height: '100%' }}
          />
          <div className="invalid-feedback">{errors.file}</div>
        </div>
      </Col>
    </Row>
    <Button
      disabled={isSubmitting}
      type="submit"
      color="success"
      className="mr-2 mb-1 btn-brand"
    >
      <i className="fa fa-save mr-2" /> {t('Apply')}
    </Button>
  </form>
);

XlsxForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default XlsxForm;
