import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../helpers/state.helper';

const initialState = {
  tempFiles: {},
  mulTempFiles: {},
};

const updateTempFile = (state, action) => {
  const { payload: { id, data } } = action;
  return {
    ...state,
    tempFiles: {
      ...state.tempFiles,
      [id]: {
        ...state.tempFiles[id],
        ...data,
      },
    },
  };
};

const removeTempFile = (state, action) => {
  return {
    ...state,
    tempFiles: {
      state: state.tempFiles,
      [action.payload]: undefined,
    },
  };
};

const reducer = handleActions(
  {
    [types.UPDATE_TEMP_FILE]: updateTempFile,
    [types.REMOVE_TEMP_FILE]: removeTempFile,
    [types.CLEAR_ALL_TEMP_FILES]: mergeIn(() => initialState),
  },
  initialState,
);

export default reducer;
