import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik/dist/index';
import { toast } from 'react-toastify';
import apiRoutes from '../../../../services/api.routes';
import GeneralForm from './GeneralForm';
import rules from './rules';

const enhance = compose(withFormik({
  mapPropsToValues: ({ data }) => ({
    email: data.email,
    domain: data.domain,
  }),
  validationSchema: rules,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: (values, {
    props: { updateSettings },
    setSubmitting,
  }) => {
    updateSettings(values, {
      url: `${apiRoutes.settings}/admin/admin`,
      setSubmitting,
      toast,
    });
  },
  displayName: 'GeneralForm',
}));

export default hoistStatics(enhance)(GeneralForm);
