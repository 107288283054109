import * as emailTemplatesActions from './actions';
import * as emailTemplatesSelectors from './selectors';
import * as emailTemplatesEpics from './epics';
import reducer from './reducers';

export default reducer;

export {
  emailTemplatesActions,
  emailTemplatesSelectors,
  emailTemplatesEpics,
};
