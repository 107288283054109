const NEW = 0;
const APPROVE = 1;
const DECLINE = 2;
const OLD = 3;

module.exports = {
  NEW,
  APPROVE,
  DECLINE,
  OLD,
};
