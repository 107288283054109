import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AppSwitch } from '@coreui/react';


const Settings = ({
  t, managed, netWorthOption, updateExternalUserAttribute,
  matchParams, getUsersCount,
}) => (
  <Card>
    <CardHeader>
      <strong>
        <i className="fa fa-cog pr-2" />
        {t('Settings')}
      </strong>
    </CardHeader>
    <CardBody className="user-settings">
      <div className="aside-options">
        <div className="clearfix">
          <small><b>{t('Managed option')}</b></small>
          <AppSwitch
            className="float-right"
            variant="pill"
            label
            color="success"
            dataOn="Yes"
            dataOff="No"
            checked={!!managed}
            onClick={() => {
              updateExternalUserAttribute(
                'managed',
                !managed ? 1 : 0,
                `/external-users/check-user/${matchParams.id}`,
              );
              getUsersCount(null, { url: 'checkedUsersOverview' });
            }}
          />
        </div>
        <div>
          <small className="text-muted">
            {t(`Please indicate if the user has been checked or still
                needs to be reviewed. After an update of data the system
                automatically puts the user status to "not managed".`)}
          </small>
        </div>
      </div>

      <div className="aside-options">
        <div className="clearfix mt-3">
          <small><b>{t('Net worth option')}</b></small>
          <AppSwitch
            className="float-right"
            variant="pill"
            label
            color="success"
            dataOn="High"
            dataOff="Low"
            checked={!!netWorthOption}
            onClick={() => updateExternalUserAttribute(
              'net_worth_option',
              !netWorthOption ? 1 : 0,
              `/external-users/update-attribute/${matchParams.id}`,
            )}
          />
        </div>
        <div>
          <small className="text-muted">
            {t(`Please indicate if the user has been checked for
                "low" or "high" net-worth transactions.A user which
                was indicated to be checked for high net-worth transaction
                may deposit and withdraw more then CHF 5000.`)}
          </small>
        </div>
      </div>
    </CardBody>
  </Card>
);

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  updateExternalUserAttribute: PropTypes.func.isRequired,
};

export default Settings;
