import { HOC } from 'react-redux-permissions';
import { renderNothing } from 'recompose';
import { createSelector } from 'reselect';
import { prop, all, includes, memoizeWith, __, identity } from 'ramda';

const withPermissions = (
  allowed = [],
  except = [],
  fallbackComponent = renderNothing,
) => Component => HOC(allowed, except)(Component, fallbackComponent);

const hasPermissions = createSelector(
  prop('permissions'),
  permissions => memoizeWith(identity, all(includes(__, permissions))),
);

export {
  withPermissions,
  hasPermissions,
};
