import React from 'react';
import { compose, hoistStatics, withHandlers, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import _ from 'lodash';
import ExportUsers from './ExportUsers';
import apiRoutes from '../../services/api.routes';
import FirstLoading from '../../components/FirstLoading';
import { exportUsersActions } from '../../reducers/exportUsers';
import fileFormat from './fileFormat';


const getColumns = ({
 t, markedUsers, removeUser, setUser,
}) => () => [
  {
    id: 1,
    name: 'username',
    title: '',
    value: ({ item }) => (
      <input
        type="checkbox"
        onChange={() => {
          const includesId = _.includes(markedUsers, item.id);

          if (includesId) {
            removeUser(item.id);
          } else if (markedUsers.length < 5) {
            setUser(item.id);
          } else {
            toast.error(t('You can chose max 5 users'));
          }
        }}
        checked={_.includes(markedUsers, item.id)}
      />),
    filter: false,
  },
  {
    id: ({ item }) => item.id,
    name: 'id',
    title: t('ID'),
  },
  {
    id: ({ item }) => item.id,
    name: 'username',
    title: t('Username'),
  },
  {
    id: ({ item }) => item.id,
    name: 'date_of_registration',
    title: t('Date registration'),
  },

];

const handleInputChange = props => (event) => {
  const { target } = event;
  const { name } = target;
  const value = target.type === 'checkbox' ? target.checked : target.value;

  props.setDate(name, value);
};

const handleSubmit = props => (event) => {
  event.preventDefault();
  const {
    markedUsers, personalData, transactions, emails, docs, dateFrom, dateTo, dataFormat,
  } = props;

  const params = {
    userIds: markedUsers,
    personalData,
    transactions,
    emails,
    docs,
    dateFrom,
    dateTo,
    exportType: dataFormat,
  };

  props.exportData(params, { url: apiRoutes.exportUsers });
};

const mapStateToProps = ({ auth, externalUsers, exportUsers }) => ({
  data: externalUsers.data,
  link: exportUsers.link,
  token: auth.token,
});

const mapDispatchToProps = {
  // fetchAllExternalUsers: externalUsersActions.fetchAllExternalUsers,
  exportData: exportUsersActions.exportUsers,
  downloadFile: exportUsersActions.downloadFile,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withStateHandlers(() => ({
    step: 1,
    markedUsers: [],
    dateFrom: '',
    dateTo: '',
    personalData: false,
    transactions: false,
    emails: false,
    docs: false,
    dataFormat: fileFormat.XLSX,
  }), {
    changeStep: () => step => ({ step }),
    setUser: state => id => ({
      markedUsers: [...state.markedUsers, id],
    }),
    removeUser: state => id => ({
      markedUsers: state.markedUsers.filter(item => item !== id),
    }),
    setDate: () => (name, value) => ({ [name]: value }),
  }),
  FirstLoading,
  withHandlers({
    handleSubmit,
    handleInputChange,
    getColumns,
  }),
);

export default hoistStatics(enhance)(ExportUsers);
