import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchCdUser, fetchCdUserSuccess, fetchCdUserRejected,
  createCdUser, createCdUserSuccess, createCdUserRejected,
  updateCdUser, updateCdUserSuccess, updateCdUserRejected,
  deleteCdUser, deleteCdUserSuccess, deleteCdUserRejected,
  uploadCdUserImage, uploadCdUserImageSuccess, uploadCdUserImageRejected,
  addDocumentToUser, addDocumentToUserSuccess, addDocumentToUserRejected,
} = createActions(
  {
    [types.FETCH_CD_USER]: [
      identity,
      nthArg(1),
    ],
    [types.CREATE_CD_USER]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_CD_USER]: [
      identity,
      nthArg(1),
    ],
    [types.DELETE_CD_USER]: [
      identity,
      nthArg(1),
    ],
    [types.UPLOAD_CD_USER_IMAGE]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_CD_USER_SUCCESS,
  types.FETCH_CD_USER_REJECTED,
  types.CREATE_CD_USER_SUCCESS,
  types.CREATE_CD_USER_REJECTED,
  types.UPDATE_CD_USER_SUCCESS,
  types.UPDATE_CD_USER_REJECTED,
  types.DELETE_CD_USER_SUCCESS,
  types.DELETE_CD_USER_REJECTED,
  types.UPLOAD_CD_USER_IMAGE_SUCCESS,
  types.UPLOAD_CD_USER_IMAGE_REJECTED,

  types.ADD_DOCUMENT_TO_USER,
  types.ADD_DOCUMENT_TO_USER_SUCCESS,
  types.ADD_DOCUMENT_TO_USER_REJECTED,
);

export default {
  fetchCdUser,
  fetchCdUserSuccess,
  fetchCdUserRejected,

  createCdUser,
  createCdUserSuccess,
  createCdUserRejected,

  updateCdUser,
  updateCdUserSuccess,
  updateCdUserRejected,

  deleteCdUser,
  deleteCdUserSuccess,
  deleteCdUserRejected,

  uploadCdUserImage,
  uploadCdUserImageSuccess,
  uploadCdUserImageRejected,

  addDocumentToUser,
  addDocumentToUserSuccess,
  addDocumentToUserRejected,
};
