import apiRoutes from './api.routes';
import ModelService from './model.service';
import api from './api.service';

const DashboardService = new ModelService(apiRoutes.dashboard, '/dashboard', 'dashboard');

const getSanctionedUsers = async params => api.catchError(async () => {
  const { data } = await api.get(`${apiRoutes.dashboard}/getSanctionedUsers`, params);
  return data;
});

const getOverviewGraphData = async params => api.catchError(async () => {
  const { data } = await api.get(`${apiRoutes.dashboard}/getOverviewGraphData`, params);
  return data;
});

export default {
  DashboardService,
  getSanctionedUsers,
  getOverviewGraphData,
};
