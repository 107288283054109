import { createActions } from 'redux-actions';
import types from './types';

const {
  updateTempFile, updateTempFileSuccess, updateTempFileRejected,
  removeTempFile, removeTempFileSuccess, removeTempFileRejected,
  clearAllTempFiles,
} = createActions(
  types.UPDATE_TEMP_FILE,
  types.UPDATE_TEMP_FILE_SUCCESS,
  types.UPDATE_TEMP_FILE_REJECTED,
  types.REMOVE_TEMP_FILE,
  types.REMOVE_TEMP_FILE_SUCCESS,
  types.REMOVE_TEMP_FILE_REJECTED,
  types.CLEAR_ALL_TEMP_FILES,
);

export default {
  updateTempFile,
  updateTempFileSuccess,
  updateTempFileRejected,
  removeTempFile,
  removeTempFileSuccess,
  removeTempFileRejected,
  clearAllTempFiles,
};
