import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { settingsActions } from './index';

export const fetchSettingsEpic = action$ => action$.pipe(
  ofType(types.FETCH_SETTINGS),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => settingsActions.fetchSettingsSuccess(response)),
    catchError(error => of(settingsActions.fetchSettingsRejected(error))),
  )),
);

export const updateSettingsEpic = action$ => action$.pipe(
  ofType(types.UPDATE_SETTINGS),
  mergeMap(action => ajax.put(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('Settings update');

      return settingsActions.updateSettingsSuccess(response);
    }),
    catchError((error) => {
      action.meta.setSubmitting(false);
      toast.error(error.message);

      return of(settingsActions.updateSettingsRejected(error));
    }),
  )),
);

export const uploadXlsxFileEpic = action$ => action$.pipe(
  ofType(types.UPLOAD_XLSX_FILE),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('File uploaded success');
      return settingsActions.uploadXlsxFileSuccess(response);
    }),
    catchError((response) => {
      action.meta.setSubmitting(false);
      toast.error(response.status === 404 ? 'Only xlsx' : response.response.text);

      return of(settingsActions.uploadXlsxFileRejected());
    }),
  )),
);

export const fetchLanguagesEpic = action$ => action$.pipe(
  ofType(types.FETCH_LANGUAGES),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => settingsActions.fetchLanguagesSuccess(response)),
    catchError(error => of(settingsActions.fetchLanguagesRejected(error))),
  )),
);
