export default {
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_REJECTED: 'FETCH_USER_REJECTED',

  LOG_IN: 'LOG_IN',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_REJECTED: 'LOG_IN_REJECTED',

  LOG_OUT: 'LOG_OUT',
  LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',
  LOG_OUT_REJECTED: 'LOG_OUT_REJECTED',

  GENERATE_2FA_CODE: 'GENERATE_2FA_CODE',
  GENERATE_2FA_CODE_SUCCESS: 'GENERATE_2FA_CODE_SUCCESS',
  GENERATE_2FA_CODE_REJECTED: 'GENERATE_2FA_CODE_REJECTED',

  CLEAR_USER: 'CLEAR_USER',
};
