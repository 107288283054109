import { connect } from 'react-redux';
import { compose, hoistStatics, withHandlers, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import UserService from '../../services/user.service';

const mapStateToProps = state => ({
  user: state.auth.user.data,
});

const enhance = compose(
  connect(mapStateToProps, null),
  translate('core'),
  defaultProps({
    getAvatar: UserService.getAvatar,
  }),
  withHandlers({
    onClickLogout: props => () => {
      props.history.push('/logout');
    },
    onClickProfile: props => () => {
      props.history.push('/profile');
    },
  }),
);

export default withRouter(hoistStatics(enhance)(Header));
