import { createSelector } from 'reselect';
import { prop, identity, pipe, memoizeWith, __ } from 'ramda';

const emailTemplates = createSelector(
  prop('emailTemplates'),
  identity,
);

const getEmailTemplatesList = createSelector(
  emailTemplates,
  prop('result'),
);

const getEmailTemplatesEntities = createSelector(
  emailTemplates,
  prop('entities'),
);

const getEmailTemplate = createSelector(
  pipe(
    emailTemplates,
    prop('entities'),
  ),
  entities => memoizeWith(identity, prop(__, entities)),
);

export {
  getEmailTemplatesList,
  getEmailTemplatesEntities,
  getEmailTemplate,
};
