import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import WhiteListUsers from './WhiteListUsers';
import columnTypes from '../../../components/cms/grid/columnTypes';
import gc from '../../../components/cms/gridComponents';
import whiteListUserService from '../../../services/whiteListUser.service';
import HandleBadge from '../handleBadge';
import SanctionCheckLabel from '../sanctionCheckLabel';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (
      <Link to={whiteListUserService.getUpdateUrl(item.id, item.compliance_status)}>{item.id}</Link>
    ),
  },
  {
    name: 'first_name',
    title: t('First name'),
    filter: false,
  },
  {
    name: 'last_name',
    title: t('Last name'),
    filter: false,
  },
  {
    name: 'email',
    title: t('Email'),
  },
  {
    name: 'addresses',
    title: t('Countries'),
    value: ({ item }) => (
      item.addresses.map(value =>
        <Badge key={value.id} color="primary" className="mr-1" >{value.country}</Badge>)
    ),
    filter: false,
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    name: 'managed',
    title: t('Status'),
    value: ({ item }) => <HandleBadge t={t} status={item.managed} />,
    filter: false,
  },
  {
    name: 'sanction_check',
    title: t('Sanction check'),
    value: ({ item }) => <SanctionCheckLabel user={item} />,
    filter: false,
  },
  {
    name: 'files',
    title: t('Documents'),
    value: ({ item }) => (
      item.files.length !== 0 ?
        <Badge color="primary" >{t('New')}</Badge> :
        <Badge color="success">{t('No new documents')}</Badge>
    ),
    filter: false,
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('core'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(WhiteListUsers);
