export default {
  FETCH_EXTERNAL_USER: 'FETCH_EXTERNAL_USER',
  FETCH_EXTERNAL_USER_SUCCESS: 'FETCH_EXTERNAL_USER_SUCCESS',
  FETCH_EXTERNAL_USER_REJECTED: 'FETCH_EXTERNAL_USER_REJECTED',

  FETCH_EXTERNAL_USER_BALANCE: 'FETCH_EXTERNAL_USER_BALANCE',
  FETCH_EXTERNAL_USER_BALANCE_SUCCESS: 'FETCH_EXTERNAL_USER_BALANCE_SUCCESS',
  FETCH_EXTERNAL_USER_BALANCE_REJECTED: 'FETCH_EXTERNAL_USER_BALANCE_REJECTED',

  CHANGE_STATUS_EXTERNAL_USER: 'CHANGE_STATUS_EXTERNAL_USER',
  CHANGE_STATUS_EXTERNAL_USER_SUCCESS: 'CHANGE_STATUS_EXTERNAL_USER_SUCCESS',
  CHANGE_STATUS_EXTERNAL_USER_REJECTED: 'CHANGE_STATUS_EXTERNAL_USER_REJECTED',

  CHANGE_STATUS_ADDRESS: 'CHANGE_STATUS_ADDRESS',
  CHANGE_STATUS_ADDRESS_SUCCESS: 'CHANGE_STATUS_ADDRESS_SUCCESS',
  CHANGE_STATUS_ADDRESS_REJECTED: 'CHANGE_STATUS_ADDRESS_REJECTED',

  UPDATE_ATTRIBUTE_EXTERNAL_USER: 'UPDATE_ATTRIBUTE_EXTERNAL_USER',
  UPDATE_ATTRIBUTE_EXTERNAL_USER_SUCCESS: 'UPDATE_ATTRIBUTE_EXTERNAL_USER_SUCCESS',
  UPDATE_ATTRIBUTE_EXTERNAL_USER_REJECTED: 'UPDATE_ATTRIBUTE_EXTERNAL_USER_REJECTED',

  UPDATE_DOCUMENT_STATUS: 'UPDATE_DOCUMENT_STATUS',
  UPDATE_DOCUMENT_STATUS_SUCCESS: 'UPDATE_DOCUMENT_STATUS_SUCCESS',
  UPDATE_DOCUMENT_STATUS_REJECTED: 'UPDATE_DOCUMENT_STATUS_REJECTED',
};
