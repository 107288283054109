import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/cms/grid';
import ModelService from '../../services/role.service';

const Roles = ({ t, getColumns }) => (
  <Grid
    id="roles"
    title={t('Roles')}
    service={ModelService}
    columns={getColumns(t)}
    removeUrl="roles"
    showViewButton={false}
    showAddButton
  />
);

Roles.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Roles;
