import { compose, hoistStatics, lifecycle, withContext, withHandlers, withProps } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { pick, pipe, prop, filter, propOr } from 'ramda';
import PropTypes from 'prop-types';
import moment from 'moment';

import { uploadActions } from '../../../../../reducers/upload';
import { externalUserActions } from '../../../../../reducers/externalUser';
import ExternalUserData from './ExternalUserData';
import apiRoutes from '../../../../../services/api.routes';
import FirstLoading from '../../../../../components/FirstLoading';
import statusActions from './statusActions';
import { usersNumberActions } from '../../../../../reducers/usersCount';
import SanctionCheckStatuses from '../../../../../constants/SanctionCheckStatuses';
import stringHelpers from '../../../../../helpers/string.helper';

const { toCamelCaseWithSpaces } = stringHelpers;

const changeStatus = ({
  history,
  changeStatusExternalUser,
  matchParams: { id },
  externalUser: { compliance_status },
}) => (status) => {
  changeStatusExternalUser({
    id,
    prevStatus: compliance_status,
    action: status,
  }, {
    url: apiRoutes.changeStatus,
    status,
    history,
    id,
  });
};

const changeStatusAddress = ({ changeStatusAddressAction }) => (addressId, status) => {
  changeStatusAddressAction({ status }, {
    url: `/change-user-address-status/${addressId}`,
  });
};

const disabled = props => (buttonStatus) => {
  const { history } = props;
  const currentUrl = history.location.pathname.split('/')[1];

  switch (currentUrl) {
    case 'new-users':
      return false;
    case 'white-list-users':
      return buttonStatus !== statusActions.BLACKLIST;
    case 'pending-users':
      return buttonStatus === statusActions.REVIEW;
    case 'black-list-users':
      return buttonStatus !== statusActions.REVIEW;
    default:
      return true;
  }
};

const mapStateToProps = state => ({
  externalUser: state.externalUser.data,
  firstLoading: state.externalUser.firstLoading,
});

const mapDispatchToProps = {
  removeTempFile: uploadActions.removeTempFile,
  fetchExternalUser: externalUserActions.fetchExternalUser,
  changeStatusExternalUser: externalUserActions.changeStatusExternalUser,
  changeStatusAddressAction: externalUserActions.changeStatusAddress,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withFormik({
    mapPropsToValues: ({ externalUser }) => ({
      username: externalUser.username,
      email: externalUser.email,
      first_name: externalUser.first_name,
      middle_name: externalUser.middle_name,
      last_name: externalUser.last_name,
      phone_number: externalUser.phone_number,
      company_name: externalUser.company_name,
      date_of_birth: externalUser.date_of_birth && moment(externalUser.date_of_birth).format('L'),
      date_of_kyc: externalUser.date_of_kyc && moment(externalUser.date_of_kyc).format('L'),
      date_of_registration: externalUser.date_of_registration && moment(externalUser.date_of_registration).format('L'),
      date_of_sanction: externalUser.date_of_sanction && moment(externalUser.date_of_sanction).format('L'),
      gender: externalUser.gender,
      nationality: externalUser.nationality,
      managed: externalUser.managed,
    }),
    validateOnChange: false,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const { fetchExternalUser, matchParams } = this.props;

      fetchExternalUser(matchParams, {
        url: apiRoutes.getExtUserData,
        firstLoading: true,
      });
    },
  }),
  withProps(({ externalUser }) => ({
    title: externalUser.username,
    addresses: externalUser.addresses,
    sanctionLabelStatus: prop('sanction_check', externalUser),
    sanctionLabelText: pipe(
      prop('sanction_check'),
      num => filter(entry => entry[1] === num)(Object.entries(SanctionCheckStatuses)),
      (arr = [['', '']]) => (arr.length ? arr[0][0] : ''),
      toCamelCaseWithSpaces,
    )(externalUser),
    sanctionDate: propOr('', 'sanctions_check_date', externalUser),
  })),
  withHandlers({
    changeStatus,
    changeStatusAddress,
    disabled,
  }),
  withContext({
    changeStatusAddress: PropTypes.func,
  }, pick(['changeStatusAddress'])),
  FirstLoading,
);

export default hoistStatics(enhance)(ExternalUserData);
