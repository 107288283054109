import { compose, hoistStatics } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik/dist/index';
import Translation from '../../Translation';
import FirstLoading from '../../../../../components/FirstLoading';
import apiRoutes from '../../../../../services/api.routes';
import withTranslation from '../../../translate';
import rules from '../../../translate/rules';

const enhance = compose(
  withTranslation,
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      title: '',
      image: '',
      status: '',
      priority: '',
      admin_status: '',
      is_default: '',
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { createTranslation },
      setSubmitting,
    }) => {
      createTranslation(values, {
        url: apiRoutes.languages,
        setSubmitting,
      });
    },
  }),
  FirstLoading,
);

export default withRouter(hoistStatics(enhance)(Translation));
