export default {
  UPDATE_CRITERIA: 'UPDATE_CRITERIA',
  REMOVE_CRITERIA: 'REMOVE_CRITERIA',
  CLEAR_ALL_CRITERIA: 'CLEAR_ALL_CRITERIA',

  FETCH_GRID_DATA: 'FETCH_GRID_DATA',
  FETCH_GRID_DATA_SUCCESS: 'FETCH_GRID_DATA_SUCCESS',
  FETCH_GRID_DATA_REJECTED: 'FETCH_GRID_DATA_REJECTED',

  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_ITEM_SUCCESS: 'REMOVE_ITEM_SUCCESS',
  REMOVE_ITEM_REJECTED: 'REMOVE_ITEM_REJECTED',
};
