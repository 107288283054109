import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';


const Documents = ({
  t, data, toggle, toggleModal, token, listStatuses, getImageData, url, fileTypes,
}) => (
  <div
    key={data.id}
    className="mb-3 p-2"
  >
    <p>
      {t('Status - ')}
      <Badge
        className="mr-2"
        color={listStatuses()[data.status].color}
      >
        {listStatuses()[data.status].title}
      </Badge>

      {t('Type - ')}
      <Badge color="primary">
        {fileTypes()[data.type]}
      </Badge>
    </p>

    <img
      width="100%"
      src={`${url}/${data.id}?token=${token}`}
      onClick={() => {
        toggleModal(!toggle);
        getImageData(data);
      }}
      alt={data.id}
    />
  </div>
);

Documents.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  toggle: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getImageData: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  listStatuses: PropTypes.func.isRequired,
};

export default Documents;
