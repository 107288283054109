import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchConversations, fetchConversationsSuccess, fetchConversationsRejected,
  setConversation, setConversationSuccess, setConversationRejected,
  fetchMail, fetchMailSuccess, fetchMailRejected,
} = createActions(
  {
    [types.FETCH_CONVERSATIONS]: [
      identity,
      nthArg(1),
    ],
    [types.SET_CONVERSATION]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_MAIL]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_CONVERSATIONS_SUCCESS,
  types.FETCH_CONVERSATIONS_REJECTED,
  types.SET_CONVERSATION_SUCCESS,
  types.SET_CONVERSATION_REJECTED,
  types.FETCH_MAIL_SUCCESS,
  types.FETCH_MAIL_REJECTED,
);

export default {
  fetchConversations,
  fetchConversationsSuccess,
  fetchConversationsRejected,
  setConversation,
  setConversationSuccess,
  setConversationRejected,
  fetchMail,
  fetchMailSuccess,
  fetchMailRejected,
};
