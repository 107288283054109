import { toast } from 'react-toastify';
import { compose, hoistStatics, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withFormik } from 'formik/dist/index';

import Conversation from './Conversation';
import apiRoutes from '../../../services/api.routes';
import mailsActions from '../../../reducers/mails/actions';
import { uiActions } from '../../../reducers/ui';
import rules from './rules';

const mapStateToProps = state => ({
  data: state.mails.data,
  conversation: state.mails.conversation,
});

const mapDispatchToProps = {
  fetchMails: mailsActions.fetchMails,
  sendMail: mailsActions.sendMail,
  toggleAddDocumentModal: () => uiActions.toggleModal('addDocument'),
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withState('image', 'setImage', null),
  withState('userId', 'setUserId', null),
  withFormik({
    mapPropsToValues: ({ conversation }) => ({
      text: '',
      subject: conversation.subject,
      to: conversation.messageRecipient,
      from: conversation.messageSender,
      conversationId: conversation.conversationId,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { sendMail },
      setSubmitting,
      resetForm,
    }) => {
      sendMail(values, {
        url: apiRoutes.sendEmail,
        setSubmitting,
        resetForm,
        toast,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchMails, match } = this.props;
      fetchMails(match.params, {
        url: apiRoutes.getMailsById,
      });
    },
  }),
);

export default hoistStatics(enhance)(Conversation);
