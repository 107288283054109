import { compose, withProps, withState, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pipe, propOr, objOf } from 'ramda';

import AddDocumentModal from './AddDocumentModal';
import { uiActions, uiSelectors } from '../../../reducers/ui';
import { authSelectors } from '../../../reducers/auth';
import { userActions } from '../../../reducers/user';
import { docTypes } from '../../../constants/FileTypes';
import rules from './rules';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getIsModalOpen(state)('addDocument'),
  token: authSelectors.getToken(state),
});

const mapDispatchToProps = {
  toggle: () => uiActions.toggleModal('addDocument'),
  addDocumentToUser: userActions.addDocumentToUser,
};

const getNumber = number => parseInt(number, 10);

const enhance = compose(
  translate('core'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isAddToDocuments', 'setIsAddToDocuments', false),
  withState('wasSubmitted', 'setWasSubmitted', false),
  withProps(({
    toggle, setIsAddToDocuments, setWasSubmitted,
  }) => ({
    toggle: () => {
      setIsAddToDocuments(false);
      setWasSubmitted(false);
      return toggle();
    },
  })),
  withFormik({
    mapPropsToValues: () => ({
      type: 0,
      dateOfExpiration: new Date(),
      docType: 0,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (
      values,
      {
        resetForm, props: {
          setIsAddToDocuments, image, userId, addDocumentToUser, setWasSubmitted,
        },
      },
    ) => {
      setIsAddToDocuments(false);
      setWasSubmitted(true);
      const body = {
        id: image,
        user_id: userId,
        type: getNumber(values.type),
        docType: getNumber(values.docType),
        expiration_date: values.dateOfExpiration,
      };
      addDocumentToUser(body);
      resetForm();
    },
  }),
  withProps(({ values: { type } }) => pipe(
    propOr({}, type),
    objOf('docTypes'),
  )(docTypes)),
);

export default enhance(AddDocumentModal);
