import React from 'react';
import PropTypes from 'prop-types';
import { Col, Table } from 'reactstrap';
import _ from 'lodash';
import InputGroup from '../../../../components/cms/form/inputGroup';

const Permissions = ({
  t,
  errors,
  permissions,
  changePermissions,
  values,
}) => (
  <Col xs="12" lg="6">
    <Table responsive>
      <thead>
      <tr>
        <th>{t('Module')}</th>
        <th>{t('Read')}</th>
        <th>{t('Create')}</th>
        <th>{t('Update')}</th>
        <th>{t('Delete')}</th>
      </tr>
      </thead>
      <tbody>
      {Object.keys(permissions).map(key =>
        (<tr key={key}>
          <td>{t(key[0].toUpperCase() + key.slice(1))}</td>
          {_.findIndex(permissions[key], o =>
            o.slug.split('_')[0] === 'read') > -1 ? permissions[key].map(value => (
            value.slug.split('_')[0] === 'read' &&
            <td key={value.name}>
              <InputGroup
                id={value.name}
                name="availablePermissions"
                onChange={changePermissions}
                errors={errors}
                type="checkbox"
                value={value.id}
                checked={values.availablePermissions.includes(value.id) ? 1 : 0}
              />
            </td>
          )) : <td />}
          {_.findIndex(permissions[key], o =>
            o.slug.split('_')[0] === 'create') > -1 ? permissions[key].map(value => (
            value.slug.split('_')[0] === 'create' &&
            <td key={value.name}>
              <InputGroup
                id={value.name}
                name="availablePermissions"
                onChange={changePermissions}
                errors={errors}
                type="checkbox"
                value={value.id}
                checked={values.availablePermissions.includes(value.id) ? 1 : 0}
              />
            </td>
          )) : <td />}
          {_.findIndex(permissions[key], o =>
            o.slug.split('_')[0] === 'update') > -1 ? permissions[key].map(value => (
            value.slug.split('_')[0] === 'update' &&
            <td key={value.name}>
              <InputGroup
                id={value.name}
                name="availablePermissions"
                onChange={changePermissions}
                errors={errors}
                type="checkbox"
                value={value.id}
                checked={values.availablePermissions.includes(value.id) ? 1 : 0}
              />
            </td>
          )) : <td />}
          {_.findIndex(permissions[key], o =>
            o.slug.split('_')[0] === 'delete') > -1 ? permissions[key].map(value => (
            value.slug.split('_')[0] === 'delete' &&
            <td key={value.name}>
              <InputGroup
                id={value.name}
                name="availablePermissions"
                onChange={changePermissions}
                errors={errors}
                type="checkbox"
                value={value.id}
                checked={values.availablePermissions.includes(value.id) ? 1 : 0}
              />
            </td>
          )) : <td />}
        </tr>))}
      </tbody>
    </Table>
  </Col>
);

Permissions.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  changePermissions: PropTypes.func.isRequired,
};

export default Permissions;
