import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { externalUserActions } from '../../../../../reducers/externalUser';
import BalanceOverview from './BalanceOverview';
import apiRoutes from '../../../../../services/api.routes';
import FirstLoading from '../../../../../components/FirstLoading';

const mapStateToProps = state => ({
  externalUserBalance: state.externalUser.balance,
  loading: state.externalUser.loading,
  firstLoading: state.externalUser.firstLoading,
});

const mapDispatchToProps = {
  fetchExternalUserBalance: externalUserActions.fetchExternalUserBalance,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchExternalUserBalance, matchParams } = this.props;

      fetchExternalUserBalance(matchParams, {
        url: apiRoutes.getExternalUserBalance,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
);

export default hoistStatics(enhance)(BalanceOverview);
