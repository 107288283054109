import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination as BPagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const Pagination = ({
  goToPage,
  show,
  countPages,
  page,
  pages,
  countButtons,
}) => (
  <React.Fragment> {show && (
    <BPagination>
      {page > countButtons && (
        <React.Fragment>
          <PaginationItem>
            <PaginationLink
              tag="button"
              onClick={() => goToPage(1)}
            >
              1
            </PaginationLink>
          </PaginationItem>
        </React.Fragment>
      )}

      {page > 1 && (
        <PaginationItem>
          <PaginationLink
            tag="button"
            onClick={() => goToPage(page - 1)}
          >
            &lt;
          </PaginationLink>
        </PaginationItem>
      )}

      {pages.map(pageIndex => (
        <PaginationItem
          key={pageIndex}
          active={pageIndex === page}
          onClick={() => goToPage(pageIndex)}
        >
          <PaginationLink
            tag="button"
          >
            {pageIndex}
          </PaginationLink>
        </PaginationItem>
      ))}

      {page < countPages && (
        <PaginationItem>
          <PaginationLink
            tag="button"
            onClick={() => goToPage(page + 1)}
          >
            &gt;
          </PaginationLink>
        </PaginationItem>
      )}

      {page < countPages - countButtons - 1 && (
        <React.Fragment>
          <PaginationItem>
            <PaginationLink
              tag="button"
              onClick={() => goToPage(countPages)}
            >
              {countPages}
            </PaginationLink>
          </PaginationItem>
        </React.Fragment>
      )}
    </BPagination>
  )}
  </React.Fragment>
);

Pagination.propTypes = {
  show: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.number),
  goToPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  countButtons: PropTypes.number.isRequired,
  countPages: PropTypes.number,
};

Pagination.defaultProps = {
  pages: 10,
  countPages: 0,
};

export default Pagination;
