import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik';
import User from '../../../user/User';
import ModelService from '../../../../../services/user.service';
import canUpdate from '../../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../../services/api.routes';
import rules from '../../../user/rules';
import withUser from '../../../user';

const enhance = compose(
  withUser,
  withFormik({
    mapPropsToValues: () => ({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      company_name: '',
      phone: '',
      password: '',
      rolesList: '',
      admin_language: '',
      status: '',
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { createCdUser, updateCdUser, match },
      setSubmitting,
    }) => {
      const rolesList = JSON.stringify(values.rolesList);

      createCdUser({ ...values, rolesList }, {
        url: `${apiRoutes.users}`,
        setSubmitting,
      });
    },
  }),
  canUpdate(ModelService),
);

export default (hoistStatics(enhance)(User));
