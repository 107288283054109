import * as yup from 'yup';

export default yup.object().shape({
  highValueTrxAmount: yup.string().required({
    message: 'High value transaction amount cannot be empty',
    params: { key: 'highValueTrxAmount' },
  }),
  highVolToSameRecipientDeviation: yup.string().required({
    message: 'High volume to same recipient deviation cannot be empty',
    params: { key: 'highVolToSameRecipientDeviation' },
  }),
  highVolToSameRecipientPeriod: yup.string().required({
    message: 'High volume to same recipient deviation cannot be empty',
    params: { key: 'highVolToSameRecipientDeviation' },
  }),
  increaseNumTrxDeviation: yup.string().required({
    message: 'Increase number transaction deviation cannot be empty',
    params: { key: 'increaseNumTrxDeviation' },
  }),
  increaseNumTrxPeriod: yup.string().required({
    message: 'Increase number transaction period cannot be empty',
    params: { key: 'increaseNumTrxPeriod' },
  }),
  increaseNumTrxRedline: yup.string().required({
    message: 'Increase number transaction redline cannot be empty',
    params: { key: 'increaseNumTrxRedline' },
  }),
  totalVolInWeek: yup.string().required({
    message: ('Total volume in week cannot be empty'),
    params: { key: 'totalVolInWeek' },
  }),
  uniqueRecipientsException: yup.string().required({
    message: 'Unique recipients exception cannot be empty',
    params: { key: 'uniqueRecipientsException' },
  }),
});
