import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Table, Badge } from 'reactstrap';
import Pagination from './../../components/cms/pagination';
import Transaction from './item';

const Transactions = ({
  t, data, title, goToPage, page, lastPage, total, perPage, loading, changeStatus, showLink,
}) => (
  <Card>
    <CardHeader>
      <i className="fa fa-align-justify" /> {t(title)}
    </CardHeader>
    <CardBody>
      <Table
        responsive
        striped
        className={loading ? 'loading' : ''}
      >
        <thead>
          <tr>
            <th>{t('Transactions')}</th>
            <th>{t('Transaction type')}</th>
            <th>{t('Amount')}</th>
            <th>{t('Currency')}</th>
            <th>{t('Date / Time')}</th>
            <th>{t('Status')}</th>
            <th>{t('Warning details')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map(value => (
            <Transaction
              key={value.id}
              t={t}
              value={value}
              loading={loading}
              changeStatus={changeStatus}
              showLink={showLink}
            />
          ))}
        </tbody>
        <tfoot>
          <tr className="small-tr">
            <td colSpan={6}>
              <div className="pull-left mr-3 mt-2">
                <i className="fa fa-database mr-1" />
                <Badge> {t('Total')}: {total} {t('items')}</Badge>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className="no-border">
              <div className="pull-right">
                <Pagination
                  goToPage={goToPage}
                  count={total}
                  pageSize={perPage}
                  page={page}
                  lastPage={lastPage}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
    </CardBody>
  </Card>
);

Transactions.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  goToPage: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

export default Transactions;
