import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { transactionsActions } from './index';

export const fetchTransactionsDataEpic = action$ => action$.pipe(
  ofType(types.FETCH_TRANSACTIONS_DATA),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => transactionsActions.fetchTransactionsDataSuccess(response)),
    catchError(error => of(transactionsActions.fetchTransactionsDataRejected(error))),
  )),
);

export const changeTransactionStatusEpic = action$ => action$.pipe(
  ofType(types.CHANGE_TRANSACTION_STATUS),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      toast.success('Transaction status changed');
      return transactionsActions.changeTransactionStatusSuccess(response);
    }),
    catchError((error) => {
      toast.error(error.status === 403 ? 'Access forbidden. You are not allowed to this resource.' : error.response.error);
      return of(transactionsActions.changeTransactionStatusRejected(error));
    }),
  )),
);
