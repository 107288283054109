import React from 'react';
import PropTypes from 'prop-types';


const ColumnTitle = ({
 title, canSort, icon, onSort,
}) => (
  <th scope="col">
    {canSort ?
      <a
        href={null}
        onClick={onSort}
        style={{
          cursor: 'pointer',
          color: '#20a8d8',
        }}
      >
        { title }<i className={icon} />
      </a> :
      <span>{ title }</span>}
  </th>
);

ColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  canSort: PropTypes.bool.isRequired,
};

export default ColumnTitle;
