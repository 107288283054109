import { createSelector } from 'reselect';
import { pathOr, memoizeWith, identity, propOr, __ } from 'ramda';

const getIsModalOpen = createSelector(
  pathOr({}, ['ui', 'modals']),
  modals => memoizeWith(
    identity,
    propOr(false, __, modals),
  ),
);

const getIsPopoverOpen = createSelector(
  pathOr({}, ['ui', 'popovers']),
  popovers => memoizeWith(
    identity,
    propOr(false, __, popovers),
  ),
);

export {
  getIsModalOpen,
  getIsPopoverOpen,
};
