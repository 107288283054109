import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';

const EmailTemplatesListItem = ({ item, onClick }) => (
  <ListGroupItem className="email-templates_list_item" onClick={onClick}>
    {propOr('', 'subject', item)}
  </ListGroupItem>
);

EmailTemplatesListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    subject: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmailTemplatesListItem;

