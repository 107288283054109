import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const HandleBadge = ({
  getCheckBoxColor, getCheckBoxLabel,
}) => (
  <Badge color={getCheckBoxColor()}>{getCheckBoxLabel()}</Badge>
);

HandleBadge.propTypes = {
  getCheckBoxColor: PropTypes.func.isRequired,
  getCheckBoxLabel: PropTypes.func.isRequired,
};

export default HandleBadge;
