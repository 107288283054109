import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import apiUrl from '../../constants/api';
import { getPayload } from '../../helpers/epics.helper';
import * as ajax from '../../helpers/ajax.helper';
import types from './types';
import { chartsActions } from './index';

export const fetchUsersOverviewsEpic = action$ => action$.pipe(
  ofType(types.FETCH_USERS_OVERVIEWS),
  mergeMap(action => ajax.get(`${apiUrl}/dashboard/usersOverview`, getPayload(action)).pipe(
    map(({ response }) => chartsActions.fetchUsersOverviewsSuccess({ data: response.result })),
    catchError(error => of(chartsActions.fetchUsersOverviewsRejected(error))),
  )),
);
