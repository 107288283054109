import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button } from 'reactstrap';
import _ from 'lodash';
import Permissions from 'react-redux-permissions';
import InputGroup from '../../../components/cms/form/inputGroup';
// import InputFile from '../../../components/cms/form/inputFile';
// import PreviewImage from '../../../components/previewImage';

const twoCols = { xs: 12, md: 6 };

const User = ({
  t, userId, errors, image, getAvatar, hasPermission, languages, statusList,
  allRoles, values, handleSubmit, handleChange, isSubmitting, reqErrors, uploadCdUserImage,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{`${t('User')} - ${values.username}`}</strong>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <fieldset disabled={!hasPermission && values.id !== userId}>
                <Row>
                  <Col md={12}>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="username"
                          label={t('Username')}
                          name="username"
                          placeholder={t('Username')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.username}
                          icon="fa fa-user"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="email"
                          label={t('E-mail')}
                          name="email"
                          placeholder={t('E-mail')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.email}
                          icon="fa fa-at"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="first_name"
                          label={t('First name')}
                          name="first_name"
                          placeholder={t('First name')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.first_name}
                          icon="fa fa-address-card-o"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="last_name"
                          label={t('Last name')}
                          name="last_name"
                          placeholder={t('Last name')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.last_name}
                          icon="fa fa-address-card-o"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="company_name"
                          label={t('Company name')}
                          name="company_name"
                          placeholder={t('Company name')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.company_name}
                          icon="fa fa-group"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="phone"
                          label={t('Phone')}
                          name="phone"
                          placeholder={t('Phone')}
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.phone}
                          icon="fa fa-phone"
                        />
                      </Col>
                    </FormGroup>
                    {values.id === userId && (
                      <InputGroup
                        id="password"
                        label={t('Password')}
                        name="password"
                        placeholder="*******"
                        type="password"
                        autoComplete="new-password"
                        onChange={handleChange}
                        errors={!_.isEmpty(errors) ? errors : reqErrors}
                        value={values.password}
                        icon="fa fa-lock"
                      />
                    )}
                    {values.id !== userId && (
                      <Permissions allowed={['update_cpUsers']}>
                        <InputGroup
                          id="password"
                          label={t('Password')}
                          name="password"
                          placeholder="*******"
                          type="password"
                          autoComplete="new-password"
                          onChange={handleChange}
                          errors={!_.isEmpty(errors) ? errors : reqErrors}
                          value={values.password}
                          icon="fa fa-lock"
                        />
                      </Permissions>
                    )}
                    <Permissions allowed={['update_roles']}>
                      <InputGroup
                        label={t('Roles')}
                        placeholder={t('Roles')}
                        name="rolesList"
                        type="select2"
                        onChange={handleChange}
                        errors={!_.isEmpty(errors) ? errors : reqErrors}
                        value={values.rolesList}
                        options={allRoles}
                        icon="fa fa-lock"
                      />
                    </Permissions>
                    <InputGroup
                      label={t('Language')}
                      name="admin_language"
                      type="select"
                      onChange={handleChange}
                      errors={!_.isEmpty(errors) ? errors : reqErrors}
                      value={values.admin_language}
                      options={languages}
                      empty="-"
                      icon="fa fa-language"
                    />
                    <Permissions allowed={['update_cpUsers']}>
                      <InputGroup
                        label={t('Status')}
                        name="status"
                        type="select"
                        onChange={handleChange}
                        errors={!_.isEmpty(errors) ? errors : reqErrors}
                        value={values.status}
                        options={statusList}
                        empty="-"
                        icon="fa fa-cog"
                      />
                    </Permissions>
                  </Col>
                  {/*<Col md={3}>*/}
                    {/*<div className="center">*/}
                      {/*<PreviewImage*/}
                        {/*src={getAvatar(image, 150, 150, true)}*/}
                        {/*height={150}*/}
                        {/*wigth={150}*/}
                      {/*/>*/}
                      {/*<InputFile id="image" />*/}
                    {/*</div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      className="mr-2 mb-1 btn-brand"
                    >
                      <i className="fa fa-save mr-2" />{t('Save')}
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

User.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  // image: PropTypes.object,
  // statusList: PropTypes.array.isRequired,
};

export default User;
