import { handleActions } from 'redux-actions';
import { without } from 'ramda';

import types from './types';
import { mergeIn, mergeDeep } from '../../helpers/store.helper';

const initialState = {
  result: [],
  entities: {},
  loading: false,
  error: false,
  errors: null,
};

const reducer = handleActions(
  {
    [types.FETCH_EMAIL_TEMPLATES]: mergeIn(() => ({
      error: false,
      errors: null,
      loading: true,
    })),
    [types.FETCH_EMAIL_TEMPLATES_SUCCESS]: mergeIn(({ payload }) => ({
      ...payload,
      loading: false,
    })),
    [types.FETCH_EMAIL_TEMPLATES_REJECTED]: mergeIn(({ payload }) => ({
      loading: false,
      errors: payload,
      error: true,
    })),

    [types.CREATE_EMAIL_TEMPLATE_SUCCESS]: mergeDeep((
      { payload: { result, entities } },
      { result: oldResult },
) => ({
      entities,
      result: [...new Set([...result, ...oldResult])],
    })),

    [types.UPDATE_EMAIL_TEMPLATE_SUCCESS]: mergeDeep((
      { payload: { result, entities } },
      { result: oldResult },
    ) => ({
      entities,
      result: [...new Set([...result, ...oldResult])],
    })),

    [types.DELETE_EMAIL_TEMPLATE_SUCCESS]: mergeIn((
      { payload: id },
      { result },
    ) => ({
      result: without([id], result),
    })),
  },
  initialState,
);

export default reducer;
