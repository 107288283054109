import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchAllExternalUsers, fetchAllExternalUsersSuccess, fetchAllExternalUsersRejected,
} = createActions(
  {
    [types.FETCH_ALL_EXTERNAL_USERS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_ALL_EXTERNAL_USERS_SUCCESS,
  types.FETCH_ALL_EXTERNAL_USERS_REJECTED,
);

export default {
  fetchAllExternalUsers,
  fetchAllExternalUsersSuccess,
  fetchAllExternalUsersRejected,
};
