import { mergeMap, map, catchError, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import * as ajax from '../../helpers/ajax.helper';
import types from './types';
import { mailsActions } from './index';

export const fetchMailsEpic = action$ => action$.pipe(
  ofType(types.FETCH_MAILS),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => mailsActions.fetchMailsSuccess(response)),
    catchError(error => of(mailsActions.fetchMailsRejected(error))),
  )),
);

export const sendMailEpic = action$ => action$.pipe(
  ofType(types.SEND_MAIL),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('Message sent');
      action.meta.resetForm();

      return mailsActions.sendMailSuccess(response);
    }),
    catchError((error) => {
      action.meta.setSubmitting(false);
      toast.error(error.message);
      action.meta.resetForm();

      return of(mailsActions.sendMailRejected(error));
    }),
  )),
);

export const fetchUserEmailEpic = action$ => action$.pipe(
  ofType(types.FETCH_USER_EMAIL),
  delay(1000),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => mailsActions.fetchUserEmailSuccess(response)),
    catchError(error => of(mailsActions.fetchUserEmailRejected(error))),
  )),
);
