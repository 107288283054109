import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InputGroup from '../../components/cms/form/inputGroup';

const ResetPassword = ({
  onChange, onSubmit, t, password, errors, repeat, isSubmitting,
}) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <p className="text-muted">{t('Please enter new password')}</p>
                  <InputGroup
                    id="password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    placeholder={t('Password')}
                    onChange={onChange}
                    errors={errors}
                    value={password}
                    icon="fa fa-lock"
                  />
                  <InputGroup
                    id="repeat"
                    type="password"
                    name="repeat"
                    autoComplete="new-password"
                    placeholder={t('Repeat password')}
                    onChange={onChange}
                    errors={errors}
                    value={repeat}
                    icon="fa fa-lock"
                  />
                  <Row>
                    <Col xs="6">
                      <Button disabled={isSubmitting} color="primary" className="px-4">{t('Change password')}</Button>
                    </Col>
                    <Col xs="6" className="text-right" />
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

ResetPassword.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ResetPassword;
