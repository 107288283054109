export default {
  generate2FACode: '/gen-2fa',
  login: '/auth/login',
  currentUser: '/auth/current',
  forgotPassword: '/auth/forgot-password',
  checkCode: '/users/check-code',
  changePassword: '/auth/change-password',
  logout: '/auth/logout',
  roles: '/roles',
  permissions: '/permissions',
  documents: '/documents',
  document: '/document',
  dashboard: '/dashboard',
  users: '/users',
  newUsers: '/new-users',
  whitelistedUsers: '/whitelisted-users',
  blacklistedUsers: '/blacklisted-users',
  pendingUsers: '/pending-users',
  correspondentServiceProviders: '/correspondent-service-providers',
  languages: '/languages',
  translations: '/translations',
  transaction: '/transaction',
  upload: '/temp-files/upload',
  metaData: '/meta-data',
  deleteTempFile: '/temp-files/delete',
  settings: '/settings',
  slash: '/',
  testMailjet: '/test-mailjet',
  mail: '/messages',
  exportUsers: '/exportData',
  getConversationsList: '/getConversationsList',
  getMailsById: '/messages/getListOfMessagesByConversationId',
  getConversationsByEmail: '/messages/getConversationsById',
  getLastConversationMessage: '/messages/getLastConversationMessage',
  sendEmail: '/messages/send',
  getExtUserData: '/getExtUserData',
  getTransactionsByUid: '/transaction/getTransactionsByUid',
  changeStatus: '/changeStatus',
  getSanctionedUsers: '/dashboard/getSanctionedUsers',
  getAllPermissions: '/permissions/all',
  changeTransactionStatus: '/transaction/reviewTrx',
  getAllExternalUsers: '/all-users',
  getExternalUserBalance: '/transaction/getBalanceByUid',
  searchUserByUserName: '/searchUserByUserName',
};
