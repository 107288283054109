import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import Buttons from './Buttons';

const getUpdateUrl = (baseUrl, id) => `${baseUrl}/${id}`;
const getUpdateExternalUserUrl = (baseUrl, id, status) => `${baseUrl}/${status}/${id}`;
const getViewUrl = (baseUrl, id) => `${baseUrl}/view/${id}`;

const enhance = compose(
  translate('core'),
  defaultProps({
    getUpdateUrl,
    getViewUrl,
    getUpdateExternalUserUrl,
    showViewButton: true,
    showUpdateButton: true,
    showDeleteButton: true,
    onClickDelete: props => id => console.log('deleted #', id),
  }),
);

export default hoistStatics(enhance)(Buttons);
