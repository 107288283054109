import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { permissionsActions } from '../../../reducers/permissions';
import { roleActions } from '../../../reducers/role';

const wrapperComponent = (WrappedComponent) => {
  const Component = props => <WrappedComponent {...props} />;

  const mapStateToProps = ({ role }) => ({
    role: role.data,
    permissions: role.permissions,
    loading: role.loading,
  });

  const mapDispatchToProps = {
    updatePermissions: permissionsActions.updatePermissions,
    fetchRole: roleActions.fetchRole,
    createRole: roleActions.createRole,
    updateRole: roleActions.updateRole,
    fetchPermissions: roleActions.fetchPermissions,
  };

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    translate('core'),
  );

  return enhance(Component);
};

export default wrapperComponent;
