import { compose, hoistStatics, withHandlers } from 'recompose';
import Document from './Document';
import FILE_STATUSES from '../../../../../../../constants/FileStatuses';
import FILE_TYPES from '../../../../../../../constants/FileTypes';

const fileTypes = ({ t }) => () => ({
  [FILE_TYPES.PROOF_OF_ADDRESS]: t('Proof of address'),
  [FILE_TYPES.ID_CARD_1]: t('ID card 1'),
  [FILE_TYPES.ID_CARD_2]: t('ID card 2'),
  [FILE_TYPES.SELFIE]: t('Selfie'),
  [FILE_TYPES.VIDEO_LIFENESS]: t('Video lifeness'),
  [FILE_TYPES.SALES_CONTRACT]: t('Sales contract'),
  [FILE_TYPES.PASSPORT_ID]: t('Passport ID'),
})

const listStatuses = ({ t }) => () => ({
  [FILE_STATUSES.NEW]: { title: t('New'), color: 'primary' },
  [FILE_STATUSES.APPROVED]: { title: t('Aproved'), color: 'success' },
  [FILE_STATUSES.DECLINED]: { title: t('Declined'), color: 'danger' },
  [FILE_STATUSES.OLD]: { title: t('Old'), color: 'warning' },
});

const enhance = compose(withHandlers({
  listStatuses, fileTypes,
}));

export default hoistStatics(enhance)(Document);
