import { createSelector } from 'reselect';
import { prop, identity } from 'ramda';

const getAuth = createSelector(
  prop('auth'),
  identity,
);

const getToken = createSelector(
  getAuth,
  prop('token'),
);

export {
  getToken,
};
