import * as yup from 'yup';

export default yup.object().shape({
  text: yup.string().required({
    message: 'Text cannot be empty',
    params: { key: 'Text' },
  }),
  subject: yup.string().required({
    message: 'Subject cannot be empty',
    params: { key: 'Subject' },
  }),
  to: yup.string().required({
    message: 'To cannot be empty',
    params: { key: 'To' },
  }),
  from: yup.string().required({
    message: 'From cannot be empty',
    params: { key: 'From' },
  }),
});
