import { compose, hoistStatics, lifecycle, withHandlers, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import DashboardService from '../../services/dashboard.service';

const generateArrayRandomColors = length => (
  [...Array(length).keys()].map(() => `#${((1 << 24) * Math.random() | 0).toString(16)}`)
);

const mapStateToProps = state => ({
  user: state.auth.user.data.user,
});

const handleChangeType = props => async (type) => {
  const data = await DashboardService.getOverviewGraphData({ type });
  const colors = generateArrayRandomColors(data.labels.length);

  props.setChartData({ ...data, colors });
};

const enhance = compose(
  connect(mapStateToProps),
  translate('core'),
  withStateHandlers(() => ({
    chartData: [],
    sanctionedUsers: {},
    sanctionedUsersData: [],
    isLoadingSanctionedUsers: true,
  }), {
    setChartData: state => chartData => ({
      ...state, chartData,
    }),
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      const data = await DashboardService.getOverviewGraphData({ type: 'daily' });
      const colors = generateArrayRandomColors(data.labels.length);

      props.setChartData({ ...data, colors });
    },
  }),
  withHandlers({
    handleChangeType,
  }),
);

export default hoistStatics(enhance)(Dashboard);
