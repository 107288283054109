export default {
  FETCH_EMAIL_TEMPLATES: 'FETCH_EMAIL_TEMPLATES',
  FETCH_EMAIL_TEMPLATES_SUCCESS: 'FETCH_EMAIL_TEMPLATES_SUCCESS',
  FETCH_EMAIL_TEMPLATES_REJECTED: 'FETCH_EMAIL_TEMPLATES_REJECTED',

  CREATE_EMAIL_TEMPLATE: 'CREATE_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE_SUCCESS: 'CREATE_EMAIL_TEMPLATE_SUCCESS',
  CREATE_EMAIL_TEMPLATE_REJECTED: 'CREATE_EMAIL_TEMPLATE_REJECTED',

  UPDATE_EMAIL_TEMPLATE: 'UPDATE_EMAIL_TEMPLATE',
  UPDATE_EMAIL_TEMPLATE_SUCCESS: 'UPDATE_EMAIL_TEMPLATE_SUCCESS',
  UPDATE_EMAIL_TEMPLATE_REJECTED: 'UPDATE_EMAIL_TEMPLATE_REJECTED',

  DELETE_EMAIL_TEMPLATE: 'DELETE_EMAIL_TEMPLATE',
  DELETE_EMAIL_TEMPLATE_SUCCESS: 'DELETE_EMAIL_TEMPLATE_SUCCESS',
  DELETE_EMAIL_TEMPLATE_REJECTED: 'DELETE_EMAIL_TEMPLATE_REJECTED',
};
