import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Roles from './Roles';
import columnTypes from '../../components/cms/grid/columnTypes';
import dh from '../../helpers/date.helper';
import ModelService from '../../services/role.service';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>)
  },
  {
    name: 'slug',
    title: t('Slug'),
  },
  {
    name: 'name',
    title: t('Name'),
  },
  {
    name: 'description',
    title: t('Description'),
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: item => dh.getFormattedDate(item.created_at),
    filter: false,
  },
  {
    name: 'updated_at',
    title: t('Updated at'),
    value: item => dh.getFormattedDate(item.updated_at),
    filter: false,
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('translations'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Roles);
