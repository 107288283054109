import { compose, hoistStatics, lifecycle, withPropsOnChange, withStateHandlers } from 'recompose';
import Pagination from './Pagination';

const calc = (state, props) => {
  const { countButtons } = state;
  const { page, lastPage } = props;

  const startPage = page - countButtons > 0 ? page - countButtons : 1;
  const endPage = page + countButtons + 2 > lastPage ? lastPage + 1 : page + countButtons + 2;
  const pagesLength = endPage - startPage;
  const pages = pagesLength ? Array(...new Array(pagesLength)).map((el, i) => i + startPage) : [];

  return ({
    ...state,
    show: lastPage > 1,
    pages,
    countPages: lastPage,
    countButtons: 5,
  });
};

const enhance = compose(
  withStateHandlers(() => ({
    show: false,
    pages: [],
    countPages: 0,
    countButtons: 5,
  }), {
    handlerCalc: (state, props) => () => calc(state, props),
  }),
  lifecycle({
    componentDidMount() {
      this.props.handlerCalc();
    },
  }),
  withPropsOnChange(['page', 'lastPage'], (props) => {
    props.handlerCalc();
  }),
);

export default hoistStatics(enhance)(Pagination);
