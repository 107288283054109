import { compose, getContext } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import EmailTemplateForm from './EmailTemplateForm';
import { emailTemplatesActions, emailTemplatesSelectors } from '../../../../../../reducers/emailTemplates';
import rules from './rules';

const { createEmailTemplate, updateEmailTemplate, deleteEmailTemplate } = emailTemplatesActions;

const mapStateToProps = (state, { templateId }) => (templateId
  ? ({
    item: emailTemplatesSelectors.getEmailTemplate(state)(templateId),
  }) : ({}));

const mapDispatchToProps = (dispatch, { templateId }) => ({
  sendData: (...data) => (
    dispatch(templateId ? updateEmailTemplate(...data) : createEmailTemplate(...data))
  ),
  deleteEmailTemplate: data => dispatch(deleteEmailTemplate(data, templateId)),
});

const enhance = compose(
  translate('core'),
  getContext({
    templateId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.oneOf([null]),
    ]),
    setTemplateId: PropTypes.func,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ item = { body: '', subject: '' } }) => ({
      body: item.body,
      subject: item.subject,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { sendData, templateId },
      resetForm,
    }) => {
      sendData(values, templateId);
      resetForm();
    },
  }),
);

export default enhance(EmailTemplateForm);
