import { compose, hoistStatics, lifecycle, withHandlers } from 'recompose';
import RoleService from '../../../../services/role.service';
import canUpdate from '../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../services/api.routes';
import withRole from '../../role';
import spinner from '../../../../components/Spinner';
import Permissions from './Permissions';

const changePermissions = props => ({ target }) => {
  const value = parseInt(target.value, 10);

  if (props.values.availablePermissions.includes(value)) {
    props.setFieldValue('availablePermissions', [
      ...props.values.availablePermissions.filter(item => item !== value),
    ]);
  } else {
    props.setFieldValue('availablePermissions', [
      ...props.values.availablePermissions,
      value,
    ]);
  }
};

const enhance = compose(
  withRole,
  lifecycle({
    componentDidMount() {
      const { fetchPermissions } = this.props;

      fetchPermissions(null, {
        url: apiRoutes.getAllPermissions,
      });
    },
  }),
  withHandlers({
    changePermissions,
  }),
  canUpdate(RoleService),
  spinner,
);

export default hoistStatics(enhance)(Permissions);
