import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik/dist/index';
import { toast } from 'react-toastify';
import apiRoutes from '../../../../services/api.routes';
import SecurityForm from './SecurityForm';
import rules from './rules';

const enhance = compose(withFormik({
  mapPropsToValues: ({ data }) => ({
    allowedCountries: data.allowedCountries,
    loginAttempts: data.loginAttempts,
    loginAttemptsTime: data.loginAttemptsTime,
    allowedImageSizes: data.allowedImageSizes,
    allowedFileTypes: data.allowedFileTypes,
  }),
  validationSchema: rules,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: (values, {
    props: { updateSettings },
    setSubmitting,
  }) => {
    updateSettings(values, {
      url: `${apiRoutes.settings}/security/security`,
      setSubmitting,
      toast,
    });
  },
}));

export default hoistStatics(enhance)(SecurityForm);
