import React from 'react';
import InputGroup from '../form/inputGroup';

const select2Filter = (name, isMulti, options) => ({ value, onFilter }) => (
  <InputGroup
    name={name}
    type="select2"
    options={[
      {
        value: '',
        label: '-',
      },
      ...options,
    ]}
    onChange={onFilter}
    isMulti={isMulti}
    value={value}
    placeholder="-"
  />
);

export default select2Filter;
