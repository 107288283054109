const PROOF_OF_ADDRESS = 0;
const ID_CARD_1 = 1;
const ID_CARD_2 = 2;
const SELFIE = 3;
const VIDEO_LIFENESS = 4;
const SALES_CONTRACT = 5;
const PASSPORT_ID = 6;

export const docTypes = {
  [PROOF_OF_ADDRESS]: {
    BILL: 0,
    TAX_REPORT: 1,
    BANK: 2,
  },
  [ID_CARD_1]: {
    PASSPORT: 0,
    DRIVERS_LICENSE: 1,
    ID: 2,
  },
  [ID_CARD_2]: {
    PASSPORT: 0,
    DRIVERS_LICENSE: 1,
    ID: 2,
  },
  [SELFIE]: {
    SELFIE: 0,
  },
  [PASSPORT_ID]: {
    PASSPORT_ID: 0,
  },
};

export default {
  PROOF_OF_ADDRESS,
  ID_CARD_1,
  ID_CARD_2,
  SELFIE,
  VIDEO_LIFENESS,
  SALES_CONTRACT,
  PASSPORT_ID,
};
