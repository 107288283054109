import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import types from './types';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import { externalUserActions } from './index';
import { getNormalizedData } from '../../helpers/normalize.helper';
import statusList from '../../views/externalUsers/user/components/externalUserData/statusList';

export const fetchExternalUserEpic = action$ => action$.pipe(
  ofType(types.FETCH_EXTERNAL_USER),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      const normalizedAddressesData = getNormalizedData(response.addresses);
      const normalizedContractsData = getNormalizedData(response.contracts);
      const normalizedFilesData = getNormalizedData(response.files);

      return externalUserActions.fetchExternalUserSuccess({
        ...response,
        addresses: normalizedAddressesData,
        contracts: normalizedContractsData,
        files: normalizedFilesData,
      });
    }),
    catchError(error => of(externalUserActions.fetchExternalUserRejected(error))),
  )),
);

export const fetchExternalUserBalanceEpic = action$ => action$.pipe(
  ofType(types.FETCH_EXTERNAL_USER_BALANCE),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => externalUserActions.fetchExternalUserBalanceSuccess(response)),
    catchError(error => of(externalUserActions.fetchExternalUserBalanceRejected(error))),
  )),
);

//Todo REVIEW this epic
export const changeStatusExternalUserEpic = action$ => action$.pipe(
  ofType(types.CHANGE_STATUS_EXTERNAL_USER),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      if (response.result.compliance_status === statusList.WHITELIST) {
        action.meta.history.push(`/white-list-users/${response.result.compliance_status}/${action.meta.id}`);
      } else if (response.result.compliance_status === statusList.BLACKLIST) {
        action.meta.history.push(`/black-list-users/${response.result.compliance_status}/${action.meta.id}`);
      } else if (statusList.REVIEW.includes(response.result.compliance_status)) {
        action.meta.history.push(`/pending-users/${response.result.compliance_status}/${action.meta.id}`);
      }

      toast.success('Status changed');

      return externalUserActions.changeStatusExternalUserSuccess(response);
    }),
    catchError((error) => {
      toast.error(error.status === 403 ?
        'Access forbidden. You are not allowed to this resource.' :
        error.response.error);
      return of(externalUserActions.changeStatusExternalUserRejected(error));
    }),
  )),
);

export const changeStatusAddressEpic = action$ => action$.pipe(
  ofType(types.CHANGE_STATUS_ADDRESS),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      const normalizedData = getNormalizedData([response]);

      return externalUserActions.changeStatusAddressSuccess(normalizedData.entities);
    }),
    catchError(error => of(externalUserActions.changeStatusAddressRejected(error))),
  )),
);

export const updateAttributeExternalUserEpic = action$ => action$.pipe(
  ofType(types.UPDATE_ATTRIBUTE_EXTERNAL_USER),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => externalUserActions.updateAttributeExternalUserSuccess(response)),
    catchError(error => of(externalUserActions.updateAttributeExternalUserRejected(error))),
  )),
);

export const updateDocumentStatusEpic = action$ => action$.pipe(
  ofType(types.UPDATE_DOCUMENT_STATUS),
  mergeMap(action => ajax.put(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      const normalizedData = getNormalizedData([response]);

      return externalUserActions.updateDocumentStatusSuccess(normalizedData.entities);
    }),
    catchError(error => of(externalUserActions.updateDocumentStatusRejected(error))),
  )),
);
