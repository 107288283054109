import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, CardBody, Card } from 'reactstrap';
import classnames from 'classnames';

import settingsTypes from './settingsTypes';
import GeneralForm from './components/generalForm';
import SecurityForm from './components/securityForm';
import TransactionForm from './components/transactionForm';
import XlxsForm from './components/xlsxForm';
import EmailTemplates from './components/emailTemplates';

const Settings = ({
  t, data, activeTab, goToType, updateSettings, uploadXlsxFile,
  canUpdateEmailTemplates,
}) => (
  <div className="settings-card">
    <Card>
      <CardHeader>
        <strong><i className="fa fa-cogs" /> {t('Settings')}</strong>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === settingsTypes.GENERAL })}
              onClick={() => { goToType(settingsTypes.GENERAL); }}
            >
              <i className="fa fa-cog" /> {t('General')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === settingsTypes.SECURITY })}
              onClick={() => { goToType(settingsTypes.SECURITY); }}
            >
              <i className="fa fa-lock" /> {t('Security')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === settingsTypes.TRANSACTION })}
              onClick={() => { goToType(settingsTypes.TRANSACTION); }}
            >
              <i className="fa fa-external-link-square" /> {t('Transaction')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === settingsTypes.SANCTION_XLSX })}
              onClick={() => { goToType(settingsTypes.SANCTION_XLSX); }}
            >
              <i className="fa fa-external-link-square" /> {t('XLSX')}
            </NavLink>
          </NavItem>
          <NavItem hidden={!canUpdateEmailTemplates}>
            <NavLink
              className={classnames({ active: activeTab === settingsTypes.EMAIL_TEMPLATES })}
              onClick={() => { goToType(settingsTypes.EMAIL_TEMPLATES); }}
            >
              <i className="fa fa-envelope" /> {t('Email templates')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={settingsTypes.GENERAL}>
            <GeneralForm
              t={t}
              data={data.admin}
              updateSettings={updateSettings}
            />
          </TabPane>
          <TabPane tabId={settingsTypes.SECURITY}>
            <SecurityForm
              t={t}
              data={data.security}
              updateSettings={updateSettings}
            />
          </TabPane>
          <TabPane tabId={settingsTypes.TRANSACTION}>
            <TransactionForm
              t={t}
              data={data.transaction}
              updateSettings={updateSettings}
            />
          </TabPane>
          <TabPane tabId={settingsTypes.SANCTION_XLSX}>
            <XlxsForm
              t={t}
              uploadXlsxFile={uploadXlsxFile}
              // data={data.transaction}
              updateSettings={updateSettings}
            />
          </TabPane>
          <TabPane tabId={settingsTypes.EMAIL_TEMPLATES}>
            <EmailTemplates
              t={t}
              updateSettings={updateSettings}
            />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </div>
);

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  goToType: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  canUpdateEmailTemplates: PropTypes.bool.isRequired,
};

export default Settings;
