import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchGridData, fetchGridDataSuccess, fetchGridDataRejected,
  removeItem, removeItemSuccess, removeItemRejected,
} = createActions(
  {
    [types.FETCH_GRID_DATA]: [
      identity,
      nthArg(1),
    ],
    [types.REMOVE_ITEM]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_GRID_DATA_SUCCESS,
  types.FETCH_GRID_DATA_REJECTED,

  types.REMOVE_ITEM_SUCCESS,
  types.REMOVE_ITEM_REJECTED,
);

export default {
  fetchGridData,
  fetchGridDataSuccess,
  fetchGridDataRejected,

  removeItem,
  removeItemSuccess,
  removeItemRejected,
};
