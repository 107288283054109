import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer, rootEpic } from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'permissions'],
};

const persistedReducer = persistReducer(persistConfig, createReducer);
const epicMiddleware = createEpicMiddleware();

export default () => {
  const store = createStore(
    persistedReducer,
    // applyMiddleware(thunk, epicMiddleware, createLogger()),
    applyMiddleware(thunk, epicMiddleware),
  );

  epicMiddleware.run(rootEpic);

  const persistor = persistStore(store);
  return { store, persistor };
};
