import { compose, hoistStatics, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik/dist/index';
import Translation from '../../Translation';
import FirstLoading from '../../../../../components/FirstLoading';
import apiRoutes from '../../../../../services/api.routes';
import withTranslation from '../../../translate';
// import rules from '../../../translate/rules';


const enhance = compose(
  withTranslation,
  withFormik({
    mapPropsToValues: ({ translation }) => ({
      // name: translation.name,
      // title: translation.title,
      // image: translation.image,
      // status: translation.status,
      // priority: translation.priority,
      // admin_status: translation.adminStatus,
      // is_default: translation.isDefault,
    }),
    // validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { updateTranslation, match },
      setSubmitting,
    }) => {
      updateTranslation(values, {
        url: `${apiRoutes.translations}/${match.params.id}`,
        setSubmitting,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchTranslation, match } = this.props;

      fetchTranslation(null, {
        url: `${apiRoutes.translations}/${match.params.id}`,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
);

export default withRouter(hoistStatics(enhance)(Translation));
