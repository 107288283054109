import { compose, hoistStatics, withHandlers, withStateHandlers } from 'recompose';
import _ from 'lodash';
import Transaction from './Transaction';
import statusList from './../status';
import urls from './urls';

const toggle = props => (name, value) => {
  const { setPopoverOpen } = props;
  setPopoverOpen(name, value);
};

const status = props => () => {
  switch (props.value.warningStatus) {
    case statusList.WARNING:
      return {
        title: 'Warning',
        color: 'warning',
      };
    case statusList.APPROVE:
      return {
        title: 'Approve',
        color: 'success',
      };
    case statusList.DECLINE:
      return {
        title: 'Decline',
        color: 'danger',
      };
    default:
      return {
        title: 'Checked',
        color: 'primary',
      };
  }
};

const userLink = () => (userId, userStatus) => {
  if (urls.WHITE_LIST === userStatus) {
    return `/white-list-users/${userStatus}/${userId}`;
  } else if (urls.BLACK_LIST === userStatus) {
    return `/black-list-users/${userStatus}/${userId}`;
  } else if (urls.NEW === userStatus) {
    return `/new-users/${userStatus}/${userId}`;
  } else if (urls.PENDING.includes(userStatus)) {
    return `/pending-users/${userStatus}/${userId}`;
  }

  return '';
};

const isShowPopover = props => () => !_.includes([
  statusList.APPROVE,
  statusList.DECLINE,
], props.value.warningStatus);

const enhance = compose(
  withStateHandlers(() => ({
    popoverStatus: false,
    popoverWarningDetails: false,
  }), {
    setPopoverOpen: state => (name, value) => ({
      ...state, [name]: !value,
    }),
  }),
  withHandlers({
    toggle,
    status,
    isShowPopover,
    userLink,
  }),
);

export default hoistStatics(enhance)(Transaction);
