import apiRoutes from './api.routes';
import ModelService from './model.service';

const LanguageService = new ModelService(apiRoutes.languages, '/languages', 'languages');

LanguageService.getImage = (filename, w = false, h = false, c = false) => LanguageService.getFilePathByDir('/uploads/languages', filename, w, h, c);

LanguageService.getSettings = async () => {
  const { data } = await ModelService.getData({ order: 'asc' });
  const defaultLanguage = data.reduce((result, lang) => (lang.is_default ? lang.name : result), 'en');
  return {
    data,
    defaultLanguage,
  };
};

export default LanguageService;
