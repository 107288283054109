import reducer from './reducers';
import * as usersCountEpics from './epics';

export {
  usersCountEpics,
};

export { default as usersNumberActions } from './actions';

export default reducer;
