import { compose, hoistStatics, lifecycle } from 'recompose';
import UserView from './UserView';
import ModelService from '../../../../../services/user.service';
import canUpdate from '../../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../../services/api.routes';
import withUser from '../../../user';
import FirstLoading from '../../../../../components/FirstLoading';

const enhance = compose(
  withUser,
  lifecycle({
    componentDidMount() {
      const { fetchCdUser, match } = this.props;

      fetchCdUser(null, {
        url: `${apiRoutes.users}/${match.params.id}`,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  canUpdate(ModelService),
);

export default (hoistStatics(enhance)(UserView));
