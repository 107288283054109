export default {
  FETCH_LANGUAGE: 'FETCH_LANGUAGE',
  FETCH_LANGUAGE_SUCCESS: 'FETCH_LANGUAGE_SUCCESS',
  FETCH_LANGUAGE_REJECTED: 'FETCH_LANGUAGE_REJECTED',
  CREATE_LANGUAGE: 'CREATE_LANGUAGE',
  CREATE_LANGUAGE_SUCCESS: 'CREATE_LANGUAGE_SUCCESS',
  CREATE_LANGUAGE_REJECTED: 'CREATE_LANGUAGE_REJECTED',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  UPDATE_LANGUAGE_SUCCESS: 'UPDATE_LANGUAGE_SUCCESS',
  UPDATE_LANGUAGE_REJECTED: 'UPDATE_LANGUAGE_REJECTED',
};
