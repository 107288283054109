import { compose, hoistStatics, lifecycle, withHandlers, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import SanctionedBySwitzerland from './SanctionedBySwitzerland';
import FirstLoading from './../../components/FirstLoading';
import { sanctionedBySwitzerlandActions } from './../../reducers/sanctionedBySwitzerland';


const matchItem = ({ toggleConfirm, setSelectedId }) => (id) => {
  setSelectedId(id);
  toggleConfirm();
};

const defaultConfirmCallback = ({
  toggleConfirm, matchParams, selectedId, noMatch,
}) => () => {
  noMatch({
    userId: matchParams.id,
    id: selectedId,
  }, {
    url: '/invalidMatchingSanction',
  });

  toggleConfirm();
};

const mapStateToProps = ({ sanctionedBySwitzerland }) => ({
  data: sanctionedBySwitzerland.data,
  loading: sanctionedBySwitzerland.loading,
  firstLoading: sanctionedBySwitzerland.firstLoading,
});

const mapDispatchToProps = {
  fetchSanctionedBySwitzerland: sanctionedBySwitzerlandActions.fetchSanctionedBySwitzerland,
  noMatch: sanctionedBySwitzerlandActions.noMatch,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(() => ({
    toggle: false,
    showConfirm: false,
    selectedId: null,
    index: null,
  }), {
    toggleModal: state => index => ({
      toggle: !state.toggle,
      index,
    }),
    toggleConfirm: state => () => ({
      showConfirm: !state.showConfirm,
    }),
    setSelectedId: () => id => ({
      selectedId: id,
    }),
  }),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchSanctionedBySwitzerland, matchParams: { id } } = this.props;

      fetchSanctionedBySwitzerland({ userId: id }, {
        url: '/getSanctionsFromXlsx',
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  withHandlers({
    matchItem,
    defaultConfirmCallback,
  }),
);

export default hoistStatics(enhance)(SanctionedBySwitzerland);
