import { toast } from 'react-toastify';
import { withState } from 'recompose';
import { translate } from 'react-i18next';
import sh from './string.helper';

const methodPrefix = 'set';

const getSetMethodName = (name) => {
  let parts = name.split('_');
  parts = parts.map(part => sh.firstLetterToUpperCase(part));
  return methodPrefix + parts.join('');
};

const getWithStateOptions = (name, defaultValue = '') => [name, getSetMethodName(name), defaultValue];

const onChange = props => event => props[getSetMethodName(event.target.name)](event.target.value);

/**
 * Update formData simple attribute
 * @param props
 */
const onChangeFormDataElement = props => ({ target }) => {
  const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
  return props.setFormData({
    ...props.formData,
    [target.name]: value,
  });
};

/**
 * Update formData group attribute
 * @param props
 */
const onChangeFormDataElementByGroup = props => group => ({ target }) => {
  const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
  return props.setFormData({
    ...props.formData,
    [group]: {
      ...props.formData[group],
      [target.name]: value,
    },
  });
};

/**
 * Update formData multilang attribute
 * @param props
 */
const onChangeFormDataMlElement = props => (lang, defaultLanguage) => ({ target }) => {
  if (lang === defaultLanguage) {
    props.setFormData({
      ...props.formData,
      [target.name]: target.value,
      ml: {
        ...props.formData.ml,
        [lang]: {
          ...props.formData.ml[lang],
          [target.name]: target.value,
        },
      },
    });
  } else {
    props.setFormData({
      ...props.formData,
      ml: {
        ...props.formData.ml,
        [lang]: {
          ...props.formData.ml[lang],
          [target.name]: target.value,
        },
      },
    });
  }
};

const notify = (t, text) => toast.success(text || t('Successfully saved!'));

const defaultRecompose = defaultFormData => ([
  translate('core'),
  withState(...getWithStateOptions('errors', {})),
  withState(...getWithStateOptions('isNewModel', true)),
  withState(...getWithStateOptions('isLoading', true)),
  withState(...getWithStateOptions('firstLoading', true)),
  withState(...getWithStateOptions('isSubmitting', false)),
  withState(...getWithStateOptions('isSubmittingRedirect', false)),
  withState('formData', 'setFormData', defaultFormData),
]);

const titlePrefix = ' #';
const getTitleEnd = props => (props.match && props.match.params.id ? titlePrefix + props.match.params.id : '');

const beforeSave = async (event, props, redirect) => {
  event.persist();
  event.preventDefault();
  await props.setIsSubmittingRedirect(redirect);
  props.setIsSubmitting(true);
};

const initModelData = async (props, applyModel, ModelService, afterSetModel) => {
  if (props.match && props.match.params.id) {
    const model = await ModelService.get(props.match.params.id);
    await applyModel(props.setFormData, model);
    await props.setIsNewModel(false);
    if (typeof afterSetModel === 'function') {
      await afterSetModel(props, model);
    }
    props.setIsLoading(false);
    props.setFirstLoading(false);
    return model;
  }
  props.setIsLoading(false);
  props.setFirstLoading(false);
  return null;
};

const createApplyModel = defaultFormData => async (setFormData, model) => {
  await setFormData({
    ...defaultFormData,
    ...model,
  });
};

export default {
  getWithStateOptions,
  wso: getWithStateOptions,
  getSetMethodName,
  smn: getSetMethodName,
  onChange,
  onChangeFormDataElement,
  onChangeFormDataMlElement,
  onChangeFormDataElementByGroup,
  notify,
  defaultRecompose,
  getTitleEnd,
  beforeSave,
  initModelData,
  createApplyModel,
};
