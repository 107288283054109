import React from 'react';
import { Badge } from 'reactstrap';
import { filter, pipe, prop } from 'ramda';
import { translate } from 'react-i18next';

import SanctionCheckStatuses from '../../../constants/SanctionCheckStatuses';
import stringHelpers from '../../../helpers/string.helper';

const { toCamelCaseWithSpaces } = stringHelpers;

const Index = ({ user, t }) => (
  <Badge
    color={user.sanction_check === 1 ? 'success' : 'danger'}
    className="ml-1 mr-1"
  >
    {pipe(
      prop('sanction_check'),
      num => filter(entry => entry[1] === num)(Object.entries(SanctionCheckStatuses)),
      (arr = [['', '']]) => (arr.length ? arr[0][0] : ''),
      toCamelCaseWithSpaces,
      t,
    )(user)}
  </Badge>
);

export default translate('core')(Index);
