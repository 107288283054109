import { compose, hoistStatics, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Ws from '@adonisjs/websocket-client';
import { toast } from 'react-toastify';
import Layout from './Layout';
import { usersNumberActions } from '../../reducers/usersCount';
import { userIsAuthenticated } from '../../helpers/auth.helper';

const ws = Ws(`${process.env.REACT_APP_WS_URL}`);

const mapStateToProps = state => ({
  permissions: state.permissions,
  usersCount: state.usersCount.data,
  token: state.auth.token,
});

const mapDispatchToProps = {
  getUsersCount: usersNumberActions.fetchUsersNumber,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  userIsAuthenticated,
  lifecycle({
    componentDidMount() {
      const { getUsersCount, token } = this.props;

      getUsersCount(null, {
        url: 'checkedUsersOverview',
      });

      // todo need use middelware
      ws.withJwtToken(token).connect();
      ws.subscribe('CDManagerNotification');
      ws.getSubscription('CDManagerNotification').on('message', (data) => {
        if (data.type === 'warning') {
          return toast.warn(data.message);
        }

        if (data.type === 'info') {
          return toast.info(data.message);
        }

        return toast.success(data.message, { autoClose: false });
      });
    },
    componentWillUnmount() {
      ws.close();
    },
  }),
);

export default withRouter(hoistStatics(enhance)(Layout));
