import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  exportUsers, exportUsersSuccess, exportUsersRejected,
  downloadFile, downloadFileSuccess,
} = createActions(
  {
    [types.EXPORT_USERS]: [
      identity,
      nthArg(1),
    ],
  },
  types.EXPORT_USERS_SUCCESS,
  types.EXPORT_USERS_REJECTED,

  types.DOWNLOAD_FILE,
  types.DOWNLOAD_FILE_SUCCESS,
);

export default {
  exportUsers,
  exportUsersSuccess,
  exportUsersRejected,

  downloadFile,
  downloadFileSuccess,
};
