export default {
  FETCH_ROLE: 'FETCH_ROLE',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_REJECTED: 'FETCH_ROLE_REJECTED',
  CREATE_ROLE: 'CREATE_ROLE',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_REJECTED: 'CREATE_ROLE_REJECTED',
  UPDATE_ROLE: 'UPDATE_ROLE',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_REJECTED: 'UPDATE_ROLE_REJECTED',
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_REJECTED: 'FETCH_ROLES_REJECTED',
  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_REJECTED: 'FETCH_PERMISSIONS_REJECTED',
};
