import reducer from './reducers';
import * as settingsEpics from './epics';

export {
  settingsEpics,
};

export { default as settingsActions } from './actions';

export default reducer;
