import * as yup from 'yup';

export default yup.object().shape({
  type: yup.number().required({
    message: 'Type cannot be empty',
    params: { key: 'Type' },
  }),
  dateOfExpiration: yup.date().required({
    message: 'Date of expiration cannot be empty',
    params: { key: 'DateOfExpiration' },
  }),
  docType: yup.string().required({
    message: 'Document type cannot be empty',
    params: { key: 'DocType' },
  }),
});
