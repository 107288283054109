import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FILE_STATUSES from '../../../../../../../constants/FileStatuses';


const DocumentModal = ({
  t, toggle, toggleModal, imageData, token, changeStatus, url,
}) => (
  <Modal
    isOpen={toggle}
    toggle={() => toggleModal(!toggle)}
    className="modal-lg"
  >
    <ModalHeader >{imageData.filename}</ModalHeader>
    <ModalBody>
      <img
        alt="img"
        src={`${url}/${imageData.id}?token=${token}`}
        width="100%"
      />
    </ModalBody>

    <div className="modal-info">
      <p>{`IP: ${imageData.ip_address}`}</p>
      <p>{`IP Location: ${imageData.ip_location}`}</p>
      <p>{`Upload date: ${moment(imageData.created_at).format('L')}`}</p>
      <p>{`Filename: ${imageData.filename}`}</p>
    </div>
    <ModalFooter>
      <div className="d-flex">
        {imageData.status !== FILE_STATUSES.APPROVED &&
        <Button
          className="mr-1 btn btn-success"
          onClick={() => changeStatus(imageData.id, FILE_STATUSES.APPROVED)}
        >
          {t('Approve')}
        </Button>}
        {imageData.status !== FILE_STATUSES.DECLINED &&
        <Button
          className="mr-1 btn btn-danger"
          onClick={() => changeStatus(imageData.id, FILE_STATUSES.DECLINED)}
        >
          {t('Decline')}
        </Button>}
        {imageData.status !== FILE_STATUSES.OLD &&
        <Button
          className="mr-1 btn btn-warning"
          onClick={() => changeStatus(imageData.id, FILE_STATUSES.OLD)}
        >
          {t('Old')}
        </Button>}
      </div>

      <Button
        color="secondary"
        onClick={() => toggleModal(!toggle)}
      >
        {t('Close')}
      </Button>
    </ModalFooter>
  </Modal>
);

DocumentModal.propTypes = {
  t: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  imageData: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

export default DocumentModal;
