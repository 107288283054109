import { handleActions } from 'redux-actions';

import types from './types';
import { mergeByProp } from '../../helpers/store.helper';

const initialState = {
  data: [],
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 0,
  loading: true,
  firstLoading: false,
  error: false,
  checks: {
    data: {},
    loading: true,
    error: false,
  },
};

const reducer = handleActions(
  {
    [types.FETCH_SANCTIONED_USERS]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_SANCTIONED_USERS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      total: action.payload.total,
      perPage: action.payload.perPage,
      page: action.payload.page,
      lastPage: action.payload.lastPage,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_SANCTIONED_USERS_REJECTED]: (state, action) => ({
      ...state,
      data: [],
      loading: false,
      firstLoading: false,
      error: true,
    }),

    [types.FETCH_SANCTION_CHECKS]: mergeByProp('checks', () => ({
      loading: true,
      error: false,
    })),
    [types.FETCH_SANCTION_CHECKS_SUCCESS]: mergeByProp('checks', ({ payload: data }) => ({
      data,
      loading: false,
      error: false,
    })),
    [types.FETCH_SANCTION_CHECKS_REJECTED]: mergeByProp('checks', () => ({
      loading: false,
      error: true,
    })),
  },
  initialState,
);

export default reducer;
