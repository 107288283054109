import api from './api.service';
import apiRoutes from './api.routes';

const generate2FACode = async (username, password) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.generate2FACode, {
    username,
    password,
  });
  return data;
});

const login = async (code, username) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.login, {
    code,
    username,
  });
  return data;
});

const getCurrentUser = async () => {
  const { data } = await api.get(apiRoutes.currentUser);
  return data;
};

const logout = async () => {
  const { data } = await api.get(apiRoutes.logout);
  return data;
};

const forgotPassword = async email => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.forgotPassword, {
    email,
  });
  return data;
});

const changePassword = async (id, code, password, repeat) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.changePassword, {
    id,
    code,
    password,
    repeat,
  });
  return data;
});

const checkCode = async (id, code) => api.catchError(async () => {
  const { data } = await api.get(apiRoutes.checkCode, {
    id,
    code,
  });
  return data;
});

export default {
  generate2FACode,
  login,
  getCurrentUser,
  forgotPassword,
  checkCode,
  changePassword,
  setToken: api.setToken,
  clearToken: api.clearToken,
  logout,
};
