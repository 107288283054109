import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
// import SubmitButtons from '../../../components/cms/form/submitButtons';
// import InputFile from '../../../components/cms/form/inputFile';
// import PreviewImage from '../../../components/previewImage';

const twoCols = { xs: 12, md: 6 };

const Language = ({
  t, errors, title, isSubmitting, values, handleSubmit, handleChange,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-language pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={9}>
                  <FormGroup row className="my-0">
                    <Col {...twoCols}>
                      <InputGroup
                        name="name"
                        label={t('Name')}
                        onChange={handleChange}
                        errors={errors}
                        value={values.name}
                        icon="fa fa-flag"
                      />
                    </Col>
                    <Col {...twoCols}>
                      <InputGroup
                        name="title"
                        label={t('Title')}
                        onChange={handleChange}
                        errors={errors}
                        value={values.title}
                        icon="fa fa-flag-o"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Col {...twoCols}>
                      <InputGroup
                        name="status"
                        label={t('Status')}
                        onChange={handleChange}
                        errors={errors}
                        type="checkbox"
                        value={1}
                        checked={values.status}
                      />
                    </Col>
                    <Col {...twoCols}>
                      <InputGroup
                        name="admin_status"
                        label={t('Admin GUI status')}
                        onChange={handleChange}
                        errors={errors}
                        type="checkbox"
                        value={1}
                        checked={values.admin_status}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <InputGroup
                    name="priority"
                    label={t('Priority')}
                    onChange={handleChange}
                    errors={errors}
                    value={values.priority}
                    icon="fa fa-sort-amount-asc"
                  />
                  <InputGroup
                    name="is_default"
                    label={t('Is default')}
                    type="checkbox"
                    onChange={handleChange}
                    errors={errors}
                    value={1}
                    disabled
                    checked={values.is_default}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    className="mr-2 mb-1 btn-brand"
                  >
                    <i className="fa fa-save mr-2" />{t('Save')}
                  </Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Language.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  // title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Language;
