import React from 'react';
import classnames from 'classnames';
import {
  NavItem,
  Nav,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';

const MlFields = ({ langs, fields, toggle, activeTab, defaultLanguage, isDefaultLang, formData, getLangFormData, onChange }) => (
  <React.Fragment>
    <div className="mb-2">
      <Nav tabs>
        {langs.map((lang) => (
          <NavItem key={lang.name}>
            <NavLink
              className={classnames({ active: activeTab === lang.name })}
              onClick={() => { toggle(lang.name); }}
            >
              {lang.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {langs.map((lang) => (
          <TabPane tabId={lang.name} key={lang.name}>
            {fields({
              formData: getLangFormData(lang.name, formData),
              onChange: onChange(lang.name, defaultLanguage),
            })}
          </TabPane>
        ))}
      </TabContent>
    </div>
  </React.Fragment>
);

export default MlFields;
