import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Switch } from 'react-router-dom';
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert/dist/sweetalert.css';

// Import Main styles for this application
import './scss/style.css';
import './App.css';
import { checkUser, userIsNotAuthenticated } from './helpers/auth.helper';

// Containers
import Layout from './containers/layout';
import Login from './views/login';
import TwoFaForm from './views/twoFaForm';
import ForgotPassword from './views/forgotPassword';
import ResetPassword from './views/resetPassword';

const App = () => (
  <React.Fragment>
    <ToastContainer />
    <Switch>
      <Route exact path="/login" name="Login Page" component={userIsNotAuthenticated(Login)} />
      <Route exact path="/two-fa-code" name="" component={checkUser(TwoFaForm)} />
      <Route exact path="/forgot-password" component={userIsNotAuthenticated(ForgotPassword)} />
      <Route exact path="/reset-password/:id/:code" component={userIsNotAuthenticated(ResetPassword)} />
      <Route path="/" name="Home" render={props => <Layout {...props} />} />
    </Switch>
  </React.Fragment>
);

export default App;
