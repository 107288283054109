import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/cms/grid';
import ModelService from '../../services/user.service';

const initCriteria = {
  order: 'desc',
  id: '',
  first_name: '',
  last_name: '',
  status: '',
  username: '',
  email: '',
  phone: '',
};

const Users = ({ t, getColumns }) => (
  <Grid
    id="users"
    title={t('Compliance desk users')}
    service={ModelService}
    columns={getColumns(t)}
    initCriteria={initCriteria}
    removeUrl="users"
    showAddButton
  />
);

Users.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Users;
