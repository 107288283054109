import * as yup from 'yup';

export default yup.object().shape({
  to: yup.string().required({
    message: 'To cannot be empty',
    params: { key: 'To' },
  }),
  subject: yup.string().required({
    message: 'Subject cannot be empty',
    params: { key: 'Subject' },
  }),
  text: yup.string().required({
    message: 'Text cannot be empty',
    params: { key: 'Text' },
  }),
  type: yup.string().required({
    message: 'Type cannot be empty',
    params: { key: 'Type' },
  }),
});
