import { compose, getContext, hoistStatics, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import ContractModal from './ContractModal';


const enhance = compose(
  getContext({
    toggleModal: PropTypes.func,
    toggle: PropTypes.bool,
  }),
  withHandlers({}),
);

export default hoistStatics(enhance)(ContractModal);
