import { compose, hoistStatics, lifecycle, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import SanctionedUsers from './SanctionedUsers';
import FirstLoading from './../../components/FirstLoading';
import apiRoutes from './../../services/api.routes';
import { sanctionedUsersActions } from './../../reducers/sanctionedUsers';

const goToPage = props => (page) => {
  const { fetchSanctionedUsers, userId } = props;

  fetchSanctionedUsers({ ...userId, page, limit: 10 }, {
    url: apiRoutes.getSanctionedUsers,
  });
};

const mapStateToProps = ({ sanctionedUsers }) => ({
  data: sanctionedUsers.data,
  lastPage: sanctionedUsers.lastPage,
  page: sanctionedUsers.page,
  perPage: sanctionedUsers.perPage,
  total: sanctionedUsers.total,
  loading: sanctionedUsers.loading,
  firstLoading: sanctionedUsers.firstLoading,
});

const mapDispatchToProps = {
  fetchSanctionedUsers: sanctionedUsersActions.fetchSanctionedUsers,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchSanctionedUsers, userId } = this.props;
      fetchSanctionedUsers(userId || null, {
        url: apiRoutes.getSanctionedUsers,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  withHandlers({
    goToPage,
  }),
);

export default hoistStatics(enhance)(SanctionedUsers);
