import { compose, hoistStatics, withProps, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import ColumnTitle from './ColumnFilter';
import columnTypes from '../columnTypes';
import gh from '../grid.helper';

const getCanFilter = (props) => {
  const { filter, type } = props.column;
  if (type && type === columnTypes.BUTTONS) return false;
  if (typeof filter === 'undefined') return true;
  return !!filter;
};

const enhance = compose(
  translate('core'),
  withProps(props => ({
    canFilter: getCanFilter(props),
    attribute: gh.getColumnName(props.column),
    value: props.criteria[gh.getColumnName(props.column)] ? props.criteria[gh.getColumnName(props.column)] : '',
  })),
  withHandlers({
    onFilter: props => (event) => {
      if (event.preventDefault) event.preventDefault();
      props.filter({
        attribute: props.attribute,
        value: event.target.value,
      });
    },
  }),
);

export default hoistStatics(enhance)(ColumnTitle);
