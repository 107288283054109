import { compose, hoistStatics, withState, lifecycle, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import Settings from './Settings';
import settingsActions from '../../reducers/settings/actions';
import settingsTypes from './settingsTypes';
import apiRoutes from '../../services/api.routes';
import FirstLoading from '../../components/FirstLoading';
import { hasPermissions } from '../../helpers/permissions.helper';

const mapStateToProps = state => ({
  data: state.settings.data,
  firstLoading: state.settings.firstLoading,
  canUpdateEmailTemplates: hasPermissions(["update_emailTemplates"])(state),
});

const mapDispatchToProps = {
  fetchSettings: settingsActions.fetchSettings,
  updateSettings: settingsActions.updateSettings,
  uploadXlsxFile: settingsActions.uploadXlsxFile,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  // TODO: Change default tab back
  withState('activeTab', 'setActiveTab', settingsTypes.GENERAL),
  lifecycle({
    componentDidMount() {
      const { fetchSettings } = this.props;
      fetchSettings(null, {
        url: apiRoutes.settings,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  withHandlers({
    goToType: ({ setActiveTab }) => type => (
      setActiveTab(type)
    ),
  }),
);

export default hoistStatics(enhance)(Settings);
