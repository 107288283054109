import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import React from 'react';
import { Trans } from 'react-i18next';
import { pipe, prop, tap } from 'ramda';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload } from '../../helpers/epics.helper';
import { getNormalizedData, getValues } from '../../helpers/normalize.helper';
import types from './types';
import * as actions from './actions';

const apiRoute = `${apiUrl}/emailTemplates`;

export const fetchEmailTemplates = action$ => action$.pipe(
  ofType(types.FETCH_EMAIL_TEMPLATES),
  mergeMap(action => ajax.get(`${apiRoute}/all`, getPayload(action)).pipe(
    map(pipe(
      prop('response'),
      getValues,
      getNormalizedData,
      actions.fetchEmailTemplatesSuccess,
    )),
    catchError(error => of(actions.fetchEmailTemplatesRejected(error))),
  )),
);

export const createEmailTemplate = action$ => action$.pipe(
  ofType(types.CREATE_EMAIL_TEMPLATE),
  mergeMap(action => ajax.post(`${apiRoute}`, getPayload(action)).pipe(
    map(pipe(
      prop('response'),
      prop('model'),
      Array.of,
      getNormalizedData,
      tap(() => {
        toast.success(<Trans>Successfully created</Trans>);
      }),
      actions.createEmailTemplateSuccess,
    )),
    catchError(error => of(actions.createEmailTemplateRejected(error))),
  )),
);

export const updateEmailTemplate = action$ => action$.pipe(
  ofType(types.UPDATE_EMAIL_TEMPLATE),
  mergeMap(({ payload, meta: id }) => ajax.put(`${apiRoute}/${id}`, payload).pipe(
    map(pipe(
      prop('response'),
      Array.of,
      getNormalizedData,
      tap(() => {
        toast.success(<Trans>Successfully updated</Trans>);
      }),
      actions.updateEmailTemplateSuccess,
    )),
    catchError(error => of(actions.updateEmailTemplateRejected(error))),
  )),
);

export const deleteEmailTemplate = action$ => action$.pipe(
  ofType(types.DELETE_EMAIL_TEMPLATE),
  mergeMap(({ payload: id }) => ajax.remove(`${apiRoute}/${id}`).pipe(
    map(() => pipe(
      tap(() => {
        toast.success(<Trans>Successfully deleted</Trans>);
      }),
      actions.deleteEmailTemplateSuccess,
    )(id)),
    catchError(error => of(actions.deleteEmailTemplateRejected(error))),
  )),
);
