import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ t }) => (
  <React.Fragment>
    <span>{t('Compliance desk')} &copy; {new Date().getFullYear()}</span>
    <span className="ml-auto">
      {t('Powered by')} <a
        href="https://wetelo.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Wetelo')}
      </a>
    </span>
  </React.Fragment>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Footer;
