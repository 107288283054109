import React from 'react';
import PropTypes from 'prop-types';

const ValidationMessage = ({ message, valid, wasValidated }) => (
  <React.Fragment>
    { wasValidated && !valid && (
      <div className="invalid-feedback">
        {message}
      </div>
    )
    }
  </React.Fragment>
);

ValidationMessage.defaultProps = {
  wasValidated: true,
};

ValidationMessage.propTypes = {
  message: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  wasValidated: PropTypes.bool,
};

export default ValidationMessage;
