import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchExternalUser, fetchExternalUserSuccess, fetchExternalUserRejected,
  fetchExternalUserBalance, fetchExternalUserBalanceSuccess, fetchExternalUserBalanceRejected,
  changeStatusExternalUser, changeStatusExternalUserSuccess, changeStatusExternalUserRejected,
  changeStatusAddress, changeStatusAddressSuccess, changeStatusAddressRejected,

  updateAttributeExternalUser,
  updateAttributeExternalUserSuccess,
  updateAttributeExternalUserRejected,

  updateDocumentStatus, updateDocumentStatusSuccess, updateDocumentStatusRejected,
} = createActions(
  {
    [types.FETCH_EXTERNAL_USER]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_EXTERNAL_USER_BALANCE]: [
      identity,
      nthArg(1),
    ],
    [types.CHANGE_STATUS_EXTERNAL_USER]: [
      identity,
      nthArg(1),
    ],
    [types.CHANGE_STATUS_ADDRESS]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_ATTRIBUTE_EXTERNAL_USER]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_DOCUMENT_STATUS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_EXTERNAL_USER_SUCCESS,
  types.FETCH_EXTERNAL_USER_REJECTED,

  types.FETCH_EXTERNAL_USER_BALANCE_SUCCESS,
  types.FETCH_EXTERNAL_USER_BALANCE_REJECTED,

  types.CHANGE_STATUS_EXTERNAL_USER_SUCCESS,
  types.CHANGE_STATUS_EXTERNAL_USER_REJECTED,

  types.CHANGE_STATUS_ADDRESS_SUCCESS,
  types.CHANGE_STATUS_ADDRESS_REJECTED,

  types.UPDATE_ATTRIBUTE_EXTERNAL_USER_SUCCESS,
  types.UPDATE_ATTRIBUTE_EXTERNAL_USER_REJECTED,

  types.UPDATE_DOCUMENT_STATUS_SUCCESS,
  types.UPDATE_DOCUMENT_STATUS_REJECTED,
);

export default {
  fetchExternalUser,
  fetchExternalUserSuccess,
  fetchExternalUserRejected,

  fetchExternalUserBalance,
  fetchExternalUserBalanceSuccess,
  fetchExternalUserBalanceRejected,

  changeStatusExternalUser,
  changeStatusExternalUserSuccess,
  changeStatusExternalUserRejected,

  changeStatusAddress,
  changeStatusAddressSuccess,
  changeStatusAddressRejected,

  updateAttributeExternalUser,
  updateAttributeExternalUserSuccess,
  updateAttributeExternalUserRejected,

  updateDocumentStatus,
  updateDocumentStatusSuccess,
  updateDocumentStatusRejected,
};
