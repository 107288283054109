import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Popover, PopoverBody } from 'reactstrap';
import statusList from '../statusList';


const Item = ({
  t, value, popoverStatus, toggle, status, changeStatusAddress
}) => (
  <tr key={value.id}>
    <td>{value.id}</td>
    <td>{value.address}</td>
    <td>{value.city}</td>
    <td>{value.country}</td>
    <td>{value.zip_code}</td>
    <td>{value.district}</td>
    <td>
      <Badge
        id={`Popover${value.id}`}
        color={status().color}
        onClick={() => toggle('popoverStatus', popoverStatus)}
        style={{ cursor: 'pointer' }}
      >
        {status().title}
      </Badge>
      <Popover
        placement="top"
        isOpen={popoverStatus}
        target={`Popover${value.id}`}
        toggle={toggle}
      >
        <PopoverBody className="d-flex">
          <button
            className="mr-1 btn btn-success w-50"
            onClick={() => changeStatusAddress(value.id, statusList.APPROVE)}
            // disabled={!!loading}
          >
            {t('Approve')}
          </button>
          <button
            className="mr-1 btn btn-danger w-50"
            onClick={() => changeStatusAddress(value.id, statusList.DECLINE)}
            // disabled={!!loading}
          >
            {t('Decline')}
          </button>
          <button
            className="btn btn-warning w-50"
            onClick={() => changeStatusAddress(value.id, statusList.OLD)}
            // disabled={!!loading}
          >
            {t('Old')}
          </button>
        </PopoverBody>
      </Popover>
    </td>
    <td>{value.created_at}</td>
    <td>{value.updated_at}</td>
  </tr>
);

Item.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  status: PropTypes.func.isRequired,
  popoverStatus: PropTypes.bool.isRequired,
  changeStatusAddress: PropTypes.func.isRequired,
};

export default Item;
