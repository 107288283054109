import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from 'reactstrap';
import { propOr } from 'ramda';
import { Trans } from 'react-i18next';

import './../../../assets/css/email.css';
import MailNavigation from '../components/navigation/MailNavigation';
import InputGroup from '../../../components/cms/form/inputGroup';
import { AddDocumentModal } from '../../modals';
import MessageStatuses from '../../../constants/MessageStatuses';

const Conversation = ({
  t,
  data,
  conversation,
  errors,
  handleSubmit,
  handleChange,
  values,
  toggleAddDocumentModal,
  image,
  setImage,
  userId,
  setUserId,
}) => (
  <div className="email-app mb-4">
    <MailNavigation t={t} />

    <main className="message">
      <div className="title">
        {conversation.subject}
      </div>

      {data.map(message => (
        <div className="details" key={message.id}>
          <div className="header">
            <div className="from">{message.fromName}</div>
            <div className="date">{message.date}</div>
          </div>

          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: message.body }}
          />
          {(() => {
            if (message.attachmentsStatus === MessageStatuses.NO_ATTACHMENTS) return [];
            if (message.attachmentsStatus === MessageStatuses.NOT_IMPORTED) {
              return (
                <Alert color="info">
                  <Trans>Attachments will be accessible later</Trans>
                </Alert>
              );
            }
            if (message.attachmentsStatus === MessageStatuses.NOT_VALID) {
              return (
                <Alert color="warning">
                  <Trans>Attachments failed validation</Trans>
                </Alert>
              );
            }
            return propOr([], 'attachments', message).map(({ id, filename }) => (
              <Button
                key={id}
                color="link"
                className="m-1"
                onClick={() => {
                  setImage(id);
                  setUserId(message.messageSenderId);
                  toggleAddDocumentModal();
                }}
              >
                {filename}
              </Button>
            ));
          })()}
        </div>
        ))}
      <form onSubmit={handleSubmit}>
        <div className="position-relative form-group">
          <InputGroup
            id="text"
            name="text"
            type="editor"
            label=""
            errors={errors}
            onChange={handleChange}
            value={values.text}
            icon="fa fa-flag-o"
          />
        </div>
        <div className="position-relative form-group">
          <button
            type="submit"
            className="btn btn-success"
          >
            {t('Send message')}
          </button>
        </div>
      </form>
    </main>
    <AddDocumentModal image={image} userId={userId} />
  </div>
);

Conversation.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Conversation;
