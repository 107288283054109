import { compose, getContext, hoistStatics, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import Contract from './Contract';


const enhance = compose(
  getContext({
    toggleModal: PropTypes.func,
    getContractData: PropTypes.func,
    toggle: PropTypes.bool,
  }),
  withHandlers({}),
);

export default hoistStatics(enhance)(Contract);
