export default {
  FETCH_CONVERSATIONS: 'FETCH_CONVERSATIONS',
  FETCH_CONVERSATIONS_SUCCESS: 'FETCH_CONVERSATIONS_SUCCESS',
  FETCH_CONVERSATIONS_REJECTED: 'FETCH_CONVERSATIONS_REJECTED',

  SET_CONVERSATION: 'SET_CONVERSATION',
  SET_CONVERSATION_SUCCESS: 'SET_CONVERSATION_SUCCESS',
  SET_CONVERSATION_REJECTED: 'SET_CONVERSATION_REJECTED',

  FETCH_MAIL: 'FETCH_MAIL',
  FETCH_MAIL_SUCCESS: 'FETCH_MAIL_SUCCESS',
  FETCH_MAIL_REJECTED: 'FETCH_MAIL_REJECTED',
};
