export default {
  FETCH_CD_USER: 'FETCH_CD_USER',
  FETCH_CD_USER_SUCCESS: 'FETCH_CD_USER_SUCCESS',
  FETCH_CD_USER_REJECTED: 'FETCH_CD_USER_REJECTED',

  CREATE_CD_USER: 'CREATE_CD_USER',
  CREATE_CD_USER_SUCCESS: 'CREATE_CD_USER_SUCCESS',
  CREATE_CD_USER_REJECTED: 'CREATE_CD_USER_REJECTED',

  UPDATE_CD_USER: 'UPDATE_CD_USER',
  UPDATE_CD_USER_SUCCESS: 'UPDATE_CD_USER_SUCCESS',
  UPDATE_CD_USER_REJECTED: 'UPDATE_CD_USER_REJECTED',

  DELETE_CD_USER: 'DELETE_CD_USER',
  DELETE_CD_USER_SUCCESS: 'DELETE_CD_USER_SUCCESS',
  DELETE_CD_USER_REJECTED: 'DELETE_CD_USER_REJECTED',

  UPLOAD_CD_USER_IMAGE: 'UPLOAD_CD_USER_IMAGE',
  UPLOAD_CD_USER_IMAGE_SUCCESS: 'UPLOAD_CD_USER_IMAGE_SUCCESS',
  UPLOAD_CD_USER_IMAGE_REJECTED: 'UPLOAD_CD_USER_IMAGE_REJECTED',

  ADD_DOCUMENT_TO_USER: 'ADD_DOCUMENT_TO_USER',
  ADD_DOCUMENT_TO_USER_SUCCESS: 'ADD_DOCUMENT_TO_USER_SUCCESS',
  ADD_DOCUMENT_TO_USER_REJECTED: 'ADD_DOCUMENT_TO_USER_REJECTED',
};
