import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Table, Badge } from 'reactstrap';
import Pagination from './../../components/cms/pagination';

const SanctionedUsers = ({
  t, data, loading, goToPage, perPage, total, page, lastPage,
}) => (
  <Card>
    <CardHeader>
      <i className="fa fa-align-justify" /> {t('New sanctions')}
    </CardHeader>
    <CardBody>
      <Table
        responsive
        striped
        className={loading ? 'loading' : ''}
      >
        <thead>
          <tr>
            <th>{t('User')}</th>
            <th>{t('Date')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map(value => (
            <tr key={value.id}>
              <td>{`${value.first_name} ${value.last_name}`}</td>
              <td>{value.date_of_sanction ? value.date_of_sanction : '-'}</td>
            </tr>
           ))}
        </tbody>
        <tfoot>
          <tr className="small-tr">
            <td colSpan={6}>
              <div className="pull-left mr-3 mt-2">
                <i className="fa fa-database mr-1" />
                <Badge> {t('Total')}: {total} {t('items')}</Badge>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className="no-border">
              <div className="pull-right">
                <Pagination
                  goToPage={goToPage}
                  count={total}
                  pageSize={perPage}
                  page={page}
                  lastPage={lastPage}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
    </CardBody>
  </Card>
);

SanctionedUsers.propTypes = {
  t: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default SanctionedUsers;
