import reducer from './reducers';
import * as translationsEpics from './epics';

export {
  translationsEpics,
};

export { default as translationActions } from './actions';

export default reducer;
