import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import './../../../../assets/css/email.css';

const MailNavigation = ({ t }) => (
  <nav>
    <Button block color="danger">
      <Link
        to="/mail/new"
        style={{ color: '#fff', padding: '10px 35px' }}
      >
        {t('New email')}
      </Link>
    </Button>
    <Nav vertical>
      <NavItem>
        <Link
          to="/mail/inbox"
          className="nav-link"
        >
          <i className="fa fa-inbox" />{t('Inbox')}
        </Link>
      </NavItem>
    </Nav>
  </nav>
);

MailNavigation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default MailNavigation;
