import apiRoutes from './api.routes';
import api from './api.service';
import ModelService from './model.service';

const UserService = new ModelService(apiRoutes.users, '/users', 'cpUsers');

UserService.defaultAvatar = `${api.baseUrl}/default-avatar.jpg`;

UserService.getAvatar = (filename, w = false, h = false, c = false) => {
  // if (!filename) return UserService.defaultAvatar;
  // return UserService.getFilePathByDir('/uploads/users', filename, w, h, c);
  return UserService.defaultAvatar;
};

UserService.STATUS_ACTIVE = 1;
UserService.STATUS_INACTIVE = 0;
UserService.STATUS_UNDER_REVIEW = 2;

UserService.getStatusList = t => ({
  [UserService.STATUS_ACTIVE]: t('Active'),
  [UserService.STATUS_INACTIVE]: t('Inactive'),
  [UserService.STATUS_UNDER_REVIEW]: t('Under review'),
});

UserService.getStatusOptions = t => UserService
  .prepareOptionsFromSimpleObject(UserService.getStatusList(t));

UserService.getStatusTitle = (status, t) => {
  const label = UserService.getStatusList(t)[status];
  return label || '-';
};

UserService.getColorByStatus = (status) => {
  switch (status) {
    case UserService.STATUS_ACTIVE:
      return 'success';
    case UserService.STATUS_INACTIVE:
      return 'danger';
    case UserService.STATUS_UNDER_REVIEW:
      return 'warning';
    default:
      return 'secondary';
  }
};

export default UserService;
