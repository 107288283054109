import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { usersNumberActions } from './index';

export const fetchUsersNumberEpic = action$ => action$.pipe(
  ofType(types.FETCH_USERS_NUMBER),
  mergeMap(action => ajax.get(`${apiUrl}/${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => usersNumberActions.fetchUsersNumberSuccess({ data: response.result })),
    catchError(error => of(usersNumberActions.fetchUsersNumberRejected(error))),
  )),
);
