import React from 'react';
import { compose } from 'recompose';
// import Loading from './Loading';
import CardLoadig from './CardLoading';

const FirstLoading = (WrappedComponent) => {
  const Component = props => (
    props.firstLoading ? <CardLoadig /> : <WrappedComponent {...props} />
  );

  const enhancer = compose();

  return enhancer(Component);
};

export default FirstLoading;
