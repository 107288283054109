import React from 'react';
import { compose } from 'recompose';
import Loading from './Loading';

const spinner = (WrappedComponent) => {
  const Component = props => (
    props.loading ? <Loading /> : <WrappedComponent {...props} />
  );

  const enhancer = compose();

  return enhancer(Component);
};

export default spinner;
