import { normalize, schema } from 'normalizr';
import { prop, map } from 'ramda';

const singleSchema = [new schema.Entity('single')];

// eslint-disable-next-line import/prefer-default-export
export const getNormalizedData = (data) => {
  const {
    entities: {
      single: entities,
    },
    result,
  } = normalize(data, singleSchema);

  return {
    entities,
    result,
  };
};

export const getValues = map(prop('value'));
