import React from 'react';
import PropTypes from 'prop-types';
import { AppSwitch } from '@coreui/react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button, Badge } from 'reactstrap';

import InputGroup from '../../../../../components/cms/form/inputGroup';
import statusActions from './statusActions';
import Addresses from '../addresses';
import HandleBadge from '../../../handleBadge';
import dateHelper from '../../../../../helpers/date.helper';

const twoCols = { xs: 12, md: 6 };

const ExternalUserData = ({
  t, errors, title, values, changeStatus,
  disabled, addresses, sanctionLabelStatus, sanctionLabelText, sanctionDate,
}) => (
  <Card>
    <CardHeader>
      <div className="float-left">
        <div className="d-flex flex-column">
          <strong>
            <i className="fa fa-id-card pr-2" />{`${title} - `}
            <HandleBadge t={t} status={values.managed} />
          </strong>
          <div>
            <span>{t('Sanction check')}:</span>
            <Badge
              color={sanctionLabelStatus === 1 ? 'success' : 'danger'}
              className="ml-1 mr-1"
            >
              {t(sanctionLabelText)}
            </Badge>
            <span>
              Last check: {dateHelper.getFormattedDate(sanctionDate, true, '-', 'd/m/Y')}
            </span>
          </div>
        </div>
      </div>
      <div className="card-header-actions">
        <Row className="align-items-center">
          <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
            <Button
              color="success"
              onClick={() => changeStatus(statusActions.WHITELIST)}
              disabled={disabled(statusActions.WHITELIST)}
            >
              {t('WL status')}
            </Button>
          </Col>

          <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
            <Button
              color="warning"
              onClick={() => changeStatus(statusActions.REVIEW)}
              disabled={disabled(statusActions.REVIEW)}
            >
              {t('Pending')}
            </Button>
          </Col>

          <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
            <Button
              color="danger"
              onClick={() => changeStatus(statusActions.BLACKLIST)}
              disabled={disabled(statusActions.BLACKLIST)}
            >
              {t('BL status')}
            </Button>
          </Col>
        </Row>
      </div>
    </CardHeader>
    <CardBody>
      <form>
        <Row>
          <Col md={12}>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="username"
                  label={t('Username')}
                  name="username"
                  placeholder={t('Username')}
                  errors={errors}
                  value={values.username}
                  icon="fa fa-user"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="email"
                  label={t('E-mail')}
                  name="email"
                  placeholder={t('E-mail')}
                  errors={errors}
                  value={values.email}
                  icon="fa fa-at"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="first_name"
                  label={t('First name')}
                  name="first_name"
                  placeholder={t('First name')}
                  errors={errors}
                  value={values.first_name}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="last_name"
                  label={t('Last name')}
                  name="last_name"
                  placeholder={t('Last name')}
                  errors={errors}
                  value={values.last_name}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="middle_name"
                  label={t('Middle name')}
                  name="middle_name"
                  placeholder={t('Middle name')}
                  errors={errors}
                  value={values.middle_name}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="phone_number"
                  label={t('Phone number')}
                  name="phone_number"
                  placeholder={t('Phone number')}
                  errors={errors}
                  value={values.phone_number}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="company_name"
                  label={t('Company name')}
                  name="company_name"
                  placeholder={t('Company name')}
                  errors={errors}
                  value={values.company_name}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="date_of_birth"
                  label={t('Date of birth')}
                  name="date_of_birth"
                  placeholder={t('Date of birth')}
                  errors={errors}
                  value={values.date_of_birth}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="date_of_kyc"
                  label={t('Date of kyc')}
                  name="date_of_kyc"
                  placeholder={t('Date of kyc')}
                  errors={errors}
                  value={values.date_of_kyc}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="date_of_registration"
                  label={t('Date of registration')}
                  name="date_of_registration"
                  placeholder={t('Date of registration')}
                  errors={errors}
                  value={values.date_of_registration}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="date_of_sanction"
                  label={t('Date of sanction')}
                  name="date_of_sanction"
                  placeholder={t('Date of sanction')}
                  errors={errors}
                  value={values.date_of_sanction}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  id="gender"
                  label={t('Gender')}
                  name="gender"
                  placeholder={t('Gender')}
                  errors={errors}
                  value={values.gender}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row className="my-0">
              <Col {...twoCols}>
                <InputGroup
                  id="nationality"
                  label={t('Nationality')}
                  name="nationality"
                  placeholder={t('Nationality')}
                  errors={errors}
                  value={values.nationality}
                  icon="fa fa-address-card-o"
                  disabled
                />
              </Col>
            </FormGroup>
          </Col>

          <Col md={12}>
            <Addresses
              data={addresses}
            />
          </Col>
        </Row>
      </form>
    </CardBody>
  </Card>
);

ExternalUserData.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  addresses: PropTypes.object.isRequired,
};

export default ExternalUserData;
