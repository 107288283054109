import { connect } from 'react-redux';
import { compose, hoistStatics, withHandlers, withProps } from 'recompose';
import { translate } from 'react-i18next';
import Settings from './Settings';
import FirstLoading from '../../../../../components/FirstLoading';
import { externalUserActions } from '../../../../../reducers/externalUser';
import { usersNumberActions } from '../../../../../reducers/usersCount';


const updateExternalUserAttribute = ({
  updateAttributeExternalUserAction,
}) => (attribute, value, url) => {
  updateAttributeExternalUserAction({ attribute, value }, { url });
};

const mapStateToProps = ({ externalUser }) => ({
  managed: externalUser.data.managed,
  netWorthOption: externalUser.data.net_worth_option,
  firstLoading: externalUser.firstLoading,
});

const mapDispatchToProps = {
  checkExternalUserAction: externalUserActions.checkExternalUser,
  updateAttributeExternalUserAction: externalUserActions.updateAttributeExternalUser,
  getUsersCount: usersNumberActions.fetchUsersNumber,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withHandlers({
    updateExternalUserAttribute,
  }),
  withProps(() => ({
    url: `${process.env.REACT_APP_API_URL}/contract/download`,
  })),
  FirstLoading,
);

export default hoistStatics(enhance)(Settings);
