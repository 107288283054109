export default {
  FETCH_MAILS: 'FETCH_MAILS',
  FETCH_MAILS_SUCCESS: 'FETCH_MAILS_SUCCESS',
  FETCH_MAILS_REJECTED: 'FETCH_MAILS_REJECTED',

  SEND_MAIL: 'SEND_MAIL',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_REJECTED: 'SEND_MAIL_REJECTED',

  FETCH_USER_EMAIL: 'FETCH_USER_EMAIL',
  FETCH_USER_EMAIL_SUCCESS: 'FETCH_USER_EMAIL_SUCCESS',
  FETCH_USER_EMAIL_REJECTED: 'FETCH_USER_EMAIL_REJECTED',
};
