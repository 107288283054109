import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';

const twoCols = { xs: 12, md: 6 };

const SecurityForm = ({
  t, errors, handleChange, isSubmitting, handleSubmit, values,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col {...twoCols}>
        <InputGroup
          name="allowedCountries"
          label={t('Allowed countries')}
          onChange={handleChange}
          errors={errors}
          value={values.allowedCountries}
          icon="fa fa-flag"
        />
        <p className="text-muted small">
          <a
            href="https://www.nationsonline.org/oneworld/country_code_list.htm"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('ISO Alpha 2 country code')}
          </a>
          <br />
          {t('Example')}: DE,gb, fr
        </p>
        <InputGroup
          name="loginAttempts"
          label={t('Login attempts')}
          onChange={handleChange}
          errors={errors}
          value={values.loginAttempts}
          icon="fa fa-user-circle"
        />
        <InputGroup
          name="loginAttemptsTime"
          label={t('Login attempts time (in minutes)')}
          onChange={handleChange}
          errors={errors}
          value={values.loginAttemptsTime}
          icon="fa fa-clock-o"
        />
      </Col>
      <Col {...twoCols}>
        <InputGroup
          name="allowedFileTypes"
          label={t('Allowed file types')}
          onChange={handleChange}
          errors={errors}
          value={values.allowedFileTypes}
          icon="fa fa-file"
        />
        <p className="text-muted small">{t('Example')}: jpg, jpeg,GIF, Docx</p>
        <InputGroup
          name="allowedImageSizes"
          type="textarea"
          label={t('Allowed image sizes')}
          rows={4}
          onChange={handleChange}
          errors={errors}
          value={values.allowedImageSizes}
          icon="fa fa-crop"
        />
        <p
          className="text-muted small"
        >
          {t('Example')}: 35_35,35_0,0_35,100_100, 150_0,0_150,150_150
        </p>
      </Col>
    </Row>
    <Button
      disabled={isSubmitting}
      type="submit"
      color="success"
      className="mr-2 mb-1 btn-brand"
    >
      <i className="fa fa-save mr-2" /> {t('Apply')}
    </Button>
  </form>
);

SecurityForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default SecurityForm;
