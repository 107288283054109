import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { translate } from 'react-i18next';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import TwoFaForm from './TwoFaForm';
import { authActions } from '../../reducers/auth';
import apiRoutes from '../../services/api.routes';

const rules = yup.object().shape({
  code: yup.string().required({
    message: 'Code cannot be empty',
    params: { key: 'Code' },
  }),
});

const mapStateToProps = state => ({
  username: state.auth.user.data.username,
});

const mapDispatchToProps = {
  logIn: authActions.logIn,
  clearUser: authActions.clearUser,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withFormik({
    mapPropsToValues: () => ({ code: '' }),
    validationSchema: rules,
    validateOnChange: false,
    handleSubmit: ({ code }, {
      props: { logIn, username },
      setSubmitting,
      resetForm,
    }) => {
      logIn({ username, code }, {
        url: apiRoutes.login,
        setSubmitting,
        resetForm,
      });
    },
  }),
);

export default withRouter(hoistStatics(enhance)(TwoFaForm));
