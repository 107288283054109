import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: [],
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 0,
  loading: true,
  firstLoading: false,
  error: false,
};

const reducer = handleActions(
  {
    [types.FETCH_ALL_EXTERNAL_USERS]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_ALL_EXTERNAL_USERS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      total: action.payload.total,
      perPage: action.payload.perPage,
      page: action.payload.page,
      lastPage: action.payload.lastPage,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_ALL_EXTERNAL_USERS_REJECTED]: (state, action) => ({
      ...state,
      loading: false,
      firstLoading: false,
      error: true,
    }),
  },
  initialState,
);

export default reducer;
