import * as yup from 'yup';

export default yup.object().shape({
  email: yup.string().required({
    message: 'Email cannot be empty',
    params: { key: 'Email' },
  }),
  domain: yup.string().required({
    message: 'Domain cannot be empty',
    params: { key: 'Domain' },
  }),
});
