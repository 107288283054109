import reducer from './reducers';
import * as languageEpics from './epics';

export {
  languageEpics,
};

export { default as languageActions } from './actions';

export default reducer;
