import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';

const twoCols = { xs: 12, md: 6 };

const TransactionForm = ({
  t, errors, handleChange, isSubmitting, handleSubmit, values,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col {...twoCols}>
        <InputGroup
          name="highValueTrxAmount"
          label={t('High value transaction amount')}
          onChange={handleChange}
          errors={errors}
          value={values.highValueTrxAmount}
          icon="fa fa-check"
        />
        <InputGroup
          name="highVolToSameRecipientDeviation"
          label={t('High volume to same recipient deviation')}
          onChange={handleChange}
          errors={errors}
          value={values.highVolToSameRecipientDeviation}
          icon="fa fa-check"
        />
        <InputGroup
          name="highVolToSameRecipientPeriod"
          label={t('High volume to same recipient period')}
          onChange={handleChange}
          errors={errors}
          value={values.highVolToSameRecipientPeriod}
          icon="fa fa-check"
        />
        <InputGroup
          name="increaseNumTrxDeviation"
          label={t('Increase number transaction deviation')}
          onChange={handleChange}
          errors={errors}
          value={values.increaseNumTrxDeviation}
          icon="fa fa-check"
        />
      </Col>
      <Col {...twoCols}>
        <InputGroup
          name="increaseNumTrxPeriod"
          label={t('Increase number transaction period')}
          onChange={handleChange}
          errors={errors}
          value={values.increaseNumTrxPeriod}
          icon="fa fa-check"
        />
        <InputGroup
          name="increaseNumTrxRedline"
          label={t('Increase number transaction redline')}
          onChange={handleChange}
          errors={errors}
          value={values.increaseNumTrxRedline}
          icon="fa fa-check"
        />
        <InputGroup
          name="totalVolInWeek"
          label={t('Total volume in week')}
          onChange={handleChange}
          errors={errors}
          value={values.totalVolInWeek}
          icon="fa fa-check"
        />
        <InputGroup
          name="uniqueRecipientsException"
          label={t('Unique recipients exception')}
          onChange={handleChange}
          errors={errors}
          value={values.uniqueRecipientsException}
          icon="fa fa-check"
        />
      </Col>
    </Row>
    <Button
      disabled={isSubmitting}
      type="submit"
      color="success"
      className="mr-2 mb-1 btn-brand"
    >
      <i className="fa fa-save mr-2" /> {t('Apply')}
    </Button>
  </form>
);

TransactionForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default TransactionForm;
