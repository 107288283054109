export default {
  FETCH_TRANSLATION: 'FETCH_TRANSLATION',
  FETCH_TRANSLATION_SUCCESS: 'FETCH_TRANSLATION_SUCCESS',
  FETCH_TRANSLATION_REJECTED: 'FETCH_TRANSLATION_REJECTED',

  CREATE_TRANSLATION: 'CREATE_TRANSLATION',
  CREATE_TRANSLATION_SUCCESS: 'CREATE_TRANSLATION_SUCCESS',
  CREATE_TRANSLATION_REJECTED: 'CREATE_TRANSLATION_REJECTED',

  UPDATE_TRANSLATION: 'UPDATE_TRANSLATION',
  UPDATE_TRANSLATION_SUCCESS: 'UPDATE_TRANSLATION_SUCCESS',
  UPDATE_TRANSLATION_REJECTED: 'UPDATE_TRANSLATION_REJECTED',
};
