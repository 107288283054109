import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: {},
  loading: true,
  firstLoading: false,
  error: false,
  errors: {},
};

const reducer = handleActions(
  {
    [types.FETCH_TRANSLATION]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
      errors: {},
    }),
    [types.FETCH_TRANSLATION_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_TRANSLATION_REJECTED]: (state, action) => ({
      ...state,
      data: {},
      loading: false,
      firstLoading: false,
      error: true,
      errors: action.payload,
    }),

    [types.CREATE_TRANSLATION]: state => ({
      ...state,
      data: {},
      loading: true,
      error: false,
    }),
    [types.CREATE_TRANSLATION_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    }),
    [types.CREATE_TRANSLATION_REJECTED]: state => ({
      ...state,
      data: {},
      loading: false,
      error: true,
    }),

    [types.UPDATE_TRANSLATION]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.UPDATE_TRANSLATION_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    }),
    [types.UPDATE_TRANSLATION_REJECTED]: (state, action) => ({
      ...state,
      loading: false,
      error: true,
      errors: action.payload,
    }),
  },
  initialState,
);

export default reducer;
