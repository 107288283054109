import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { sanctionedBySwitzerlandActions } from './index';


export const fetchSanctionedBySwitzerlandEpic = action$ => action$.pipe(
  ofType(types.FETCH_SANCTIONED_BY_SWITZERLAND),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => sanctionedBySwitzerlandActions.fetchSanctionedBySwitzerlandSuccess(response.result)),
    catchError(error => of(sanctionedBySwitzerlandActions.fetchSanctionedBySwitzerlandRejected(error))),
  )),
);

export const noMatchEpic = action$ => action$.pipe(
  ofType(types.NO_MATCH),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(() => sanctionedBySwitzerlandActions.noMatchSuccess(action.payload.id)),
    catchError((error) => {
      toast.error(error.status === 403 ?
        'Access forbidden. You are not allowed to this resource.' :
        error.response.error);
      return of(sanctionedBySwitzerlandActions.noMatchRejected(error));
    }),
  )),
);
