const badgeCount = (count, type) => (count !== 0 ? {
  variant: type,
  text: count,
} : null);

/**
 * The list of menu items
 * @param t
 */
const getNavs = (t, usersCount) => ({
  items: [
    {
      divider: true,
    },
    {
      title: true,
      name: '',
    },
    {
      name: t('Dashboard'),
      url: '/',
      icon: 'icon-speedometer',
    },
    {
      name: t('Under review'),
      url: '/pending-users/index',
      icon: 'icon-people',
      permissions: ['read_urUsers'],
      badge: badgeCount(usersCount.underReview, 'warning'),
    },
    {
      name: t('WL users'),
      url: '/white-list-users/index',
      icon: 'icon-people',
      permissions: ['read_wlUsers'],
      badge: badgeCount(usersCount.whitelisted, 'success'),
    },
    {
      name: t('BL users'),
      url: '/black-list-users/index',
      icon: 'icon-people',
      permissions: ['read_blUsers'],
      badge: badgeCount(usersCount.blacklisted, 'danger'),
    },
    {
      name: t('Other users'),
      url: '/new-users/index',
      icon: 'icon-people',
      permissions: ['read_newUsers'],
      badge: badgeCount(usersCount.newUsers, 'info'),
    },
    {
      name: t('Export users'),
      url: '/export-users/index',
      icon: 'icon-people',
      permissions: ['read_exportUsers'],
    },
    {
      name: t('Messages'),
      url: '/mail/inbox',
      icon: 'icon-envelope-letter',
      permissions: ['read_messages'],
    },
    {
      name: t('CD users'),
      url: '/users',
      icon: 'icon-user',
      permissions: ['read_cpUsers', 'read_roles'],
      children: [
        {
          name: t('CD users'),
          url: '/users/index',
          icon: 'icon-people',
          permissions: ['read_cpUsers'],
        },
        {
          name: t('Roles'),
          url: '/users/roles',
          icon: 'icon-lock',
          permissions: ['read_roles'],
        },
      ],
    },
    {
      name: t('Languages'),
      url: '/languages',
      icon: 'icon-flag',
      permissions: ['read_languages'],
    },
    {
      name: t('Translations'),
      url: '/translations',
      icon: 'icon-pencil',
      permissions: ['read_translations'],
    },
    {
      name: t('Settings'),
      url: '/settings',
      icon: 'icon-settings',
      permissions: ['read_settings'],
    },
    {
      divider: true,
    },
    {
      name: t('Logout'),
      url: '/logout',
      icon: 'icon-logout',
    },
  ],
});

/**
 * This function filter the array of menu items by permissions
 * @param items
 * @param permissions
 */
const filterItems = (items, permissions) => items.filter((root) => {
  if (!root.permissions) return true;
  if (root.permissions && root.permissions.length > 0) {
    for (const permission of root.permissions) {
      if (permissions.includes(permission)) return true;
    }
    return false;
  }
  return true;
});

/**
 * This function filter the menu items tree by permissions
 * @param t
 * @param permissions
 * @param usersCount
 */
export default (t, permissions, usersCount) => {
  const navs = getNavs(t, usersCount);
  if (permissions) {
    navs.items = filterItems(navs.items, permissions);
    for (let i = 0; i < navs.items.length; i += 1) {
      if (navs.items[i].children && navs.items[i].children.length > 0) {
        navs.items[i].children = filterItems(navs.items[i].children, permissions);
      }
    }
  }
  return navs;
};

