import { compose, withHandlers } from 'recompose';
import HandleBadge from './HandleBadge';

const checked = 1;
const notChecked = 0;

const getCheckBoxColor = ({ status }) => () => (status ? 'success' : 'danger');

const getCheckBoxLabelList = t => ({
  [checked]: t('Managed'),
  [notChecked]: t('Not managed'),
});

const getCheckBoxLabel = ({ t, status }) => () => {
  const list = getCheckBoxLabelList(t);
  return list[status];
};

const enhance = compose(withHandlers({
  getCheckBoxLabel,
  getCheckBoxColor,
}));

export default enhance(HandleBadge);
