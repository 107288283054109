import { handleActions } from 'redux-actions';
import types from './types';
import { mergeByPath } from '../../helpers/store.helper';

const initialState = {
  usersOverviews: {
    data: [],
    loading: true,
    firstLoading: false,
    error: false,
  },
};

const reducer = handleActions(
  {
    [types.FETCH_USERS_OVERVIEWS]: mergeByPath(['usersOverviews'], () => ({
      loading: true,
      error: false,
      errors: {},
    })),
    [types.FETCH_USERS_OVERVIEWS_SUCCESS]: mergeByPath(['usersOverviews'], ({ payload }) => ({
      ...payload,
      loading: false,
    })),
    [types.FETCH_USERS_OVERVIEWS_REJECTED]: mergeByPath(['usersOverviews'], action => ({
      loading: false,
      error: true,
      errors: action.payload,
    })),
  },
  initialState,
);

export default reducer;
