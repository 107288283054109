import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchTranslation, fetchTranslationSuccess, fetchTranslationRejected,
  createTranslation, createTranslationSuccess, createTranslationRejected,
  updateTranslation, updateTranslationSuccess, updateTranslationRejected,
} = createActions(
  {
    [types.FETCH_TRANSLATION]: [
      identity,
      nthArg(1),
    ],
    [types.CREATE_TRANSLATION]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_TRANSLATION]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_TRANSLATION_SUCCESS,
  types.FETCH_TRANSLATION_REJECTED,
  types.CREATE_TRANSLATION_SUCCESS,
  types.CREATE_TRANSLATION_REJECTED,
  types.UPDATE_TRANSLATION_SUCCESS,
  types.UPDATE_TRANSLATION_REJECTED,
);

export default {
  fetchTranslation,
  fetchTranslationSuccess,
  fetchTranslationRejected,
  createTranslation,
  createTranslationSuccess,
  createTranslationRejected,
  updateTranslation,
  updateTranslationSuccess,
  updateTranslationRejected,
};
