import Logout from './views/logout';
import Users from './views/users';
import UserView from './views/users/user/components/view';

import Languages from './views/languages';
import CreateLanguage from './views/languages/language/components/create';
import UpdateLanguage from './views/languages/language/components/update';

import Translations from './views/translates';
import CreateTranslation from './views/translates/translate/components/create';
import UpdateTranslation from './views/translates/translate/components/update';

import Settings from './views/settings';
import NewUsers from './views/externalUsers/newUsers';
import WhiteListUsers from './views/externalUsers/whiteListUsers';
import Mail from './views/mail';
import Conversation from './views/mail/conversation';
import NewEmail from './views/mail/newEmail';
import BlackListUsers from './views/externalUsers/blackListUsers';
import PendingUsers from './views/externalUsers/pending';
import ExternalUser from './views/externalUsers/user';
import ExternalUserView from './views/externalUsers/userView';
import ExportUsers from './views/exportUsers';
import Roles from './views/roles';
import Dashboard from './views/dashboard';
import CreateRole from './views/roles/role/components/create';
import UpdateRole from './views/roles/role/components/update';
import CreateUser from './views/users/user/components/create';
import UpdateUser from './views/users/user/components/update';
import Profile from './views/users/user/components/profile';

const getRoutes = t => ([
  {
    path: '/',
    exact: true,
    component: Dashboard,
    name: t('Dashboard'),
  },
  {
    path: '/translations',
    exact: true,
    component: Translations,
    name: t('Texts'),
    permissions: ['read_translations'],
  },
  {
    path: '/translations/create',
    exact: true,
    component: CreateTranslation,
    name: t('Create'),
    permissions: ['create_translations'],
  },
  {
    path: '/translations/:id',
    exact: true,
    component: UpdateTranslation,
    name: t('Update'),
    permissions: ['update_translations'],
  },
  {
    path: '/settings',
    exact: true,
    component: Settings,
    name: t('Settings'),
    permissions: ['read_settings'],
  },
  {
    path: '/languages',
    exact: true,
    component: Languages,
    name: t('Languages'),
    permissions: ['read_languages'],
  },
  {
    path: '/languages/create',
    exact: true,
    component: CreateLanguage,
    name: t('Create'),
    permissions: ['create_languages'],
  },
  {
    path: '/languages/:id',
    exact: true,
    component: UpdateLanguage,
    name: t('Update'),
    permissions: ['update_languages'],
  },
  {
    path: '/new-users/index',
    exact: true,
    component: NewUsers,
    name: t('New users'),
    // permissions: ['read_newUsers'],
  },
  {
    path: '/new-users/view/:id',
    exact: true,
    component: ExternalUserView,
    name: t('View'),
    permissions: ['read_newUsers'],
  },
  {
    path: '/new-users/:status/:id',
    exact: true,
    component: ExternalUser,
    name: t('Update'),
    permissions: ['update_newUsers'],
  },
  {
    path: '/white-list-users/index',
    exact: true,
    component: WhiteListUsers,
    name: t('White list users'),
    // permissions: ['read_wlUsers'],
  },
  {
    path: '/white-list-users/view/:id',
    exact: true,
    component: ExternalUserView,
    name: t('View'),
    permissions: ['read_wlUsers'],
  },
  {
    path: '/white-list-users/:status/:id',
    exact: true,
    component: ExternalUser,
    name: t('Update'),
    permissions: ['update_wlUsers'],
  },
  {
    path: '/black-list-users/index',
    exact: true,
    component: BlackListUsers,
    name: t('Black list users'),
  },
  {
    path: '/black-list-users/view/:id',
    exact: true,
    component: ExternalUserView,
    name: t('View'),
    permissions: ['read_blUsers'],
  },
  {
    path: '/black-list-users/:status/:id',
    exact: true,
    component: ExternalUser,
    name: t('Update'),
    permissions: ['update_blUsers'],
  },
  {
    path: '/pending-users/index',
    exact: true,
    component: PendingUsers,
    name: t('Pending users'),
    permissions: ['read_urUsers'],
  },
  {
    path: '/pending-users/view/:id',
    exact: true,
    component: ExternalUserView,
    name: t('View'),
    permissions: ['read_urUsers'],
  },
  {
    path: '/pending-users/:status/:id',
    exact: true,
    component: ExternalUser,
    name: t('Update'),
    permissions: ['update_urUsers'],
  },
  {
    path: '/export-users/index',
    exact: true,
    component: ExportUsers,
    name: t('Export users'),
    permissions: ['read_exportUsers'],
  },
  {
    path: '/mail/inbox',
    exact: true,
    component: Mail,
    name: t('Mail'),
    permissions: ['read_messages'],
  },
  {
    path: '/mail/inbox/:id',
    exact: true,
    component: Conversation,
    name: t('Conversation'),
    permissions: ['read_messages'],
  },
  {
    path: '/mail/new',
    exact: true,
    component: NewEmail,
    name: t('New mail'),
    permissions: ['create_messages'],
  },
  {
    path: '/users/roles',
    component: Roles,
    exact: true,
    name: t('Roles'),
  },
  {
    path: '/users/roles/create',
    component: CreateRole,
    name: t('Create'),
  },
  {
    path: '/users/roles/:id',
    component: UpdateRole,
    name: t('Update'),
  },
  {
    path: '/users/index',
    exact: true,
    component: Users,
    permissions: ['read_cpUsers'],
    name: t('CD users'),
  },
  {
    path: '/profile',
    exact: true,
    name: t('Admin profile'),
    component: Profile,
    // permissions: ['create_cpUsers'],
  },
  {
    path: '/users/create',
    exact: true,
    component: CreateUser,
    name: t('Create'),
    permissions: ['create_cpUsers'],
  },
  {
    path: '/users/:id',
    exact: true,
    component: UpdateUser,
    name: t('Update'),
    permissions: ['update_cpUsers'],
  },
  {
    path: '/users/view/:id',
    exact: true,
    component: UserView,
    name: t('View'),
    permissions: ['read_cpUsers'],
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
  },
]);

export default getRoutes;
