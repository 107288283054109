import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';

const GeneralForm = ({
  t, errors, handleChange, handleSubmit, isSubmitting, values, hasPermission,
}) => (
  <form onSubmit={handleSubmit}>
    <fieldset disabled={!hasPermission}>
      <Row>
        <Col>
          <InputGroup
            name="email"
            label={t('Admin e-mail')}
            onChange={handleChange}
            errors={errors}
            value={values.email}
            icon="fa fa-at"
          />
          <InputGroup
            name="domain"
            label={t('Domain')}
            onChange={handleChange}
            errors={errors}
            value={values.domain}
            icon="fa fa-anchor"
          />
        </Col>
      </Row>
    </fieldset>
    <Button
      disabled={isSubmitting}
      type="submit"
      color="success"
      className="mr-2 mb-1 btn-brand"
    >
      <i className="fa fa-save mr-2" /> {t('Apply')}
    </Button>
  </form>
);

GeneralForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default GeneralForm;
