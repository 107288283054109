import React from 'react';
import PropTypes from 'prop-types';


const Contract = ({
  data, getContractData, toggleModal, toggle,
}) => (
  <div
    key={data.id}
    className="mb-3 p-2"
  >
    <i className="fa fa-file-pdf-o mr-1" />
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        toggleModal(!toggle);
        getContractData(data);
      }}
    >
      {data.filename}
    </a>
  </div>
);

Contract.propTypes = {
  data: PropTypes.object.isRequired,
  getContractData: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
};

export default Contract;
