import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback } from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import { Creatable } from 'react-select';
import MailNavigation from '../components/navigation/MailNavigation';
import InputGroup from '../../../components/cms/form/inputGroup';
import './../../../assets/css/email.css';

const NewEmail = ({
  t, isSubmitting, errors, handleSubmit, handleChange, handleSubjectSelect,
  values, loadOptions, handleInputChange, users, options,
}) => (
  <div className="email-app mb-4">
    <MailNavigation t={t} />

    <main>
      <p className="text-center">{t('New Message')}</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 position-relative row form-group">
          <div className="col-10 col-sm-12">
            <FormGroup>
              <AsyncSelect
                id="to"
                name="to"
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={users}
                placeholder={t('Type username')}
                onChange={handleInputChange}
              />
              {!!errors.to && (
                <FormFeedback className="help-block">{errors.to.message}</FormFeedback>
              )}
            </FormGroup>
          </div>
        </div>

        <div className="mb-3 position-relative row form-group">
          <div className="col-10 col-sm-12">
            <Creatable
              className="basic-single"
              classNamePrefix="select"
              name="subject"
              onChange={handleSubjectSelect}
              options={options}
              errors={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="mt-4 position-relative form-group">
              <InputGroup
                id="text"
                name="text"
                type="editor"
                label=""
                errors={errors}
                onChange={handleChange}
                value={values.text}
                icon="fa fa-flag-o"
              />
            </div>
            <div className="position-relative form-group">
              <button
                type="submit"
                className="mr-1 btn btn-success"
                disabled={isSubmitting}
              >{isSubmitting ? t('Sending...') : t('Send message')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </main>
  </div>
);

NewEmail.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubjectSelect: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewEmail;
