import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Transactions from '../../transactions';
import BalanceOverview from './components/balanceOverview';
import ExternalUserData from './components/externalUserData';
import Conversations from './components/conversations';
import apiRoutes from './../../../services/api.routes';
import Documents from './components/documents';
import SanctionedBySwitzerland from '../../sanctionedBySwitzerland';
import Contracts from './components/contracts';
import Settings from './components/settings';


const ExternalUser = ({
  t, match, history,
}) => (
  <div>
    <Row>
      <Col md={9}>
        <ExternalUserData
          matchParams={match.params}
          history={history}
        />

        <BalanceOverview
          matchParams={match.params}
        />

        <Transactions
          matchParams={match.params}
          title={t('Transactions')}
          url={apiRoutes.getTransactionsByUid}
          showLink={false}
        />

        <SanctionedBySwitzerland
          matchParams={match.params}
        />

        <Conversations
          matchParams={match.params}
        />
      </Col>
      <Col md={3}>
        {/* <Card> */}
        {/* <CardHeader> */}
        {/* <strong><i className="fa fa-id-card pr-2" />{t('Export')}</strong> */}
        {/* </CardHeader> */}
        {/* <CardBody> */}
        {/* <p>{t('Export')}</p> */}
        {/* </CardBody> */}
        {/* </Card> */}

        <Settings
          matchParams={match.params}
        />

        <Contracts />

        <Documents
          matchParams={match.params}
        />
      </Col>
    </Row>
  </div>
);

ExternalUser.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ExternalUser;
