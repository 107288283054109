import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  token: '',
  user: {
    data: {},
    loading: false,
    valid: false,
    error: false,
    errors: {},
  },
  code: {
    loading: false,
    valid: false,
    error: false,
    errors: {},
  },
};

const reducer = handleActions(
  {
    [types.FETCH_USER]: state => ({
      ...state,
      user: {
        loading: true,
        valid: false,
        errors: false,
      },
      token: '',
    }),
    [types.FETCH_USER_SUCCESS]: (state, action) => ({
      ...state,
      token: action.payload.token,
      user: {
        data: action.payload.user,
        loading: false,
        valid: true,
        errors: false,
      },
    }),
    [types.FETCH_USER_REJECTED]: (state, action) => ({
      ...state,
      user: {
        loading: false,
        valid: false,
        error: true,
        errors: action.payload.response,
      },
      token: '',
    }),


    [types.LOG_IN]: state => ({
      ...state,
      code: {
        loading: true,
        valid: false,
        errors: false,
      },
    }),
    [types.LOG_IN_SUCCESS]: (state, action) => ({
      ...state,
      code: {
        loading: false,
        valid: true,
        errors: false,
      },
      // token: action.payload.token,
    }),
    [types.LOG_IN_REJECTED]: state => ({
      ...state,
      code: {
        loading: false,
        valid: false,
        errors: true,
      },
    }),


    [types.LOG_OUT]: state => ({
      ...state,
    }),
    [types.LOG_OUT_SUCCESS]: state => ({
      ...state,
      ...initialState,
    }),
    [types.LOG_OUT_REJECTED]: state => ({
      ...state,
    }),


    [types.CLEAR_USER]: state => ({
      ...state,
      user: {
        data: {},
        loading: false,
        valid: false,
        error: false,
        errors: {},
      },
    }),
  },
  initialState,
);

export default reducer;
