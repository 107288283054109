import { compose, hoistStatics, withState, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import SelectLimit from './SelectLimit';

const toggle = ({ setOpen, open }) => () => setOpen(!open);

const enhance = compose(
  withState('open', 'setOpen', false),
  withState('limits', 'setLimits', [10, 25, 50, 100, 150]),
  translate('core'),
  withHandlers({
    toggle,
  }),
);

export default hoistStatics(enhance)(SelectLimit);
