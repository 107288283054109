import { compose, getContext, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmailTemplatesListItem from './EmailTemplatesListItem';
import { emailTemplatesSelectors } from '../../../../../../reducers/emailTemplates';

const mapStateToProps = (state, { id }) => ({
  item: emailTemplatesSelectors.getEmailTemplate(state)(id),
});

const enhance = compose(
  connect(mapStateToProps),
  getContext({
    setTemplateId: PropTypes.func,
  }),
  withHandlers({
    onClick: ({ setTemplateId, id }) => () => setTemplateId(id),
  }),
);

export default enhance(EmailTemplatesListItem);
