import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

export const {
  fetchEmailTemplates,
  fetchEmailTemplatesSuccess,
  fetchEmailTemplatesRejected,

  createEmailTemplate,
  createEmailTemplateSuccess,
  createEmailTemplateRejected,

  updateEmailTemplate,
  updateEmailTemplateSuccess,
  updateEmailTemplateRejected,

  deleteEmailTemplate,
  deleteEmailTemplateSuccess,
  deleteEmailTemplateRejected,
} = createActions(
  {
    [types.UPDATE_EMAIL_TEMPLATE]: [
      identity,
      nthArg(1),
    ],
    [types.DELETE_EMAIL_TEMPLATE]: [
      identity,
      nthArg(1),
    ],
  },

  types.FETCH_EMAIL_TEMPLATES,
  types.FETCH_EMAIL_TEMPLATES_SUCCESS,
  types.FETCH_EMAIL_TEMPLATES_REJECTED,

  types.CREATE_EMAIL_TEMPLATE,
  types.CREATE_EMAIL_TEMPLATE_SUCCESS,
  types.CREATE_EMAIL_TEMPLATE_REJECTED,

  types.UPDATE_EMAIL_TEMPLATE_SUCCESS,
  types.UPDATE_EMAIL_TEMPLATE_REJECTED,

  types.DELETE_EMAIL_TEMPLATE_SUCCESS,
  types.DELETE_EMAIL_TEMPLATE_REJECTED,
);
