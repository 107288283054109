import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translationActions } from '../../../reducers/translations';

// const defaultFormData = {
//   key: '',
//   text: '',
//   is_html: ModelService.IS_HTML_NO,
//   namespace: ModelService.NAMESPACE_CORE,
//   ml: {},
// };
// const applyModel = fh.createApplyModel(defaultFormData);
//
// const onSubmit = props => redirect => async (event) => {
//   const { isNewModel, history, formData, t } = props;
//   await fh.beforeSave(event, props, redirect);
//   const { model, errors } = await ModelService.update({
//     ...formData,
//   });
//   if (!errors) {
//     if (redirect) return history.push(ModelService.baseRoute);
//     if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
//     await applyModel(props.setFormData, model);
//     fh.notify(t);
//   }
//   props.setErrors(!errors ? {} : errors);
//   return props.setIsSubmitting(false);
// };

const wrapperComponent = (WrappedComponent) => {
  const Component = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    // title: state.transaction.data.title,
    // language: state.transaction.data,
    // firstLoading: state.transaction.firstLoading,
  });

  const mapDispatchToProps = {
    fetchTranslation: translationActions.fetchTranslation,
    createLanguage: translationActions.createLanguage,
    updateLanguage: translationActions.updateLanguage,
  };

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    translate('core'),
  );

  return enhance(Component);
};

export default wrapperComponent;
