import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { sanctionedUsersActions } from './index';

export const fetchSanctionedUsersEpic = action$ => action$.pipe(
  ofType(types.FETCH_SANCTIONED_USERS),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => sanctionedUsersActions.fetchSanctionedUsersSuccess(response)),
    catchError((error) => {
      if (error.status === 403) {
        return of(sanctionedUsersActions.fetchSanctionedUsersRejected('Access forbidden. You are not allowed to this resource.'));
      }
      return of(sanctionedUsersActions.fetchSanctionedUsersRejected(error));
    }),
  )),
);

export const fetchSanctionChecks = action$ => action$.pipe(
  ofType(types.FETCH_SANCTION_CHECKS),
  mergeMap(() => ajax.get(`${apiUrl}/sanctionCheckIndicators`).pipe(
    map(({ response }) => sanctionedUsersActions.fetchSanctionChecksSuccess(response.result)),
    catchError((error) => {
      if (error.status === 403) {
        return of(sanctionedUsersActions.fetchSanctionChecksRejected('Access forbidden. You are not allowed to this resource.'));
      }
      return of(sanctionedUsersActions.fetchSanctionChecksRejected(error));
    }),
  )),
);
