import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { mapObjIndexed, values as Rvalues } from 'ramda';

import FILE_TYPES from '../../../constants/FileTypes';
import stringHelpers from '../../../helpers/string.helper';

const url = process.env.REACT_APP_API_URL;

const DocumentModal = ({
  t, isOpen, toggle, isAddToDocuments, setIsAddToDocuments, docTypes, wasSubmitted,
  handleChange,
  handleSubmit,
  setFieldValue,
  values,
  errors, image, token,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    className="modal-lg"
  >
    <ModalHeader>{t('Add attachment to user documents')}</ModalHeader>
    <ModalBody>
      <img
        alt="Email attachment"
        src={`${url}/downloadAttachmentById/${image}?token=${token}`}
        width="100%"
      />
    </ModalBody>
    <Form hidden={!isAddToDocuments} className="w-75 m-auto" onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="type">
          {t('Type')}
        </Label>
        <Input
          type="select"
          onChange={handleChange}
          name="type"
          id="type"
          value={values.type}
          invalid={!!errors.type}
        >
          <option value={FILE_TYPES.PROOF_OF_ADDRESS}> {t('Proof of address')} </option>
          <option value={FILE_TYPES.ID_CARD_1}> {t('ID card 1')} </option>
          <option value={FILE_TYPES.ID_CARD_2}> {t('ID card 2')} </option>
          <option value={FILE_TYPES.SELFIE}> {t('Selfie')} </option>
          <option value={FILE_TYPES.PASSPORT_ID}> {t('Passport ID')} </option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="docType">
          {t('Type of document')}
        </Label>
        <Input
          type="select"
          onChange={handleChange}
          name="docType"
          id="docType"
          value={values.docType}
          invalid={!!errors.docType}
        >
          {Rvalues(mapObjIndexed((value, key) => (
            <option key={key} value={value}>{t(stringHelpers.toCamelCaseWithSpaces(key))}</option>
          ))(docTypes))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="dateOfExpiration">
          {t('Expiration date')}
        </Label>
        <Input
          type="date"
          onChange={({ currentTarget: { value } }) => setFieldValue('dateOfExpiration', value)}
          name="dateOfExpiration"
          id="dateOfExpiration"
          value={values.dateOfExpiration}
          invalid={!!errors.dateOfExpiration}
        />
      </FormGroup>
      <FormGroup className="d-flex justify-content-center">
        <Button type="submit" color="success">{t('Add')}</Button>
      </FormGroup>
    </Form>

    <ModalFooter>
      <Button
        color="primary"
        onClick={() => setIsAddToDocuments(true)}
        hidden={wasSubmitted}
      >
        {t('Add to user documents')}
      </Button>
      <Button
        color="secondary"
        onClick={toggle}
      >
        {t('Close')}
      </Button>
    </ModalFooter>
  </Modal>
);

DocumentModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isAddToDocuments: PropTypes.bool.isRequired,
  setIsAddToDocuments: PropTypes.func.isRequired,
  docTypes: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  token: PropTypes.string.isRequired,
};

DocumentModal.defaultProps = {
  image: '',
};

export default DocumentModal;
