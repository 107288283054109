import reducer from './reducers';
import * as authSelectors from './selectors';
import * as authEpics from './epics';

export { default as authTypes } from './types';
export { default as authActions } from './actions';
export {
  authSelectors,
  authEpics,
};

export default reducer;
