import { connect } from 'react-redux';
import { pick } from 'ramda';
import { compose, hoistStatics, withContext, withProps, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Contracts from './Contracts';
import FirstLoading from '../../../../../components/FirstLoading';


const mapStateToProps = ({ auth, externalUser }) => ({
  token: auth.token,
  contracts: externalUser.data.contracts,
  firstLoading: externalUser.firstLoading,
});

const enhance = compose(
  connect(mapStateToProps, null),
  translate('core'),
  withStateHandlers(() => ({
    toggle: false,
    data: {},
  }), {
    toggleModal: () => toggle => ({ toggle }),
    getContractData: () => data => ({ data }),
  }),
  withProps(() => ({
    url: `${process.env.REACT_APP_API_URL}/contract/download`,
  })),
  withContext({
    toggleModal: PropTypes.func,
    getContractData: PropTypes.func,
    toggle: PropTypes.bool,
  }, pick(['toggleModal', 'getContractData', 'toggle'])),
  FirstLoading,
);

export default hoistStatics(enhance)(Contracts);
