import { handleActions } from 'redux-actions';
import { propOr } from 'ramda';

import { mergeByProp } from '../../helpers/store.helper';
import types from './types';

const initialState = {
  modals: {},
  popovers: {},
};

const reducer = handleActions(
  {
    [types.SET_MODAL]: mergeByProp(
      'modals',
      ({ payload: { name, status } }) => ({
      [name]: !!status,
    }),
),
    [types.TOGGLE_MODAL]: mergeByProp(
      'modals',
      ({ payload: name }, { modals }) => ({
      [name]: !propOr(false, name, modals),
    }),
),

    [types.SET_POPOVER]: mergeByProp(
      'popovers',
      ({ payload: { name, status } }) => ({
      [name]: !!status,
    }),
),
    [types.TOGGLE_POPOVER]: mergeByProp(
      'popovers',
      ({ payload: name }, { popovers }) => ({
      [name]: !propOr(false, name, popovers),
    }),
),
  },
  initialState,
);

export default reducer;
