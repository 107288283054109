import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: [],
  loading: true,
  firstLoading: false,
  error: false,
};

const reducer = handleActions(
  {
    [types.FETCH_SANCTIONED_BY_SWITZERLAND]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_SANCTIONED_BY_SWITZERLAND_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_SANCTIONED_BY_SWITZERLAND_REJECTED]: state => ({
      ...state,
      data: [],
      loading: false,
      firstLoading: false,
      error: true,
    }),


    [types.NO_MATCH]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.NO_MATCH_SUCCESS]: (state, action) => ({
      ...state,
      data: [...state.data.filter(({ id }) => id !== action.payload)],
      loading: false,
      error: false,
    }),
    [types.NO_MATCH_REJECTED]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

export default reducer;
