import { compose, getContext, hoistStatics, withHandlers, withStateHandlers } from 'recompose';
import PropTypes from 'prop-types';
import Item from './Item';
import statusList from './../statusList';

const toggle = props => (name, value) => {
  const { setPopoverOpen } = props;
  setPopoverOpen(name, value);
};

const status = ({ value }) => () => {
  switch (value.status) {
    case statusList.NEW:
      return {
        title: 'New',
        color: 'primary',
      };
    case statusList.APPROVE:
      return {
        title: 'Approve',
        color: 'success',
      };
    case statusList.DECLINE:
      return {
        title: 'Decline',
        color: 'danger',
      };
    default:
      return {
        title: 'Old',
        color: 'warning',
      };
  }
};

const enhance = compose(
  getContext({
    changeStatusAddress: PropTypes.func,
  }),
  withStateHandlers(() => ({
    popoverStatus: false,
  }), {
    setPopoverOpen: state => (name, value) => ({
      ...state, [name]: !value,
    }),
  }),
  withHandlers({
    toggle,
    status,
  }),
);

export default hoistStatics(enhance)(Item);
