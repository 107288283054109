import { mergeMap, map, catchError, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import types from './types';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import { authActions } from './index';
import { permissionsActions } from '../permissions/index';

export const fetchUserEpic = action$ => action$.pipe(
  ofType(types.FETCH_USER),
  delay(500),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      action.meta.resetForm();

      return authActions.fetchUserSuccess(response);
    }),
    catchError((error) => {
      action.meta.setSubmitting(false);
      action.meta.resetForm();
      toast.error(error.response.password);

      return of(authActions.fetchUserRejected(error));
    }),
  )),
);

export const logInEpic = action$ => action$.pipe(
  ofType(types.LOG_IN),
  delay(500),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    mergeMap(({ response }) => of(
      authActions.logInSuccess(response),
      permissionsActions.setPermissions(response.permissions),
    )),
    catchError((error) => {
      toast.error(error.response.code);
      return of(authActions.logInRejected(error));
    }),
  )),
);

export const logOutEpic = action$ => action$.pipe(
  ofType(types.LOG_OUT),
  delay(1500),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    mergeMap(() => of(
      permissionsActions.clearPermissions(),
      authActions.logOutSuccess(),
    )),
    catchError(error => of(authActions.logOutRejected(error))),
  )),
);
