import React from 'react';
import { Badge } from 'reactstrap';

const checkBoxDataCell = ({ ModelService, t, attr }) => ({ item: { [attr]: status } }) => (
  <Badge color={ModelService.getCheckBoxColor(status)}>
    {ModelService.getCheckBoxLabel(status, t)}
  </Badge>
);

export default checkBoxDataCell;
