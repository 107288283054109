import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/cms/grid';
import ModelService from '../../services/language.service';

const initCriteria = {
  id: '',
  name: '',
  is_default: '',
  status: '',
  admin_status: '',
  title: '',
  priority: '',
  sortBy: 'priority',
  order: 'asc',
};

const Languages = ({ t, getColumns }) => (
  <Grid
    id="languages"
    title={t('Languages')}
    service={ModelService}
    columns={getColumns(t)}
    initCriteria={initCriteria}
    saveFilter={false}
    showViewButton={false}
    showAddButton
  />
);

Languages.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Languages;
