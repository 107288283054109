import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import api from './services/api.service';

const backendConfig = {
  // for all available options read the backend's repository readme file
  loadPath: `${api.baseUrl}/locales/{{lng}}/{{ns}}.json`,
};

i18n.use(LanguageDetector).use(Backend).init({
  detection: {
    order: ['localStorage', 'path', 'querystring', 'cookie', 'navigator', 'htmlTag', 'subdomain'],
    lookupFromPathIndex: 0,
  },
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['core'],
  defaultNS: 'core',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  backend: backendConfig,
  react: {
    wait: true,
  },
});

export default i18n;
