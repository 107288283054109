import React from 'react';
import { HOC as Permissions } from 'react-redux-permissions';
import { compose, withProps } from 'recompose';

let fallback = {};
// TODO: Bad solution, but permission library doesn't pass props to fallback component

const HasPermissions = permissions => Component =>
  compose(withProps((props) => {
      fallback = props;
      return {};
    }))(Permissions(permissions)(
      props => (<Component {...props} hasPermission />),
      () => (<Component {...fallback} hasPermission={false} />),
    ));

export default HasPermissions;
