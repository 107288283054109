import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchSettings, fetchSettingsSuccess, fetchSettingsRejected,
  updateSettings, updateSettingsSuccess, updateSettingsRejected,
  uploadXlsxFile, uploadXlsxFileSuccess, uploadXlsxFileRejected,
  fetchLanguages, fetchLanguagesSuccess, fetchLanguagesRejected,
} = createActions(
  {
    [types.FETCH_SETTINGS]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_SETTINGS]: [
      identity,
      nthArg(1),
    ],
    [types.UPLOAD_XLSX_FILE]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_LANGUAGES]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_SETTINGS_SUCCESS,
  types.FETCH_SETTINGS_REJECTED,

  types.UPDATE_SETTINGS_SUCCESS,
  types.UPDATE_SETTINGS_REJECTED,

  types.UPLOAD_XLSX_FILE_SUCCESS,
  types.UPLOAD_XLSX_FILE_REJECTED,

  types.FETCH_LANGUAGES_SUCCESS,
  types.FETCH_LANGUAGES_REJECTED,

);

export default {
  fetchSettings,
  fetchSettingsSuccess,
  fetchSettingsRejected,

  updateSettings,
  updateSettingsSuccess,
  updateSettingsRejected,

  uploadXlsxFile,
  uploadXlsxFileSuccess,
  uploadXlsxFileRejected,

  fetchLanguages,
  fetchLanguagesSuccess,
  fetchLanguagesRejected,
};
