import { compose, hoistStatics, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Mail from './Mail';
import { conversationsActions } from '../../reducers/conversations';
import apiRoutes from '../../services/api.routes';
import FirstLoading from '../../components/FirstLoading';

const criteria = {
  page: 1,
  limit: 10,
};

const meta = {
  url: apiRoutes.getConversationsList,
  firstLoading: true,
};

const goToPage = props => (page) => {
  const { fetchData } = props;

  fetchData({ ...criteria, page }, { url: apiRoutes.getConversationsList });
};

const mapStateToProps = ({ conversations }) => ({
  data: conversations.data,
  loading: conversations.loading,
  firstLoading: conversations.firstLoading,
  count: conversations.total,
  lastPage: conversations.lastPage,
  page: conversations.page,
  limit: conversations.perPage,
});

const mapDispatchToProps = {
  fetchData: conversationsActions.fetchConversations,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchData } = this.props;

      fetchData(criteria, meta);
    },
  }),
  withHandlers({
    goToPage,
  }),
  FirstLoading,
);

export default hoistStatics(enhance)(Mail);
