import React from 'react';
import PropTypes from 'prop-types';

const ColumnTitle = ({ column, value }) => (
  <td>{value}</td>
);

ColumnTitle.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default ColumnTitle;
