import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik/dist/index';
import { toast } from 'react-toastify';
import apiRoutes from '../../../../services/api.routes';
import TransactionForm from './TransactionForm';
import rules from './rules';

const enhance = compose(withFormik({
  mapPropsToValues: ({ data }) => ({
    highValueTrxAmount: data.highValueTrxAmount,
    highVolToSameRecipientDeviation: data.highVolToSameRecipientDeviation,
    highVolToSameRecipientPeriod: data.highVolToSameRecipientPeriod,
    increaseNumTrxDeviation: data.increaseNumTrxDeviation,
    increaseNumTrxPeriod: data.increaseNumTrxPeriod,
    increaseNumTrxRedline: data.increaseNumTrxRedline,
    totalVolInWeek: data.totalVolInWeek,
    uniqueRecipientsException: data.uniqueRecipientsException,
  }),
  validationSchema: rules,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: (values, {
    props: { updateSettings },
    setSubmitting,
  }) => {
    updateSettings(values, {
      url: `${apiRoutes.settings}/transaction`,
      setSubmitting,
      toast,
    });
  },
}));

export default hoistStatics(enhance)(TransactionForm);
