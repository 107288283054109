import { compose, hoistStatics, withProps } from 'recompose';
import { translate } from 'react-i18next';
import DataCell from './DataCell';
import columnTypes from '../columnTypes';
import gh from '../grid.helper';


const buttonsClass = ' buttons-cell';
const enhance = compose(
  withProps(({
    column,
    item,
    tdClassName,
    service,
    destroyItem,
    updateData,
    showViewButton,
  }) => ({
    tdClassName: column.type === columnTypes.BUTTONS ? tdClassName + buttonsClass : tdClassName,
    value: gh.getCellValue(column, item, service, destroyItem, updateData, showViewButton),
  })),
  translate('core'),
);

export default hoistStatics(enhance)(DataCell);
