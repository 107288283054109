import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { authActions } from '../../reducers/auth';
import Logout from './Logout';
import apiRoutes from '../../services/api.routes';

const mapDispatchToProps = {
  logOut: authActions.logOut,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { logOut } = this.props;

      logOut(null, { url: apiRoutes.logout });
    },
  }),
);

export default hoistStatics(enhance)(Logout);
