import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


const ContractModal = ({
  t, toggle, toggleModal, data, token, url,
}) => (
  <Modal
    isOpen={toggle}
    toggle={() => toggleModal(!toggle)}
    className="modal-lg"
  >
    <ModalHeader >{data.filename}</ModalHeader>
    <ModalBody>
      <embed
        src={`${url}/${data.id}?token=${token}`}
        type="application/pdf"
        style={{ width: '100%', height: 700 }}
        frameBorder="0"
      />
    </ModalBody>

    <div className="modal-info">
      <p>{`IP: ${data.ip_address}`}</p>
      <p>{`IP Location: ${data.ip_location}`}</p>
      <p>{`Upload date: ${moment(data.created_at).format('L')}`}</p>
      <p>{`Filename: ${data.filename}`}</p>
    </div>
    <ModalFooter>
      <a
        href={`${url}/${data.id}?token=${token}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Open contract in new tab')}
      </a>
      <Button
        color="secondary"
        onClick={() => toggleModal(!toggle)}
      >
        {t('Close')}
      </Button>
    </ModalFooter>
  </Modal>
);

ContractModal.propTypes = {
  t: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ContractModal;
