export default {
  FETCH_SETTINGS: 'FETCH_SETTINGS',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_REJECTED: 'FETCH_SETTINGS_REJECTED',

  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_REJECTED: 'UPDATE_SETTINGS_REJECTED',

  UPLOAD_XLSX_FILE: 'UPLOAD_XLSX_FILE',
  UPLOAD_XLSX_FILE_SUCCESS: 'UPLOAD_XLSX_FILE_SUCCESS',
  UPLOAD_XLSX_FILE_REJECTED: 'UPLOAD_XLSX_FILE_REJECTED',

  FETCH_LANGUAGES: 'FETCH_LANGUAGES',
  FETCH_LANGUAGES_SUCCESS: 'FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_REJECTED: 'FETCH_LANGUAGES_REJECTED',
};
