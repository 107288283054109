import React from 'react';
import { ListGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import EmailTemplatesListItem from '../emailTemplatesListItem';

const EmailTemplatesList = ({ emailTemplatesList }) => (
  <ListGroup className="email-templates_list">
    {emailTemplatesList.map(id => <EmailTemplatesListItem key={id} id={id} />)}
  </ListGroup>
);

EmailTemplatesList.propTypes = {
  emailTemplatesList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default EmailTemplatesList;

