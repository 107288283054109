import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { unapply, identity, values, map, flatten, apply, pipe } from 'ramda';

import user, { userEpics } from './user';
import auth, { authEpics } from './auth';
import upload, { uploadEpics } from './upload';
import grid, { gridEpics } from './grid';
import settings, { settingsEpics } from './settings';
import permissions from './permissions';
import conversations, { conversationsEpics } from './conversations';
import externalUser, { externalUserEpics } from './externalUser';
import externalUsers, { externalUsersEpics } from './externalUsers';
import sanctionedUsers, { sanctionedUsersEpics } from './sanctionedUsers';
import transactions, { transactionsEpics } from './transactions';
import language, { languageEpics } from './language';
import mails, { mailsEpics } from './mails';
import role, { roleEpics } from './role';
import charts, { chartsEpics } from './charts';
import exportUsers, { exportUsersEpics } from './exportUsers';
import sanctionedBySwitzerland, { sanctionedBySwitzerlandEpics } from './sanctionedBySwitzerland';
import translation, { translationsEpics } from './translations';
import usersCount, { usersCountEpics } from './usersCount';
import ui from './ui';
import emailTemplates, { emailTemplatesEpics } from './emailTemplates';

export const createReducer = combineReducers({
  user,
  auth,
  upload,
  grid,
  settings,
  permissions,
  conversations,
  externalUser,
  externalUsers,
  sanctionedUsers,
  transactions,
  mails,
  role,
  language,
  charts,
  exportUsers,
  sanctionedBySwitzerland,
  translation,
  usersCount,
  ui,
  emailTemplates,
});

export const rootEpic = pipe(
  unapply(identity),
  map(values),
  flatten,
  apply(combineEpics),
)(
  userEpics,
  authEpics,
  uploadEpics,
  gridEpics,
  settingsEpics,
  conversationsEpics,
  externalUserEpics,
  externalUsersEpics,
  sanctionedUsersEpics,
  transactionsEpics,
  languageEpics,
  mailsEpics,
  roleEpics,
  chartsEpics,
  exportUsersEpics,
  sanctionedBySwitzerlandEpics,
  translationsEpics,
  usersCountEpics,
  emailTemplatesEpics,
);
