import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ValidationMessage from '../../components/cms/form/validation/ValidationMessage';

const Login = ({
  t, errors, values, handleSubmit, handleChange, isSubmitting,
}) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <p className="text-muted">{t('Sign In to your account')}</p>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="username"
                      value={values.username}
                      placeholder={t('Username')}
                      onChange={handleChange}
                      invalid={!!errors.username}
                    />
                    <ValidationMessage
                      message={t('Username cannot be empty')}
                      valid={false}
                      wasValidated={!!errors.username}
                    />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder={t('Password')}
                      onChange={handleChange}
                      invalid={!!errors.password}
                    />
                    <ValidationMessage
                      message={t('Password cannot be empty')}
                      valid={false}
                      wasValidated={!!errors.password}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs="6">
                      <Button
                        type="submit"
                        color="primary"
                        className="px-4"
                        disabled={isSubmitting}
                      >
                        {t('Next')}
                      </Button>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Link to="/forgot-password">{t('Forgot password')}?</Link>
                    </Col>
                    <Col xs="6" className="text-right" />
                  </Row>
                </form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

Login.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
  }).isRequired,
  values: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Login;
