import { compose, hoistStatics, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik/dist/index';
import Language from '../../Language';
import FirstLoading from '../../../../../components/FirstLoading';
import apiRoutes from '../../../../../services/api.routes';
import withLanguage from '../../../language';
import rules from '../../../language/rules';

const enhance = compose(
  withLanguage,
  withFormik({
    mapPropsToValues: ({ language }) => ({
      name: language.name,
      title: language.title,
      image: language.image,
      status: language.status,
      priority: language.priority,
      admin_status: language.adminStatus,
      is_default: language.isDefault,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { updateLanguage, match },
      setSubmitting,
    }) => {
      updateLanguage(values, {
        url: `${apiRoutes.languages}/${match.params.id}`,
        setSubmitting,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchLanguage, match } = this.props;

      fetchLanguage(null, {
        url: `${apiRoutes.languages}/${match.params.id}`,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
);

export default withRouter(hoistStatics(enhance)(Language));
