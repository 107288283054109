import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, CardBody, Card, Table } from 'reactstrap';

const getRowName = t => ({
  usersPassedCheck: t('Users passed check'),
  usersInQueue: t('Users in queue'),
  usersWaitingForCheck: t('Users waiting for check'),
  usersFailedCheck: t('Users failed check'),
});

const SanctionChecks = ({
 t, loading, data,
}) => (
  <Card>
    <CardHeader>
      <i className="fa fa-align-justify" /> {t('Sanction status check')}
    </CardHeader>
    <CardBody>
      <Table
        responsive
        striped
        className={loading ? 'loading' : ''}
      >
        <thead>
          <tr>
            <th>{t('Category')}</th>
            <th>{t('Count')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>{getRowName(t)[key]}</td>
              <td>{value}</td>
            </tr>
        ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
  );

SanctionChecks.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
};

SanctionChecks.defaultProps = {
  loading: false,
  data: {},
};

export default SanctionChecks;
