import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: [],
  users: [],
  conversation: {},
  loading: true,
  error: false,
  errors: {},
};

const reducer = handleActions(
  {
    [types.FETCH_MAILS]: state => ({
      ...state,
      loading: true,
      error: false,
      errors: {},
    }),
    [types.FETCH_MAILS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      conversation: action.payload.conversation,
      loading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_MAILS_REJECTED]: (state, action) => ({
      ...state,
      data: [],
      conversation: {},
      loading: false,
      error: true,
      errors: action.payload,
    }),

    [types.SEND_MAIL]: state => ({
      ...state,
      loading: true,
      error: false,
      errors: {},
    }),
    [types.SEND_MAIL_SUCCESS]: (state, action) => ({
      ...state,
      data: [...state.data, action.payload],
      loading: false,
      error: false,
      errors: {},
    }),
    [types.SEND_MAIL_REJECTED]: (state, action) => ({
      ...state,
      data: [],
      loading: false,
      error: true,
      errors: action.payload,
    }),

    [types.FETCH_USER_EMAIL]: state => ({
      ...state,
      loading: true,
      error: false,
      errors: {},
    }),
    [types.FETCH_USER_EMAIL_SUCCESS]: (state, action) => ({
      ...state,
      users: action.payload,
      loading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_USER_EMAIL_REJECTED]: (state, action) => ({
      ...state,
      data: [],
      loading: false,
      error: true,
      errors: action.payload,
    }),
  },
  initialState,
);

export default reducer;
