import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge, Popover, PopoverBody } from 'reactstrap';
import statusList from './../status';

const Transaction = ({
  t, value, popoverStatus, popoverWarningDetails, toggle,
  changeStatus, status, isShowPopover, loading, showLink, userLink,
}) => (
  <tr key={value.id}>
    <td>
      {showLink ?
        <Link
          to={value.trxFrom === null ?
            userLink(value.trxTo, value.userStatus) :
            userLink(value.trxFrom, value.userStatus)
          }
        >
          {`${t('TRX')} ${value.trxId}: ${value.trxFrom} -> ${value.trxTo}`}
        </Link> : `${t('TRX')} ${value.trxId}: ${value.trxFrom} -> ${value.trxTo}`
      }
    </td>
    <td>{value.trxType}</td>
    <td>{value.amount}</td>
    <td>{value.currency}</td>
    <td>{value.createdAt}</td>
    <td>
      <Badge
        id={`Popover${value.id}`}
        color={status().color}
        onClick={() => toggle('popoverStatus', popoverStatus)}
        style={{ cursor: 'pointer' }}
      >
        {status().title}
      </Badge>
      {!showLink && isShowPopover() && (
        <Popover
          placement="top"
          isOpen={popoverStatus}
          target={`Popover${value.id}`}
          toggle={toggle}
        >
          <PopoverBody className="d-flex">
            <button
              className="mr-1 btn btn-success"
              onClick={() => changeStatus(value.trxId, statusList.APPROVE)}
              disabled={!!loading}
            >
              {t('Approve')}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => changeStatus(value.trxId, statusList.DECLINE)}
              disabled={!!loading}
            >
              {t('Decline')}
            </button>
          </PopoverBody>
        </Popover>
      )}
    </td>
    <td>
      <i
        className="fa fa-info-circle mr-1"
        id={`PopoverWarningDetails${value.id}`}
        onClick={() => toggle('popoverWarningDetails', popoverWarningDetails)}
        style={{ cursor: 'pointer' }}
      />
      <Popover
        placement="top"
        isOpen={popoverWarningDetails}
        target={`PopoverWarningDetails${value.id}`}
        toggle={toggle}
      >
        <PopoverBody>
          {value.triggerCriteria.length !== 0 ?
            <ol className="mb-0">
              {value.triggerCriteria.map(criteria => (
                <li key={criteria.id}>{criteria.description}</li>
              ))}
            </ol> : t('Empty')}
        </PopoverBody>
      </Popover>
    </td>
  </tr>
);

Transaction.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  changeStatus: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  status: PropTypes.func.isRequired,
  isShowPopover: PropTypes.func.isRequired,
  popoverStatus: PropTypes.bool.isRequired,
  popoverWarningDetails: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showLink: PropTypes.bool.isRequired,
  userLink: PropTypes.func.isRequired,
};

export default Transaction;
