import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().required({
    message: 'Name cannot be empty',
    params: { key: 'Name' },
  }),
  title: yup.string().required({
    message: 'Title cannot be empty',
    params: { key: 'Title' },
  }),
});
