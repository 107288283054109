import { compose, hoistStatics, withState, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import fh from '../../helpers/form.helper';
import AuthService from '../../services/auth.service';

const enhance = compose(
  translate('core'),
  withState('email', 'setEmail', ''),
  withState('errors', 'setErrors', {}),
  withState('isSubmitting', 'setIsSubmitting', false),
  withHandlers({
    onSubmit: props => async (event) => {
      event.preventDefault();
      props.setIsSubmitting(true);
      const { errors } = await AuthService.forgotPassword(props.email);
      props.setIsSubmitting(false);

      if (errors) {
        props.setErrors(errors);
      } else {
        props.setErrors({});
        props.setEmail('');
        toast.success(props.t('Check your e-mail'));
      }
    },
    onChange: fh.onChange,
  }),
);

export default withRouter(hoistStatics(enhance)(ForgotPassword));
