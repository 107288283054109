import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/cms/grid';
import ModelService from '../../../services/pendingUser.service';

const initCriteria = {
  order: 'desc',
  id: '',
  first_name: '',
  reg_date: '',
  action_request: '',
  kyc_docs_status: '',
};

const PendingUsers = ({ t, getColumns }) => (
  <Grid
    id="ur-users"
    title={t('Pending users')}
    service={ModelService}
    columns={getColumns(t)}
    initCriteria={initCriteria}
  />
);

PendingUsers.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default PendingUsers;
