import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert, Button, Nav, NavItem } from 'reactstrap';
import { Trans } from 'react-i18next';
import { propOr } from 'ramda';
import { Creatable } from 'react-select';

import InputGroup from '../../../../../components/cms/form/inputGroup';
import './../../../../../assets/css/email.css';
import MessageStatuses from '../../../../../constants/MessageStatuses';
import { AddDocumentModal } from '../../../../modals';

const Conversations = ({
  t, data, mail, errors, values, handleSubmit, handleChange, isSubmitting, getMailByConversation,
  setImage, setUserId, image, userId, toggleAddDocumentModal, options, handleSubjectSelect,
}) => (
  <div className="email-app email-widget mb-4">
    <main>
      <p className="text-center">{t('New Message')}</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 position-relative row form-group">
          <div className="col-10 col-sm-12">
            <InputGroup
              id="to"
              label=""
              name="to"
              placeholder={t('Type email to')}
              onChange={handleChange}
              errors={errors}
              value={values.to}
              icon="fa fa-at"
              disabled
            />
          </div>
        </div>

        <div className="mb-3 position-relative row form-group">
          <div className="col-10 col-sm-12">
            <Creatable
              className="basic-single"
              classNamePrefix="select"
              name="subject"
              onChange={handleSubjectSelect}
              options={options}
              errors={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="position-relative form-group">
              <InputGroup
                id="text"
                name="text"
                type="editor"
                label=""
                errors={errors}
                onChange={handleChange}
                value={values.text}
                icon="fa fa-flag-o"
              />
            </div>
            <div className="position-relative form-group">
              <button
                type="submit"
                className="mr-1 btn btn-success"
                disabled={isSubmitting}
              >
                {t('Send message')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </main>

    <div
      style={{
        flex: '1 1',
        minWidth: 0,
        padding: '1rem',
      }}
    >
      {!_.isEmpty(mail) &&
      <main className="message">
        <div className="details">
          <div className="title">{mail.subject}</div>
          <div className="header">
            <div className="from">{mail.from}</div>
            <div className="date">{mail.date}</div>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: mail.body }}
          />
          {(() => {
            if (mail.attachmentsStatus === MessageStatuses.NO_ATTACHMENTS) return [];
            if (mail.attachmentsStatus === MessageStatuses.NOT_IMPORTED) {
              return (
                <Alert color="info">
                  <Trans>Attachments will be accessible later</Trans>
                </Alert>
              );
            }
            if (mail.attachmentsStatus === MessageStatuses.NOT_VALID) {
              return (
                <Alert color="warning">
                  <Trans>Attachments failed validation</Trans>
                </Alert>
              );
            }
            return propOr([], 'attachments', mail).map(({ id, filename }) => (
              <Button
                key={id}
                color="link"
                className="m-1"
                onClick={() => {
                  setImage(id);
                  setUserId(mail.messageSenderId);
                  toggleAddDocumentModal();
                }}
              >
                {filename}
              </Button>
            ));
          })()}
        </div>
      </main>}

      <main
        className="inbox"
        style={{
          overflow: 'auto',
          height: '358px',
        }}
      >
        <Nav vertical className="messages">
          {data.map(value => (
            <NavItem
              className="message"
              onClick={() => getMailByConversation(value.conversationId)}
              key={value.conversationId}
            >
              <div className="header">
                <span className="from">{value.from}</span>
                <span className="date"><span className="fa fa-paper-clip" />{value.lastMessageDate}</span>
              </div>
              <div className="title">{value.subject}</div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: value.messageIntro }}
              />
            </NavItem>
            ))}
        </Nav>
      </main>
    </div>
    <AddDocumentModal image={image} userId={userId} />
  </div>
);

Conversations.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  mail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getMailByConversation: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setImage: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  userId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  toggleAddDocumentModal: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubjectSelect: PropTypes.func.isRequired,
};

export default Conversations;
