import { connect } from 'react-redux';
import { compose, hoistStatics, withHandlers, withProps, withStateHandlers } from 'recompose';
import { translate } from 'react-i18next';
import Documents from './Documents';
import FirstLoading from '../../../../../components/FirstLoading';
import { externalUserActions } from '../../../../../reducers/externalUser';


const changeStatus = ({ updateDocumentStatus }) => (id, status) => {
  updateDocumentStatus({ status }, {
    url: `/update-user-document-status/${id}`,
  });
};

const mapStateToProps = ({ auth, externalUser }) => ({
  firstLoading: externalUser.firstLoading,
  files: externalUser.data.files,
  token: auth.token,
});

const mapDispatchToProps = {
  updateDocumentStatus: externalUserActions.updateDocumentStatus,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(() => ({
    toggle: false,
    imageData: {},
  }), {
    toggleModal: () => toggle => ({ toggle }),
    getImageData: () => imageData => ({ imageData }),
  }),
  translate('core'),
  withHandlers({
    changeStatus,
  }),
  withProps(() => ({
    url: `${process.env.REACT_APP_API_URL}/downloadExternalUserDocById`,
  })),
  FirstLoading,
);

export default hoistStatics(enhance)(Documents);
