import * as yup from 'yup';

export default yup.object().shape({
  username: yup.string().required({
    message: 'Username cannot be empty',
    params: { key: 'Username' },
  }),
  email: yup.string().required({
    message: 'Email cannot be empty',
    params: { key: 'Email' },
  }),
  first_name: yup.string().required({
    message: 'First name cannot be empty',
    params: { key: 'First name' },
  }),
  last_name: yup.string().required({
    message: 'Last name cannot be empty',
    params: { key: 'Last name' },
  }),
  rolesList: yup.string().required({
    message: 'Roles name cannot be empty',
    params: { key: 'Roles' },
  }),
});
