import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Button, FormGroup, Label, Input } from 'reactstrap';
import fileFormat from './fileFormat';
import Grid from '../../components/cms/grid';
import apiRoutes from '../../services/api.routes';

const exportUsers = ({
  t, data, step, changeStep, handleSubmit, handleInputChange,
  dateFrom, dateTo, personalData, transactions, emails, docs,
  link, downloadFile, token, dataFormat, getColumns,
}) => (
  <Row>
    <Col xs="12" lg="12">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify" /> {t('Export')}
          <div className="pull-right">{t(`Step ${step}/3`)}</div>
        </CardHeader>
        <CardBody>
          {step === 1 &&
            <React.Fragment>
              <Grid
                id="export-users"
                title={t('Choose users')}
                service={{ baseUrl: apiRoutes.getAllExternalUsers }}
                columns={getColumns()}
                t={t}
                data={data}
              />

              <Button
                className="btn btn-primary pull-right"
                type="button"
                onClick={() => changeStep(2)}
              >
                {t('Next')}
              </Button>
            </React.Fragment>
          }

          {step === 2 &&
            <div>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <Label>{t('Date from')}</Label>
                    <Input
                      type="date"
                      id="date-input"
                      name="dateFrom"
                      placeholder={t('Date')}
                      value={dateFrom}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{t('Date to')}</Label>
                    <Input
                      type="date"
                      id="date-input"
                      name="dateTo"
                      placeholder={t('Date')}
                      value={dateTo}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>

                <Col xs="12" md="6">
                  <FormGroup row>
                    <Col md="12">
                      <FormGroup check className="checkbox">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox1"
                          name="personalData"
                          checked={personalData}
                          onChange={handleInputChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="checkbox1"
                        >
                          {t('Personal information')}
                        </Label>
                      </FormGroup>
                      <FormGroup check className="checkbox">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox2"
                          name="transactions"
                          checked={transactions}
                          onChange={handleInputChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="checkbox2"
                        >
                          {t('Transaction history')}
                        </Label>
                      </FormGroup>

                      <FormGroup check className="checkbox">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox3"
                          name="docs"
                          checked={docs}
                          onChange={handleInputChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="checkbox3"
                        >
                          {t('Documents')}
                        </Label>
                      </FormGroup>

                      <FormGroup check className="checkbox">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox4"
                          name="emails"
                          checked={emails}
                          onChange={handleInputChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="checkbox4"
                        >
                          {t('Emails')}
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <div>
                <div className="pull-left">
                  <Button
                    className="btn btn-primary mr-1"
                    type="button"
                    onClick={() => changeStep(1)}
                  >
                    {t('Back')}
                  </Button>
                </div>

                <div className="pull-right">
                  <Button
                    className="btn btn-primary mr-1"
                    type="button"
                    onClick={() => changeStep(3)}
                  >
                    {t('Next')}
                  </Button>
                </div>
              </div>
            </div>
          }

          {step === 3 &&
            <div>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup row>
                      <Col md="12">
                        <FormGroup check className="radio">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="radio1"
                            name="dataFormat"
                            value={fileFormat.XLSX}
                            onChange={handleInputChange}
                            checked={dataFormat === fileFormat.XLSX}
                          />
                          <Label check className="form-check-label" htmlFor="radio1">{t('xlsx file')}</Label>
                        </FormGroup>
                        <FormGroup check className="radio">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="radio2"
                            name="dataFormat"
                            value={fileFormat.DOCX}
                            onChange={handleInputChange}
                            checked={dataFormat === fileFormat.DOCX}
                          />
                          <Label check className="form-check-label" htmlFor="radio2">{t('docx file')}</Label>
                        </FormGroup>
                        <FormGroup check className="radio">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="radio3"
                            name="dataFormat"
                            value={fileFormat.PDF}
                            onChange={handleInputChange}
                            checked={dataFormat === fileFormat.PDF}
                          />
                          <Label check className="form-check-label" htmlFor="radio3">{t('pdf file')}</Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <div>
                  <div className="pull-left">
                    <Button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => changeStep(2)}
                    >
                      {t('Back')}
                    </Button>
                  </div>

                  <div className="pull-right">
                    <Button
                      className="btn btn-primary mr-1"
                      type="submit"
                    >
                      {t('Get users data')}
                    </Button>

                    {link && (
                      <Button
                        className="btn btn-success"
                        onClick={() => downloadFile({ fileName: link, token })}
                      >
                        {t('Download file')}
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          }
        </CardBody>
      </Card>
    </Col>
  </Row>
);

exportUsers.propTypes = {
  t: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default exportUsers;
