import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import EmailTemplatesList from './EmailTemplatesList';
import { emailTemplatesActions, emailTemplatesSelectors } from '../../../../../../reducers/emailTemplates';

const mapStateToProps = state => ({
  emailTemplatesList: emailTemplatesSelectors.getEmailTemplatesList(state),
});

const mapDispatchToProps = {
  fetchEmailTemplates: emailTemplatesActions.fetchEmailTemplates,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchEmailTemplates } = this.props;
      fetchEmailTemplates();
    },
  }),
);

export default enhance(EmailTemplatesList);
