import reducer from './reducers';
import * as mailsEpics from './epics';

export {
  mailsEpics,
};

export { default as mailsActions } from './actions';

export default reducer;
