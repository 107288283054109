import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { languageActions } from '../../../reducers/language';

const wrapperComponent = (WrappedComponent) => {
  const Component = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    title: state.language.data.title,
    language: state.language.data,
    firstLoading: state.language.firstLoading,
  });

  const mapDispatchToProps = {
    fetchLanguage: languageActions.fetchLanguage,
    createLanguage: languageActions.createLanguage,
    updateLanguage: languageActions.updateLanguage,
  };

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    translate('core'),
  );

  return enhance(Component);
};

export default wrapperComponent;

