import * as yup from 'yup';

export default yup.object().shape({
  loginAttempts: yup.string().required({
    message: 'Login attempts cannot be empty',
    params: { key: 'loginAttempts' },
  }),
  loginAttemptsTime: yup.string().required({
    message: 'Login attempts time cannot be empty',
    params: { key: 'loginAttemptsTime' },
  }),
  allowedImageSizes: yup.string().required({
    message: 'Allowed image sizes cannot be empty',
    params: { key: 'allowedImageSizes' },
  }),
  allowedFileTypes: yup.string().required({
    message: 'Allowed file types cannot be empty',
    params: { key: 'allowedFileTypes' },
  }),
});
