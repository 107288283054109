import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import apiRoutes from '../../../services/api.routes';
import Transactions from '../../transactions';

const ExternalUserView = ({
  t, title, externalUser, status, match,
}) => (
  <div>
    <Row>
      <Col md={9}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <td>{t('Username')}</td>
                  <td>{externalUser.username}</td>
                </tr>
                <tr>
                  <td>{t('Email')}</td>
                  <td>{externalUser.email}</td>
                </tr>
                <tr>
                  <td>{t('Name')}</td>
                  <td>{externalUser.name}</td>
                </tr>
                <tr>
                  <td>{t('Surname')}</td>
                  <td>{externalUser.surname}</td>
                </tr>
                <tr>
                  <td>{t('Phone')}</td>
                  <td>{externalUser.phone}</td>
                </tr>
                <tr>
                  <td>{t('Status')}</td>
                  <td>{status}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Transactions
          userId={match.params}
          title={t('Transactions')}
          url={apiRoutes.getTransactionsByUid}
        />
      </Col>
      <Col md={3}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{t('Export')}</strong>
          </CardHeader>
          <CardBody>
            <p>{t('Export')}</p>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{t('Documents')}</strong>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  </div>
);

ExternalUserView.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ExternalUserView;
