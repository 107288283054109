import React from 'react';
import { Input } from 'reactstrap';

const selectFilter = options => ({ value, onFilter }) => (
  <Input type="select" value={value} onChange={onFilter}>
    <option value="" />
    {options.map(option =>
      (<option key={option.value} value={option.value}>{option.label}</option>))}
  </Input>
);

export default selectFilter;
