import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Contract from './components/contract';
import ContractModal from './components/modal';


const Contracts = ({
  t, contracts, data, token, url,
}) => (
  <Card>
    <CardHeader>
      <strong>
        <i className="fa fa-file-o pr-2" />
        {t('Contracts')}
      </strong>
    </CardHeader>
    <CardBody>
      {contracts.result.map(value => (
        <Contract
          t={t}
          key={value}
          data={contracts.entities[value]}
        />
      ))}

      <ContractModal
        t={t}
        token={token}
        data={data}
        url={url}
      />
    </CardBody>
  </Card>
);

Contracts.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  contracts: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default Contracts;
