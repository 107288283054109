import axios from 'axios';
import { toast } from 'react-toastify';
import React from 'react';
import { Trans } from 'react-i18next';

const domain = 'api.compdesc.firs.org.ua';
const ssl = true;
// const domain = 'localhost:3333';
// const ssl = false;

const baseUrl = `http${ssl ? 's' : ''}://${domain}`;
const apiUrl = `${baseUrl}/api/admin`;

const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearToken = () => {
  axios.defaults.headers.common.Authorization = '';
};

// console.log('API URL', apiUrl);

const get = (methodUrl, params) => axios.get(apiUrl + methodUrl, { params });
const post = (methodUrl, params) => axios.post(apiUrl + methodUrl, params);
const put = (methodUrl, params) => axios.put(apiUrl + methodUrl, params);
const destroy = (methodUrl, params) => axios.delete(apiUrl + methodUrl, { params });

const catchError = async (func) => {
  try {
    return await func();
  } catch (e) {
    if (typeof e.response === 'undefined') {
      toast.error((<Trans>Server is unavailable</Trans>));
    } else {
      toast.error((<Trans>{e.response.data.error}</Trans>));
    }
    throw e;
  }
};

export default {
  domain,
  baseUrl,
  apiUrl,
  setToken,
  clearToken,
  get,
  post,
  put,
  destroy,
  catchError,
};
