import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import Fieldset from '../components/fieldset';
import Permissions from '../components/permissions';

const Role = ({
  t, hasPermission, isSubmitting, values, errors,
  handleSubmit, handleChange, changePermissions, setFieldValue,
}) => (
  <div>
    <ToastContainer />
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-lock pr-2" />{values.name}</strong>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <fieldset disabled={!hasPermission}>
                <Row>
                  <Fieldset
                    t={t}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                  />

                  <Permissions
                    t={t}
                    values={values}
                    errors={errors}
                    changePermissions={changePermissions}
                    setFieldValue={setFieldValue}
                  />
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="success"
                      className="mr-2 mb-1 btn-brand"
                    >
                      <i className="fa fa-save mr-2" /> {t('Save')}
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Role.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool.isRequired,
};

export default Role;
