import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchMails, fetchMailsSuccess, fetchMailsRejected,
  sendMail, sendMailSuccess, sendMailRejected,
  fetchUserEmail, fetchUserEmailSuccess, fetchUserEmailRejected,
} = createActions(
  {
    [types.FETCH_MAILS]: [
      identity,
      nthArg(1),
    ],
    [types.SEND_MAIL]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_USER_EMAIL]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_MAILS_SUCCESS,
  types.FETCH_MAILS_REJECTED,
  types.SEND_MAIL_SUCCESS,
  types.SEND_MAIL_REJECTED,
  types.FETCH_USER_EMAIL_SUCCESS,
  types.FETCH_USER_EMAIL_REJECTED,
);

export default {
  fetchMails,
  fetchMailsSuccess,
  fetchMailsRejected,
  sendMail,
  sendMailSuccess,
  sendMailRejected,
  fetchUserEmail,
  fetchUserEmailSuccess,
  fetchUserEmailRejected,
};
