import React from 'react';
import { Row, Col } from 'reactstrap';

import { EmailTemplatesList, EmailTemplateForm } from './components';
import './styles.sass';

const EmailTemplates = () => (
  <div className="email-templates">
    <Row className="justify-content-center">
      <Col sm="5">
        <EmailTemplatesList />
      </Col>
      <Col sm="7">
        <EmailTemplateForm />
      </Col>
    </Row>
  </div>
);

export default EmailTemplates;
