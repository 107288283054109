import { createActions } from 'redux-actions';

import types from './types';

export const {
  setModal,
  toggleModal,

  setPopover,
  togglePopover,
} = createActions(
  types.SET_MODAL,
  types.TOGGLE_MODAL,

  types.SET_POPOVER,
  types.TOGGLE_POPOVER,
);

