import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { gridActions } from './index';

export const fetchDataEpic = action$ => action$.pipe(
  ofType(types.FETCH_GRID_DATA),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => gridActions.fetchGridDataSuccess(response)),
    catchError(error => of(gridActions.fetchGridDataRejected(error))),
  )),
);

export const removeItemEpic = action$ => action$.pipe(
  ofType(types.REMOVE_ITEM),
  mergeMap(action => ajax.remove(`${apiUrl}/${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      console.log('RESPONSE', response);
      return gridActions.removeItemSuccess(response);
    }),
    catchError(error => of(gridActions.removeItemRejected(error))),
  )),
);
