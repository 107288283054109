import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik/dist/index';
import { withRouter } from 'react-router-dom';
import Role from '../../../role/Role';
import RoleService from '../../../../../services/role.service';
import canUpdate from '../../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../../services/api.routes';
import withRole from '../../../role';
import rules from '../../../role/rules';

const enhance = compose(
  withRole,
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      slug: '',
      description: '',
      availablePermissions: [],
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { createRole, history },
      setSubmitting,
    }) => {
      createRole(values, {
        url: `${apiRoutes.roles}`,
        setSubmitting,
        history,
      });
    },
  }),
  canUpdate(RoleService),
);

export default (withRouter(hoistStatics(enhance)(Role)));
