import { mergeMap, map, catchError, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { roleActions } from './index';

export const fetchRoleEpic = action$ => action$.pipe(
  ofType(types.FETCH_ROLE),
  delay(500),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => roleActions.fetchRoleSuccess(response)),
    catchError(error => of(roleActions.fetchRoleRejected(error))),
  )),
);

export const createRoleEpic = action$ => action$.pipe(
  ofType(types.CREATE_ROLE),
  delay(500),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('Created');

      action.meta.history.push(`/users/roles/${response.id}`);

      return roleActions.createRoleSuccess(response);
    }),
    catchError((error) => {
      toast.success('Created error');
      return of(roleActions.createRoleRejected(error));
    }),
  )),
);

export const updateRoleEpic = action$ => action$.pipe(
  ofType(types.UPDATE_ROLE),
  delay(500),
  mergeMap(action => ajax.put(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('Updated');

      return roleActions.updateRoleSuccess(response);
    }),
    catchError((error) => {
      toast.success('Updated error');
      return of(roleActions.updateRoleRejected(error));
    }),
  )),
);

export const fetchRolesEpic = action$ => action$.pipe(
  ofType(types.FETCH_ROLES),
  delay(500),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => roleActions.fetchRolesSuccess(response)),
    catchError(error => of(roleActions.fetchRolesRejected(error))),
  )),
);

export const fetchPermissionsEpic = action$ => action$.pipe(
  ofType(types.FETCH_PERMISSIONS),
  delay(500),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => roleActions.fetchPermissionsSuccess(response)),
    catchError(error => of(roleActions.fetchPermissionsRejected(error))),
  )),
);
