import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle, withProps } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import UsersOverviewsChart from './UsersOverviewsChart';
import { chartsActions } from '../../reducers/charts';

// todo: generateArrayRandomColors Винести в окремий файл або хелпер
const generateArrayRandomColors = length => (
  [...Array(length).keys()].map(() => `#${((1 << 24) * Math.random() | 0).toString(16)}`)
);

const mapStateToProps = state => ({
  chartData: state.charts.usersOverviews.data,
});

const mapDispatchToProps = {
  fetchUsersOverviews: chartsActions.fetchUsersOverviews,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      this.props.fetchUsersOverviews();
    },
  }),
  withProps(({ chartData }) => ({
    labels: Object.keys(chartData),
    data: Object.values(chartData),
    colors: generateArrayRandomColors(Object.keys(chartData).length),
  })),
);

export default withRouter(hoistStatics(enhance)(UsersOverviewsChart));
