import { compose, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import SanctionChecks from './SanctionChecks';
import { sanctionedUsersActions, sanctionedUsersSelectors } from '../../../../reducers/sanctionedUsers';

const { fetchSanctionChecks } = sanctionedUsersActions;
const { getSanctionLoading, getSanctionChecks } = sanctionedUsersSelectors;

const mapStateToProps = state => ({
  loading: getSanctionLoading(state),
  data: getSanctionChecks(state),
});

const mapDispatchToProps = {
  fetchSanctionChecks,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      this.props.fetchSanctionChecks();
    },
  }),
);

export default enhance(SanctionChecks);
