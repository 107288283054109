import { mergeMap, map, catchError, delay } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { uploadActions } from './index';

export const updateTempFileEpic = action$ => action$.pipe(
  ofType(types.UPDATE_TEMP_FILE),
  delay(500),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => uploadActions.updateTempFileSuccess(response)),
    catchError(error => of(uploadActions.updateTempFileRejected(error))),
  )),
);
