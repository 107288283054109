import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/cms/grid';
import ModelService from '../../services/translation.service';

const initCriteria = {
  id: '',
  key: '',
  text: '',
  namespace: '',
  is_html: '',
};

const Languages = ({ t, getColumns }) => (
  <Grid
    id="translations"
    title={t('Translations')}
    service={ModelService}
    columns={getColumns(t)}
    initCriteria={initCriteria}
    showViewButton={false}
    showAddButton
  />
);

Languages.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Languages;
