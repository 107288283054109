const NEW = 0;
const APPROVED = 1;
const DECLINED = 2;
const OLD = 3;

module.exports = {
  NEW,
  APPROVED,
  DECLINED,
  OLD,
};
