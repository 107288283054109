import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/cms/grid';
import ModelService from '../../../services/newUser.service';

const initCriteria = {
  order: 'desc',
  id: '',
  first_name: '',
  last_name: '',
  created_at: '',
  action_request: '',
  kyc_docs_status: '',
};

const NewUsers = ({ t, getColumns }) => (
  <Grid
    id="nw-users"
    title={t('Other users')}
    service={ModelService}
    columns={getColumns(t)}
    initCriteria={initCriteria}
  />
);

NewUsers.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default NewUsers;
