import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';

const BalanceOverview = ({
  t, externalUserBalance,
}) => (
  <Card>
    <CardHeader>
      <i className="fa fa-align-justify" /> {t('Assets / Balance overview')}
    </CardHeader>
    <CardBody>
      <p>{t('Assets, Balance overview')}</p>
    </CardBody>
  </Card>
);

BalanceOverview.propTypes = {
  t: PropTypes.func.isRequired,
  externalUserBalance: PropTypes.object.isRequired,
};

export default BalanceOverview;
