import { compose, hoistStatics } from 'recompose';
import RoleService from '../../../../services/role.service';
import canUpdate from '../../../../components/cms/permissions/canUpdate';
import withRole from '../../role';
import Fieldset from './Fieldset';
import spinner from '../../../../components/Spinner';

const enhance = compose(
  withRole,
  canUpdate(RoleService),
  spinner,
);

export default hoistStatics(enhance)(Fieldset);
