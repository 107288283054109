import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchUsersNumber, fetchUsersNumberSuccess, fetchUsersNumberRejected,
} = createActions(
  {
    [types.FETCH_USERS_NUMBER]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_USERS_NUMBER_SUCCESS,
  types.FETCH_USERS_NUMBER_REJECTED,
);

export default {
  fetchUsersNumber,
  fetchUsersNumberSuccess,
  fetchUsersNumberRejected,
};
