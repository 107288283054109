import { compose, hoistStatics, lifecycle } from 'recompose';
import { withFormik } from 'formik/dist/index';
import { withRouter } from 'react-router-dom';
import Role from '../../../role/Role';
import RoleService from '../../../../../services/role.service';
import canUpdate from '../../../../../components/cms/permissions/canUpdate';
import apiRoutes from '../../../../../services/api.routes';
import withRole from '../../../role';
import rules from '../../../role/rules';

const enhance = compose(
  withRole,
  withFormik({
    mapPropsToValues: ({ role }) => ({
      name: role.name,
      slug: role.slug,
      description: role.description,
      availablePermissions: role.availablePermissions,
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { updateRole, match },
      setSubmitting,
    }) => {
      updateRole(values, {
        url: `${apiRoutes.roles}/${match.params.id}`,
        setSubmitting,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchRole, match } = this.props;

      fetchRole(null, {
        url: `${apiRoutes.roles}/${match.params.id}`,
      });
    },
  }),
  canUpdate(RoleService),
);

export default (withRouter(hoistStatics(enhance)(Role)));
