import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import { pipe, prop, tap } from 'ramda';

import * as ajax from '../../helpers/ajax.helper';
import apiUrl from '../../constants/api';
import { getPayload, getRoute } from '../../helpers/epics.helper';
import types from './types';
import { userActions } from './index';

export const fetchCdUserEpic = action$ => action$.pipe(
  ofType(types.FETCH_CD_USER),
  mergeMap(action => ajax.get(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => userActions.fetchCdUserSuccess(response)),
    catchError(error => of(userActions.fetchCdUserRejected(error))),
  )),
);

export const createCdUserEpic = action$ => action$.pipe(
  ofType(types.CREATE_CD_USER),
  mergeMap(action => ajax.post(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('User created');

      return userActions.createCdUserSuccess(response);
    }),
    catchError((error) => {
      action.meta.setSubmitting(false);
      toast.error(error.message);

      return of(userActions.createCdUserRejected(error));
    }),
  )),
);

export const updateCdUserEpic = action$ => action$.pipe(
  ofType(types.UPDATE_CD_USER),
  mergeMap(action => ajax.put(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('User updated');

      return userActions.updateCdUserSuccess(response);
    }),
    catchError((error) => {
      console.log('error', error);
      action.meta.setSubmitting(false);
      toast.error(error.message);

      return of(userActions.updateCdUserRejected(error));
    }),
  )),
);

export const deleteCdUserEpic = action$ => action$.pipe(
  ofType(types.DELETE_CD_USER),
  mergeMap(action => ajax.remove(`${apiUrl}${getRoute(action)}`, getPayload(action)).pipe(
    map(({ response }) => {
      action.meta.setSubmitting(false);
      toast.success('Settings update');

      return userActions.deleteCdUserSuccess(response);
    }),
    catchError((error) => {
      action.meta.setSubmitting(false);
      toast.error(error.message);

      return of(userActions.deleteCdUserRejected(error));
    }),
  )),
);

export const uploadCdUserImageEpic = action$ => action$.pipe(
  ofType(types.UPLOAD_CD_USER_IMAGE),
  mergeMap(action => ajax.post(
    `${apiUrl}${getRoute(action)}`,
    getPayload(action),
    // { 'Content-Type': 'multipart/form-data' },
  ).pipe(
    map(({ response }) => userActions.uploadCdUserImageSuccess(response)),
    catchError(error => of(userActions.uploadCdUserImageRejected(error))),
  )),
);

export const addDocumentToUser = action$ => action$.pipe(
  ofType(types.ADD_DOCUMENT_TO_USER),
  mergeMap(action => ajax.post(`${apiUrl}/addAttachmentToDocuments`, getPayload(action)).pipe(
    map(pipe(
      prop('response'),
      tap(() => {
        toast.success('Document was successfully added');
      }),
      userActions.addDocumentToUserSuccess,
    )),
    catchError(pipe(
      tap(() => {
        toast.error('Error occurred while adding document');
      }),
      error => of(userActions.addDocumentToUserRejected(error)),
    )),
  )),
);
