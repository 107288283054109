import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';

const oneCols = { xs: 12, md: 12 };

const Fieldset = ({
  t, errors, values, handleChange,
}) => (
  <Col md={6}>
    <FormGroup row className="my-0">
      <Col {...oneCols}>
        <InputGroup
          id="name"
          label={t('Name')}
          name="name"
          placeholder={t('Name')}
          onChange={handleChange}
          errors={errors}
          value={values.name}
          icon="fa fa-lock"
        />
      </Col>
      <Col {...oneCols}>
        <InputGroup
          id="slug"
          label={t('Slug')}
          name="slug"
          placeholder={t('Slug')}
          onChange={handleChange}
          errors={errors}
          value={values.slug}
          icon="fa fa-info"
        />
      </Col>
      <Col {...oneCols}>
        <InputGroup
          id="description"
          label={t('Description')}
          name="description"
          placeholder={t('Description')}
          onChange={handleChange}
          errors={errors}
          value={values.description}
          icon="fa fa-tag"
        />
      </Col>
    </FormGroup>
  </Col>
);

Fieldset.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Fieldset;
