import * as yup from 'yup';

export default yup.object().shape({
  body: yup.string().required({
    message: 'Text cannot be empty',
    params: { key: 'Text' },
  }),
  subject: yup.string().required({
    message: 'Subject cannot be empty',
    params: { key: 'Subject' },
  }),
});
