import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import PreviewImage from '../../../../../components/previewImage';

const UserView = ({
  t,
  userId,
  title,
  image,
  getAvatar,
  statusList,
  user
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            {user &&
              <fieldset disabled={user.id !== userId}>
                <Row>
                  <Col md={9}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>Username</td>
                          <td>{user.username}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{user.email}</td>
                        </tr>
                        <tr>
                          <td>First name</td>
                          <td>{user.firstName}</td>
                        </tr>
                        <tr>
                          <td>First name</td>
                          <td>{user.firstName}</td>
                        </tr>
                        <tr>
                          <td>Last name</td>
                          <td>{user.last_name}</td>
                        </tr>
                        <tr>
                          <td>Company name</td>
                          <td>{user.company_name}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{user.phone}</td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>{user.status}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={3}>
                    <div className="center">
                      <PreviewImage
                        src={image && image.url ?
                          image.url :
                          getAvatar(user.image, 150, 150, true)}
                        height={150}
                        wigth={150}
                      />
                    </div>
                  </Col>
                </Row>
              </fieldset>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

UserView.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  statusList: PropTypes.array.isRequired,
};

export default UserView;
