import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: {
    availablePermissions: [],
  },
  roles: [],
  permissions: {},
  loading: true,
  firstLoading: false,
  error: false,
};

const reducer = handleActions(
  {
    [types.FETCH_ROLE]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_ROLE_REJECTED]: (state, action) => ({
      ...state,
      data: {
        availablePermissions: [],
      },
      loading: false,
      firstLoading: false,
      error: true,
    }),

    [types.CREATE_ROLE]: state => ({
      ...state,
      data: {
        availablePermissions: [],
      },
      permissions: {},
      loading: true,
      error: false,
    }),
    [types.CREATE_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    }),
    [types.CREATE_ROLE_REJECTED]: state => ({
      ...state,
      data: {
        availablePermissions: [],
      },
      loading: false,
      error: true,
    }),

    [types.UPDATE_ROLE]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.UPDATE_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    }),
    [types.UPDATE_ROLE_REJECTED]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: true,
    }),

    [types.FETCH_ROLES]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_ROLES_SUCCESS]: (state, action) => ({
      ...state,
      roles: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_ROLES_REJECTED]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: true,
    }),

    [types.FETCH_PERMISSIONS]: (state, action) => ({
      ...state,
      data: {
        availablePermissions: [],
      },
      permissions: {},
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_PERMISSIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      permissions: action.payload,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_PERMISSIONS_REJECTED]: state => ({
      ...state,
      loading: false,
      firstLoading: false,
      error: true,
    }),
  },
  initialState,
);

export default reducer;
