import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

const UsersOverviewChart = ({
 t, data, labels, colors,
}) => (
  <Card>
    <CardHeader>{t('Users Overviews')}</CardHeader>
    <CardBody>
      <div className="chart-wrapper">
        <Pie
          data={{
            labels,
            datasets: [{
              data,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            }],
          }}
        />
      </div>
    </CardBody>
  </Card>
);

UsersOverviewChart.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default UsersOverviewChart;
