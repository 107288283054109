import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Item from './item';


const Addresses = ({ t, data }) => (
  <Table
    responsive
    striped
    className="mt-4"
  >
    <thead>
      <tr>
        <th>{t('Id')}</th>
        <th>{t('Address')}</th>
        <th>{t('City')}</th>
        <th>{t('Country')}</th>
        <th>{t('Zip code')}</th>
        <th>{t('District')}</th>
        <th>{t('Status')}</th>
        <th>{t('Created at')}</th>
        <th>{t('Updated at')}</th>
      </tr>
    </thead>
    <tbody>
      {data.result.map(value => (
        <Item
          t={t}
          key={value}
          value={data.entities[value]}
        />
      ))}
    </tbody>
  </Table>
);

Addresses.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Addresses;
