import { pipe, split, toLower, map, mapObjIndexed, values, join } from 'ramda';

function firstLetterToUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const toCamelCaseWithSpaces = pipe(
  split('_'),
  map(toLower),
  mapObjIndexed((value, index) => {
    if (!parseInt(index, 10)) return firstLetterToUpperCase(value);
    return value;
  }),
  values,
  join(' '),
);

export default { firstLetterToUpperCase, toCamelCaseWithSpaces };
