import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Users from './Users';
import columnTypes from '../../components/cms/grid/columnTypes';
import gc from '../../components/cms/gridComponents';
import ModelService from '../../services/user.service';
import PreviewImage from '../../components/previewImage';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (
      <Link to={ModelService.getUpdateUrl(item.id)}>
        {item.id}
      </Link>
    ),
  },
  {
    name: 'image',
    title: '',
    value: item => (
      <div className="center va-m">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage
            className="img-avatar"
            width="35"
            src={ModelService.getAvatar(item.image, 35, 35, true)}
            alt={item.username}
          />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
    tdClassName: 'p-0 va-m',
  },
  {
    name: 'roles',
    title: '',
    value: ({ item: { roles } }) => roles.map(role => (
      <Badge className="mr-1" color="primary">{role.name}</Badge>
    )),
    filter: false,
  },
  {
    name: 'username',
    title: t('Username'),
  },
  {
    name: 'firstName',
    title: t('First name'),
  },
  {
    name: 'lastName',
    title: t('Last name'),
  },
  {
    name: 'email',
    title: t('E-mail'),
  },
  {
    name: 'phone',
    title: t('Phone'),
  },
  // {
  //   name: 'created_at',
  //   title: t('Created at'),
  //   value: gc.dateDataCell({ attr: 'created_at' }),
  //   filter: false,
  // },
  {
    name: 'status',
    title: t('Status'),
    value: ({ item: { status } }) => (
      <Badge color={ModelService.getColorByStatus(status)}>
        {ModelService.getStatusTitle(status, t)}
      </Badge>
    ),
    filter: gc.selectFilter(ModelService.getStatusOptions(t)),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('core'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Users);
