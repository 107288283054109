import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardColumns, CardHeader, Col, Row } from 'reactstrap';
import { Doughnut, Line } from 'react-chartjs-2';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';

import SanctionsUsers from '../sanctionedUsers';
import Transactions from '../transactions';
import UsersOverviewsChart from '../usersOverviewsChart';
import { SanctionChecks } from './components';

const options = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips,
  },
  maintainAspectRatio: false,
};

const buttons = [
  { id: 1, title: 'Hourly', type: 'hourly' },
  { id: 2, title: 'Daily', type: 'daily' },
  { id: 3, title: 'Weekly', type: 'weekly' },
  { id: 4, title: 'Monthly', type: 'monthly' },
  { id: 5, title: 'Yearly', type: 'yearly' },
];

const Dashboard = ({
  t, user, chartData, handleChangeType,
}) => (
  <div className="">
    <div className="animated fadeIn">
      <CardColumns className="cols-3 mt-4">
        <Card>
          <CardHeader>Doughnut Chart</CardHeader>
          <CardBody>
            <div className="chart-wrapper">
              <Doughnut
                data={{
                  labels: chartData.labels,
                  datasets: [{
                    data: chartData.data,
                    backgroundColor: chartData.colors,
                    hoverBackgroundColor: chartData.colors,
                  }],
                }}
              />
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Line Chart</CardHeader>
          <CardBody>
            <div className="chart-wrapper">
              <Line
                data={{
                  labels: chartData.labels,
                  datasets: [
                    {
                      label: chartData.currency,
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(75,192,192,0.4)',
                      borderColor: 'rgba(75,192,192,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(75,192,192,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: chartData.data,
                    },
                  ],
                }}
                options={options}
              />
            </div>
          </CardBody>
        </Card>

        <UsersOverviewsChart />

      </CardColumns>
      <Card>
        <CardBody>
          <Row className="align-items-center">
            {buttons.map(button => (
              <Col
                col="6"
                sm="4"
                md="2"
                xl
                className="mb-3 mb-xl-0"
                key={`col-${button.id}`}
              >
                <Button
                  key={button.id}
                  className="btn btn-primary btn-block"
                  onClick={() => handleChangeType(button.type)}
                >
                  {`${t(button.title)}`}
                </Button>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </div>

    <Row>
      <Col xs="12" lg="7">
        <Transactions
          title={t('WL Users Activity')}
          url="/transaction/getTransactionAlerts"
          showLink
        />
        <SanctionChecks />
      </Col>

      <Col xs="12" lg="5">
        <SanctionsUsers />
      </Col>
    </Row>
  </div>
);

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
