import { compose, hoistStatics, lifecycle, withHandlers, withProps, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import Grid from './Grid';
import { gridActions } from '../../../reducers/grid';
import FirstLoading from '../../FirstLoading';

const generateParamsUrl = (params, props, clearPageParam) => {
  const urlParams = window.location.search;

  if (urlParams === '') {
    const stringified = queryString.stringify(params);
    window.history.pushState('', '', `?${stringified}`);
  } else {
    const parsed = queryString.parse(urlParams);
    const generateNewParams = { ...parsed, ...params };
    const paramsWithoutEmptyValues = _.pickBy(generateNewParams, (value, key) => (
      clearPageParam ? value !== '' && key !== 'page' : value !== ''
    ));
    const stringified = queryString.stringify(paramsWithoutEmptyValues);
    const url = stringified ? `?${stringified}` : `${props.location.pathname}`;

    window.history.pushState('', '', url);
  }
};

const goToPage = props => (page) => {
  generateParamsUrl({ page }, props);
  const criteria = {
    ...props.criteria, page,
  };
  props.fetchData(criteria, { url: props.service.baseUrl });
};

const changePageSize = props => (limit) => {
  generateParamsUrl({ limit }, props, true);
  const criteria = {
    ...props.criteria, limit, page: 1,
  };
  props.fetchData(criteria, { url: props.service.baseUrl });
};

const filter = props => ({ attribute, value }) => {
  const criteria = {
    ...props.criteria, [attribute]: value, page: 1,
  };

  generateParamsUrl({ [attribute]: value }, props, true);
  props.changeFilter(criteria);
  props.fetchData(criteria, { url: props.service.baseUrl });
};

const sort = props => ({ order, sortBy }) => {
  generateParamsUrl({ order, sortBy }, props);
  const criteria = {
    ...props.criteria, order, sortBy,
  };

  props.changeFilter(criteria);
  props.fetchData(criteria, { url: props.service.baseUrl });
};

const resetFilter = ({ fetchData, location, service }) => () => {
  window.history.pushState('', '', `${location.pathname}`);
  fetchData({}, { url: service.baseUrl });
};

const clearFilter = () => {
  const urlParams = window.location.search;
  const parsed = queryString.parse(urlParams);
  const size = _.size(parsed);
  const findPageParam = _.has(parsed, 'page');

  return !urlParams || (size === 1 && findPageParam);
};

const destroyItem = ({ toggleModal, setSelectedId }) => (id) => {
  setSelectedId(id);
  toggleModal();
};

const hideConfirm = ({ toggleModal }) => () => toggleModal();

const defaultConfirmCallback = ({
  toggleModal, removeItem, selectedId, removeUrl,
}) => () => {
  if (selectedId !== null && removeUrl) {
    removeItem(null, { url: `${removeUrl}/${selectedId}` });
  }

  toggleModal();
};

const mapStateToProps = state => ({
  data: state.grid.data,
  loading: state.grid.loading,
  firstLoading: state.grid.firstLoading,
  count: state.grid.total,
  lastPage: state.grid.lastPage,
  page: state.grid.page,
  limit: state.grid.perPage,
});

const mapDispatchToProps = {
  fetchData: gridActions.fetchGridData,
  removeItem: gridActions.removeItem,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withStateHandlers(() => ({
    showConfirm: false,
    selectedId: null,
    criteria: {
      page: 1,
      order: 'desc',
      sortBy: 'id',
    },
  }), {
    changeFilter: state => criteria => ({
      ...state, criteria,
    }),
    toggleModal: state => () => ({
      showConfirm: !state.showConfirm,
    }),
    setSelectedId: () => id => ({
      selectedId: id,
    }),
  }),
  withProps(() => ({
    isDefaultCriteria: clearFilter(),
  })),
  lifecycle({
    componentDidMount() {
      const { criteria, fetchData, service } = this.props;
      const urlParams = window.location.search;

      if (urlParams !== '') {
        const parsed = queryString.parse(urlParams);
        const newCriteria = { ...criteria, ...parsed };

        fetchData(newCriteria, {
          url: service.baseUrl,
          firstLoading: true,
        });
      } else {
        fetchData({ ...criteria }, {
          url: service.baseUrl,
          firstLoading: true,
        });
      }
    },
  }),
  FirstLoading,
  withHandlers({
    goToPage,
    filter,
    sort,
    resetFilter,
    changePageSize,
    destroyItem,
    hideConfirm,
    defaultConfirmCallback,
  }),
);

export default withRouter(hoistStatics(enhance)(Grid));
