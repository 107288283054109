import reducer from './reducers';
import * as roleEpics from './epics';

export {
  roleEpics,
};

export { default as roleActions } from './actions';

export default reducer;
