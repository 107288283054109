import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  langs: [],
  defaultLanguage: 'en',
  requestError: '',
  data: {
    admin: {},
    site: {},
    transaction: {},
    security: {},
  },
  loading: true,
  firstLoading: false,
  error: false,
};

const reducer = handleActions(
  {
    [types.FETCH_SETTINGS]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
    }),
    [types.FETCH_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
    }),
    [types.FETCH_SETTINGS_REJECTED]: state => ({
      ...state,
      loading: false,
      firstLoading: false,
      error: true,
    }),


    [types.UPDATE_SETTINGS]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.UPDATE_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: false,
    }),
    [types.UPDATE_SETTINGS_REJECTED]: state => ({
      ...state,
      loading: false,
      error: true,
    }),


    [types.FETCH_LANGUAGES]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.FETCH_LANGUAGES_SUCCESS]: (state, action) => ({
      ...state,
      langs: action.payload,
      loading: false,
      error: false,
    }),
    [types.FETCH_LANGUAGES_REJECTED]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

export default reducer;
