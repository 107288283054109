import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchUser, fetchUserSuccess, fetchUserRejected,
  logIn, logInSuccess, logInRejected,
  logOut, logOutSuccess, logOutRejected,
  clearUser,
} = createActions(
  {
    [types.FETCH_USER]: [
      identity,
      nthArg(1),
    ],
    [types.LOG_IN]: [
      identity,
      nthArg(1),
    ],
    [types.LOG_OUT]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_USER_SUCCESS,
  types.FETCH_USER_REJECTED,
  types.LOG_IN_SUCCESS,
  types.LOG_IN_REJECTED,
  types.LOG_OUT_SUCCESS,
  types.LOG_OUT_REJECTED,
  types.CLEAR_USER,
);

export default {
  fetchUser,
  fetchUserSuccess,
  fetchUserRejected,
  logIn,
  logInSuccess,
  logInRejected,
  logOut,
  logOutSuccess,
  logOutRejected,
  clearUser,
};
