import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HOC as Permissions } from 'react-redux-permissions';

const LinkWrap = props => (
  <span roles={props.roles}>
    <Link to={props.to} className={props.className}><i className={`${props.icon} mr-2`} />
      {props.text || props.t('Add new')}
    </Link>
  </span>);

const buttonWrap = (t, to, icon, text, permissionToCreate) => {
  const CreateButton = Permissions(permissionToCreate, [])(LinkWrap, null);
  return (
    <CreateButton
      color="primary"
      to={to}
      className="btn btn-primary btn-brand"
      t={t}
      icon={icon}
    >
      <i className={`${icon} mr-2`} />{text || t('Add new')}
    </CreateButton>);
};

const AddNewButton = ({ t, to, icon, text, permissionToCreate }) => (
  buttonWrap(t, to, icon, text, permissionToCreate)
);

AddNewButton.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  permissionToCreate: PropTypes.array,
};

export default AddNewButton;
