import { handleActions } from 'redux-actions';
import types from './types';
import { mergeByPath } from '../../helpers/store.helper';

const initialState = {
  data: {
    newUsers: 0,
    whitelisted: 0,
    blacklisted: 0,
    underReview: 0,
  },
  error: false,
};

const reducer = handleActions(
  {
    [types.FETCH_USERS_NUMBER]: mergeByPath(['data'], () => ({})),
    [types.FETCH_USERS_NUMBER_SUCCESS]: mergeByPath(['data'], action => action.payload.data),
    [types.FETCH_USERS_NUMBER_REJECTED]: mergeByPath(['data'], () => ({})),
  },
  initialState,
);

export default reducer;
