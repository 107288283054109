import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { HOC as Permissions } from 'react-redux-permissions';
import { has } from 'ramda';

const hasStatus = has('compliance_status');

const ButtonComponentWrap = props => (
  <div roles={props.roles} className="d-inline">
    <Button onClick={props.onClick} className={props.className}>
      <i className="fa fa-remove" />
    </Button>
  </div>);

const ButtonUpdateComponentWrap = props => (
  <div roles={props.roles} className="d-inline">
    <Link
      className="btn btn-primary btn-sm mr-1 icon"
      to={ props.to}
    >
      <i className="fa fa-pencil" />
    </Link>
  </div>);

const buttonWrap = (permissionToDelete, onClickDelete, item) => {
  const DeleteButton = Permissions(permissionToDelete, [])(
    ButtonComponentWrap,
    null,
  );
  return (
    <DeleteButton
      onClick={() => { onClickDelete(item.id); }}
      className="btn btn-danger btn-sm mr-1 icon"
    >
      <i className="fa fa-remove" />
    </DeleteButton>);
};

const buttonWrapUpdate = (permissionToUpdate, getUpdateUrl, baseRoute, item, getUpdateExternalUserUrl) => {
  const UpdateButton = Permissions(permissionToUpdate, [])(
    ButtonUpdateComponentWrap,
    null,
  );
  return (
    <UpdateButton
      to={
        hasStatus(item) ?
          getUpdateExternalUserUrl(baseRoute, item.id, item.compliance_status) :
          getUpdateUrl(baseRoute, item.id)
      }
      className="btn btn-danger btn-sm mr-1 icon"
    >
      <i className="fa fa-remove" />
    </UpdateButton>);
};

const Buttons = ({
  item,
  baseRoute,
  getUpdateUrl,
  getUpdateExternalUserUrl,
  getViewUrl,
  showViewButton,
  showUpdateButton,
  showDeleteButton,
  column,
  onClickDelete,
  permissionToDelete,
  permissionToUpdate,
}) => (
  <React.Fragment>
    <div className="pull-right">
      {column.customButtons && column.customButtons({ item })}
      { showViewButton && (
        <Link className="btn btn-success btn-sm mr-1 icon" to={getViewUrl(baseRoute, item.id)}>
          <i className="fa fa-eye" />
        </Link>
      )}

      { showUpdateButton && (
        buttonWrapUpdate(permissionToUpdate, getUpdateUrl, baseRoute, item, getUpdateExternalUserUrl)
      )}
      { showDeleteButton && (
        buttonWrap(permissionToDelete, onClickDelete, item)
      )}
    </div>
  </React.Fragment>
);

Buttons.propTypes = {
  getUpdateUrl: PropTypes.func.isRequired,
  getViewUrl: PropTypes.func.isRequired,
  baseRoute: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  showViewButton: PropTypes.bool.isRequired,
  showUpdateButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  permissionToDelete: PropTypes.array,
};

export default Buttons;
