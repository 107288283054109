const NEW = 0;
const WHITELIST = 3;
const BLACKLIST = 4;
const REVIEW = [1, 2, 5];

module.exports = {
  NEW,
  WHITELIST,
  BLACKLIST,
  REVIEW,
};
