import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchRole, fetchRoleSuccess, fetchRoleRejected,
  createRole, createRoleSuccess, createRoleRejected,
  updateRole, updateRoleSuccess, updateRoleRejected,
  fetchRoles, fetchRolesSuccess, fetchRolesRejected,
  fetchPermissions, fetchPermissionsSuccess, fetchPermissionsRejected,
} = createActions(
  {
    [types.FETCH_ROLE]: [
      identity,
      nthArg(1),
    ],
    [types.CREATE_ROLE]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_ROLE]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_ROLES]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_PERMISSIONS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_ROLE_SUCCESS,
  types.FETCH_ROLE_REJECTED,
  types.CREATE_ROLE_SUCCESS,
  types.CREATE_ROLE_REJECTED,
  types.UPDATE_ROLE_SUCCESS,
  types.UPDATE_ROLE_REJECTED,
  types.FETCH_ROLES_SUCCESS,
  types.FETCH_ROLES_REJECTED,
  types.FETCH_PERMISSIONS_SUCCESS,
  types.FETCH_PERMISSIONS_REJECTED,
);

export default {
  fetchRole,
  fetchRoleSuccess,
  fetchRoleRejected,
  createRole,
  createRoleSuccess,
  createRoleRejected,
  updateRole,
  updateRoleSuccess,
  updateRoleRejected,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesRejected,
  fetchPermissions,
  fetchPermissionsSuccess,
  fetchPermissionsRejected,
};
