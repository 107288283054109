import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchLanguage, fetchLanguageSuccess, fetchLanguageRejected,
  createLanguage, createLanguageSuccess, createLanguageRejected,
  updateLanguage, updateLanguageSuccess, updateLanguageRejected,
} = createActions(
  {
    [types.FETCH_LANGUAGE]: [
      identity,
      nthArg(1),
    ],
    [types.CREATE_LANGUAGE]: [
      identity,
      nthArg(1),
    ],
    [types.UPDATE_LANGUAGE]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_LANGUAGE_SUCCESS,
  types.FETCH_LANGUAGE_REJECTED,
  types.CREATE_LANGUAGE_SUCCESS,
  types.CREATE_LANGUAGE_REJECTED,
  types.UPDATE_LANGUAGE_SUCCESS,
  types.UPDATE_LANGUAGE_REJECTED,
);

export default {
  fetchLanguage,
  fetchLanguageSuccess,
  fetchLanguageRejected,
  createLanguage,
  createLanguageSuccess,
  createLanguageRejected,
  updateLanguage,
  updateLanguageSuccess,
  updateLanguageRejected,
};
