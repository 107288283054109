import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle, withHandlers, withState } from 'recompose';
import { translate } from 'react-i18next';
import { withFormik } from 'formik/dist/index';
import { find, map, pipe, propEq, values as Rvalues } from 'ramda';

import { conversationsActions } from '../../../../../reducers/conversations';
import Conversations from './Conversations';
import apiRoutes from '../../../../../services/api.routes';
import rules from './rules';
import FirstLoading from '../../../../../components/FirstLoading';
import { uiActions } from '../../../../../reducers/ui';
import { emailTemplatesActions, emailTemplatesSelectors } from '../../../../../reducers/emailTemplates';

const { getEmailTemplatesEntities } = emailTemplatesSelectors;
const { fetchEmailTemplates } = emailTemplatesActions;

const getMailByConversation = props => (id) => {
  props.fetchMail({ id }, {
    url: apiRoutes.getLastConversationMessage,
  });
};

const mapStateToProps = (state) => {
  const templatesList = pipe(
    getEmailTemplatesEntities,
    Rvalues,
  )(state);

  const options = map(({ subject, id }) => ({
    label: subject,
    value: id,
  }), templatesList);

  return {
    data: state.conversations.data,
    mail: state.conversations.mail,
    firstLoading: state.conversations.firstLoading,
    externalUserEmail: state.externalUser.data.email,
    templatesList,
    options,
  };
};

const mapDispatchToProps = {
  fetchData: conversationsActions.fetchConversations,
  fetchMail: conversationsActions.fetchMail,
  setConversation: conversationsActions.setConversation,
  toggleAddDocumentModal: () => uiActions.toggleModal('addDocument'),
  fetchEmailTemplates,
};

const handleSubjectSelect = ({ setFieldValue, templatesList }) => ({ value }, { name }) => {
  setFieldValue(name, value);
  const text = find(propEq('id', parseInt(value, 10)), templatesList);
  if (text) setFieldValue('text', text.body);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withState('image', 'setImage', null),
  withState('userId', 'setUserId', null),
  withFormik({
    mapPropsToValues: props => ({
      to: props.externalUserEmail,
      subject: '',
      text: '',
      type: 'conversation',
    }),
    validationSchema: rules,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: (values, {
      props: { setConversation },
      setSubmitting,
      resetForm,
    }) => {
      setConversation(values, {
        url: apiRoutes.sendEmail,
        setSubmitting,
        resetForm,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchData, matchParams: { id }, fetchEmailTemplates: fetchTemplates } = this.props;

      fetchData({ id }, {
        url: apiRoutes.getConversationsByEmail,
        firstLoading: true,
      });

      fetchTemplates();
    },
  }),
  FirstLoading,
  withHandlers({
    getMailByConversation,
    handleSubjectSelect,
  }),
);

export default hoistStatics(enhance)(Conversations);
