import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Confirm from '../../components/confirm/Confirm';


const SanctionedBySwitzerland = ({
  t, data, loading, toggle, toggleModal, matchItem, index,
  showConfirm, toggleConfirm, defaultConfirmCallback,
}) => (
  <Card>
    <CardHeader>
      <i className="fa fa-align-justify" /> {t('Sanction alerts')}
    </CardHeader>
    <CardBody>
      <Table
        responsive
        striped
        className={loading ? 'loading' : ''}
      >
        <thead>
          <tr>
            <th>{t('Id')}</th>
            <th>{t('Address')}</th>
            <th>{t('Name')}</th>
            <th>{t('Created at')}</th>
            <th>{t('Updated at')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((value, i) => (
            <tr key={value.id}>
              <td>{value.id}</td>
              <td>{value.address}</td>
              <td>{value.name}</td>
              <td>{value.created_at}</td>
              <td>{value.updated_at}</td>
              <td>
                <Button
                  onClick={() => matchItem(value.id)}
                  className="btn btn-primary btn-sm mr-1"
                >
                  {t('No match')}
                </Button>
                <Button
                  onClick={() => toggleModal(i)}
                  className="btn btn-success btn-sm"
                >
                  <i className="fa fa-eye" />
                </Button>
              </td>
            </tr>
           ))}
        </tbody>
      </Table>

      {index !== null &&
        <Modal
          isOpen={toggle}
          toggle={() => toggleModal(null)}
          className="modal-lg"
        >
          <ModalHeader>{t('User')} - {data[index].name}</ModalHeader>
          <ModalBody>
            <Table
              responsive
              striped
            >
              <tbody>
                {Object.keys(data[index]).map((value, i) => (
                  <tr key={`${i}-key`}>
                    <td>{value.replace(/_/g, ' ')}</td>
                    <td>{data[index][value]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => toggleModal(null)}
            >
              {t('Close')}
            </Button>
          </ModalFooter>
        </Modal>
      }

      <Confirm
        t={t}
        show={showConfirm}
        title={t('No match')}
        text={t('Are you sure that this sanction is not related to current user?')}
        cancelCallback={() => toggleConfirm()}
        successCallback={defaultConfirmCallback}
      />
    </CardBody>
  </Card>
);

SanctionedBySwitzerland.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  toggleConfirm: PropTypes.func.isRequired,
  matchItem: PropTypes.func.isRequired,
  defaultConfirmCallback: PropTypes.func.isRequired,
};

export default SanctionedBySwitzerland;
