import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Permissions from 'react-redux-permissions';
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import getNavigation from '../../_nav';
import getRoutes from '../../routes';
import Footer from '../footer';
import Header from '../header';

const Layout = ({
  usersCount, permissions, t, ...props
}) => (
  <div className="app">
    <AppHeader fixed>
      <Header />
    </AppHeader>
    <div className="app-body">
      <AppSidebar fixed display="lg">
        <AppSidebarHeader />
        <AppSidebarForm />
        {/*Todo ...props - need review*/}
        <AppSidebarNav navConfig={getNavigation(t, permissions, usersCount)} {...props} />
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </AppSidebar>
      <main className="main">
        <AppBreadcrumb
          appRoutes={[...getRoutes(t)]}
        />
        <Container fluid>
          <Switch>
            {getRoutes(t).map(route => (route.component ? (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props2 => (
                  <Permissions allowed={route.permissions}>
                    <route.component {...props2} />
                  </Permissions>
                  )}
              />) : (null)))
            }
            {/*<Redirect from="/users" to="/users/index" />*/}
          </Switch>
        </Container>
      </main>
    </div>
    <AppFooter>
      <Footer />
    </AppFooter>
  </div>
);

Layout.propTypes = {
  t: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  usersCount: PropTypes.object.isRequired,
};

export default Layout;
