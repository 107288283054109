import { compose, hoistStatics } from 'recompose';
import { withFormik } from 'formik/dist/index';
import XlsxForm from './XlsxForm';
import rules from './rules';


const enhance = compose(withFormik({
  mapPropsToValues: () => ({
    file: null,
  }),
  validationSchema: rules,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: (values, {
    props: { uploadXlsxFile },
    setSubmitting,
  }) => {
    const formData = new FormData();
    formData.append('file', values.file);

    uploadXlsxFile(formData, {
      url: '/xlsx-files/upload',
      setSubmitting,
    });
  },
}));

export default hoistStatics(enhance)(XlsxForm);
