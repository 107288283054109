import React from 'react';
import { Form, Button, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

import InputGroup from '../../../../../../components/cms/form/inputGroup';

const EmailTemplateForm = ({
 values, errors, handleChange, handleSubmit, templateId, setTemplateId, deleteEmailTemplate, t,
}) => (
  <Form onSubmit={handleSubmit} className="email-templates_form">
    <FormGroup>
      <Label for="template_subject">{t('Subject')}</Label>
      <Input
        name="subject"
        id="email-template_subject"
        onChange={handleChange}
        value={values.subject}
        invalid={!!errors.subject}
      />
    </FormGroup>

    <FormGroup>
      <Label for="email-template_body">{t('Text')}</Label>
      <InputGroup
        name="body"
        id="email-template_body"
        type="editor"
        onChange={handleChange}
        value={values.body}
        invalid={!!errors.body}
      />
    </FormGroup>

    <div className="d-flex justify-content-end">
      <Button type="button" color="primary" hidden={!templateId} onClick={() => setTemplateId(null)}>
        {t('Create new one')}
      </Button>

      <Button type="submit" color={templateId ? 'success' : 'primary'}>
        {templateId ? t('Edit') : t('Create')}
      </Button>

      <Button
        type="button"
        color="danger"
        hidden={!templateId}
        onClick={() => {
          deleteEmailTemplate(templateId);
          setTemplateId(null);
        }}
      >
        {t('Delete')}
      </Button>
    </div>
  </Form>
);

EmailTemplateForm.propTypes = {
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  templateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  setTemplateId: PropTypes.func.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default EmailTemplateForm;

