import * as yup from 'yup';

export default yup.object().shape({
  username: yup.string().required({
    message: 'Username cannot be empty',
    params: { key: 'Username' },
  }),
  password: yup.string().required({
    message: 'Password cannot be empty',
    params: { key: 'Password' },
  }),
});
