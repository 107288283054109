import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchUsersOverviews, fetchUsersOverviewsSuccess, fetchUsersOverviewsRejected,
} = createActions(
  {
    [types.FETCH_USERS_OVERVIEWS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_USERS_OVERVIEWS_SUCCESS,
  types.FETCH_USERS_OVERVIEWS_REJECTED,
);

export default {
  fetchUsersOverviews,
  fetchUsersOverviewsSuccess,
  fetchUsersOverviewsRejected,
};
