import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ValidationMessage from '../../components/cms/form/validation/ValidationMessage';

const TwoFaForm = ({
  t, errors, values, handleSubmit, handleChange, clearUser,
}) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <p className="text-muted">{t('Сonfirmation code has been sent to your mail')}</p>
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      name="code"
                      value={values.code}
                      placeholder={t('Verification code')}
                      onChange={handleChange}
                      invalid={!!errors.code}
                    />
                    <ValidationMessage
                      message={t('Invalid code')}
                      valid={false}
                      wasValidated={!!errors.code}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs="6">
                      <Button
                        type="submit"
                        color="primary"
                        className="px-4"
                      >
                        {t('Login')}
                      </Button>
                    </Col>
                    <Col xs="6" className="text-right" >
                      <Row>
                        <Col lg={12}>
                          <Link to="#">{t('Send code again')}</Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Link
                            to="#"
                            onClick={clearUser}
                          >
                            {t('Back to Login page')}
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className="text-right" />
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

TwoFaForm.propTypes = {
  t: PropTypes.func.isRequired,
  // onChange: PropTypes.func.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  // valid: PropTypes.bool.isRequired,
};

export default TwoFaForm;
