import { createActions } from 'redux-actions';
import { nthArg, identity } from 'ramda';

import types from './types';

const {
  fetchSanctionedUsers,
  fetchSanctionedUsersSuccess,
  fetchSanctionedUsersRejected,

  fetchSanctionChecks,
  fetchSanctionChecksSuccess,
  fetchSanctionChecksRejected,
} = createActions(
  {
    [types.FETCH_SANCTIONED_USERS]: [
      identity,
      nthArg(1),
    ],
    [types.FETCH_SANCTION_CHECKS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_SANCTIONED_USERS_SUCCESS,
  types.FETCH_SANCTIONED_USERS_REJECTED,

  types.FETCH_SANCTION_CHECKS_SUCCESS,
  types.FETCH_SANCTION_CHECKS_REJECTED,
);

export default {
  fetchSanctionedUsers,
  fetchSanctionedUsersSuccess,
  fetchSanctionedUsersRejected,

  fetchSanctionChecks,
  fetchSanctionChecksSuccess,
  fetchSanctionChecksRejected,
};
