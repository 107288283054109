import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import MailNavigation from './components/navigation/MailNavigation';
import Pagination from '../../../src/components/cms/pagination';
import '../../assets/css/email.css';

const Mail = ({
  t, data, goToPage, count, lastPage, page, limit,
}) => (
  <div className="email-app mb-4">
    <MailNavigation t={t} />

    <main className="inbox">
      <Nav vertical className="messages">
        {data.map(value => (
          <NavItem className="message" key={value.conversationId}>
            <Link to={`inbox/${value.conversationId}`}>
              <div className="header">
                <span className="from">{`from: ${value.messageSender} - to: ${value.messageRecipient}`}</span>
                <span className="date"><span className="fa fa-paper-clip" />{value.lastMessageDate}</span>
              </div>
              <div className="title">{value.subject}</div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: value.messageIntro }}
              />
            </Link>
          </NavItem>
        ))}
      </Nav>

      <div className="pull-right">
        <Pagination
          goToPage={goToPage}
          count={count}
          pageSize={limit}
          page={page}
          lastPage={lastPage}
        />
      </div>
    </main>
  </div>
);

Mail.propTypes = {
  t: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default Mail;
