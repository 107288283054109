import { compose, hoistStatics, withState, withHandlers, lifecycle } from 'recompose';
import { translate } from 'react-i18next';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import fh from '../../helpers/form.helper';
import AuthService from '../../services/auth.service';

const enhance = compose(
  translate('core'),
  withState('password', 'setPassword', ''),
  withState('repeat', 'setRepeat', ''),
  withState('errors', 'setErrors', {}),
  withState('isSubmitting', 'setIsSubmitting', false),
  withState('isLoading', 'setIsLoading', true),
  withHandlers({
    onSubmit: props => async (event) => {
      event.preventDefault();
      props.setIsSubmitting(true);
      const { match: { params: { id, code } } } = props;
      const { errors } = await AuthService.changePassword(id, code, props.password, props.repeat);
      props.setIsSubmitting(false);
      if (errors) {
        props.setErrors(errors);
      } else {
        props.setErrors({});
        toast.success(props.t('Your password successfully changed'));
        props.history.push('/login');
      }
    },
    onChange: fh.onChange,
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      const { match: { params: { id, code } } } = props;
      const { status } = await AuthService.checkCode(id, code);
      if (status) {
        props.setIsLoading(false);
      } else {
        props.history.push('/login');
      }
    },
  }),
);

export default withRouter(hoistStatics(enhance)(ResetPassword));
