import { compose, withState, withContext } from 'recompose';
import { pick } from 'ramda';
import PropTypes from 'prop-types';

import EmailTemplates from './EmailTemplates';

const enhance = compose(
  withState('templateId', 'setTemplateId', null),
  withContext({
    templateId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.oneOf([null]),
    ]),
    setTemplateId: PropTypes.func,
  }, pick(['templateId', 'setTemplateId'])),
);

export default enhance(EmailTemplates);
