const NO_ATTACHMENTS = 0;
const NOT_IMPORTED = 1;
const IMPORTED_SUCCESSFULLY = 2;
const IMPORTED_WITH_ERRORS = 3;
const NOT_VALID = 4;

export default {
  NO_ATTACHMENTS,
  NOT_IMPORTED,
  IMPORTED_SUCCESSFULLY,
  IMPORTED_WITH_ERRORS,
  NOT_VALID,
};
