import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import Transactions from './Transactions';
import transactionActions from '../../reducers/transactions/actions';
import FirstLoading from '../../components/FirstLoading';
import apiRoutes from '../../services/api.routes';

const goToPage = props => (page) => {
  const { fetchTransactionsData, matchParams, url } = props;
  fetchTransactionsData({ ...matchParams, page, limit: 10 }, { url });
};

const changeStatus = ({ changeStatus, matchParams }) => (id, status) => {
  changeStatus({ id, status, userId: matchParams.id }, {
    url: apiRoutes.changeTransactionStatus,
  });
};

const mapStateToProps = state => ({
  data: state.transactions.data,
  lastPage: state.transactions.lastPage,
  page: state.transactions.page,
  perPage: state.transactions.perPage,
  total: state.transactions.total,
  loading: state.transactions.loading,
  firstLoading: state.transactions.firstLoading,
});

const mapDispatchToProps = {
  fetchTransactionsData: transactionActions.fetchTransactionsData,
  changeStatus: transactionActions.changeTransactionStatus,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchTransactionsData, matchParams, url } = this.props;
      fetchTransactionsData(matchParams || null, {
        url,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  withHandlers({
    goToPage,
    changeStatus,
  }),
);

export default hoistStatics(enhance)(Transactions);
