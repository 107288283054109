import { createActions } from 'redux-actions';
import { identity, nthArg } from 'ramda';

import types from './types';

const {
  fetchTransactionsData, fetchTransactionsDataSuccess, fetchTransactionsDataRejected,
  changeTransactionStatus, changeTransactionStatusSuccess, changeTransactionStatusRejected,
} = createActions(
  {
    [types.FETCH_TRANSACTIONS_DATA]: [
      identity,
      nthArg(1),
    ],
    [types.CHANGE_TRANSACTION_STATUS]: [
      identity,
      nthArg(1),
    ],
  },
  types.FETCH_TRANSACTIONS_DATA_SUCCESS,
  types.FETCH_TRANSACTIONS_DATA_REJECTED,
  types.CHANGE_TRANSACTION_STATUS_SUCCESS,
  types.CHANGE_TRANSACTION_STATUS_REJECTED,
);

export default {
  fetchTransactionsData,
  fetchTransactionsDataSuccess,
  fetchTransactionsDataRejected,
  changeTransactionStatus,
  changeTransactionStatusSuccess,
  changeTransactionStatusRejected,
};
