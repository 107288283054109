import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Parser } from 'html-to-react';
import Translations from './Translations';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/translation.service';
import gc from '../../components/cms/gridComponents';

const htmlToComponents = new Parser().parse;

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>)
  },
  {
    name: 'key',
    title: t('Key'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.key}</Link>)
    // value: ({ key }) => (<Badge color="secondary">{key}</Badge>),
  },
  {
    name: 'text',
    title: t('Text'),
    value: ({ text }) => htmlToComponents(text),
    className: '',
  },
  {
    name: 'namespace',
    title: t('Namespace'),
    value: ({ namespace }) => (<Badge color="primary">{ModelService.getNamespaceTitle(namespace, t)}</Badge>),
    filter: gc.selectFilter(ModelService.getNamespaceOptions(t)),
  },
  {
    name: 'is_html',
    title: t('Is HTML'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'is_html' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('core'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Translations);
