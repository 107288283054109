import { handleActions } from 'redux-actions';
import types from './types';
import { mergeByPath } from '../../helpers/store.helper';

const initialState = {
  data: {
    addresses: {
      entities: {},
      result: [],
    },
    contracts: {
      entities: {},
      result: [],
    },
    files: {
      entities: {},
      result: [],
    },
  },
  balance: {},
  loading: true,
  firstLoading: false,
  error: false,
  errors: {},
};

const reducer = handleActions(
  {
    [types.FETCH_EXTERNAL_USER]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
      errors: {},
    }),
    [types.FETCH_EXTERNAL_USER_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_EXTERNAL_USER_REJECTED]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      firstLoading: false,
      error: true,
      errors: {},
    }),


    [types.FETCH_EXTERNAL_USER_BALANCE]: (state, action) => ({
      ...state,
      balance: {},
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
      errors: {},
    }),
    [types.FETCH_EXTERNAL_USER_BALANCE_SUCCESS]: (state, action) => ({
      ...state,
      balance: action.payload,
      loading: false,
      firstLoading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_EXTERNAL_USER_BALANCE_REJECTED]: state => ({
      ...state,
      balance: {},
      loading: false,
      firstLoading: false,
      error: true,
      errors: {},
    }),

    [types.CHANGE_STATUS_ADDRESS]: mergeByPath(['data'], () => ({})),
    [types.CHANGE_STATUS_ADDRESS_SUCCESS]: mergeByPath(['data', 'addresses', 'entities'], action => action.payload),
    [types.CHANGE_STATUS_ADDRESS_REJECTED]: mergeByPath(['data'], () => ({})),

    [types.UPDATE_ATTRIBUTE_EXTERNAL_USER]: mergeByPath(['data'], () => ({})),
    [types.UPDATE_ATTRIBUTE_EXTERNAL_USER_SUCCESS]: mergeByPath(['data'], action => action.payload),
    [types.UPDATE_ATTRIBUTE_EXTERNAL_USER_REJECTED]: mergeByPath(['data'], () => ({})),

    [types.UPDATE_DOCUMENT_STATUS]: mergeByPath(['data'], () => ({})),
    [types.UPDATE_DOCUMENT_STATUS_SUCCESS]: mergeByPath(['data', 'files', 'entities'], action => action.payload),
    [types.UPDATE_ATTRIBUTE_EXTERNAL_USER_REJECTED]: mergeByPath(['data'], () => ({})),
  },
  initialState,
);

export default reducer;
