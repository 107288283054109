import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: {},
  loading: true,
  firstLoading: false,
  error: false,
  errors: {},
};

const updateStore = (state, action) => ({
  ...state,
  loading: true,
  firstLoading: action.meta.firstLoading,
  error: false,
  errors: {},
});

const updateStoreSuccess = (state, action) => ({
  ...state,
  data: action.payload,
  loading: false,
  firstLoading: false,
  error: false,
  errors: {},
});

const updateStoreRejected = (state, action) => ({
  ...state,
  loading: false,
  firstLoading: false,
  error: true,
  errors: action.payload.response,
});

const reducer = handleActions(
  {
    [types.FETCH_CD_USER]: updateStore,
    [types.FETCH_CD_USER_SUCCESS]: updateStoreSuccess,
    [types.FETCH_CD_USER_REJECTED]: updateStoreRejected,

    [types.UPDATE_CD_USER]: updateStore,
    [types.UPDATE_CD_USER_SUCCESS]: updateStoreSuccess,
    [types.UPDATE_CD_USER_REJECTED]: updateStoreRejected,

    [types.CREATE_CD_USER]: updateStore,
    [types.CREATE_CD_USER_SUCCESS]: updateStoreSuccess,
    [types.CREATE_CD_USER_REJECTED]: updateStoreRejected,

    [types.UPLOAD_CD_USER_IMAGE]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
      errors: {},
    }),
    [types.UPLOAD_CD_USER_IMAGE_SUCCESS]: (state, action) => ({
      ...state,
      data: { ...state.data, image: action.payload.filename },
      loading: false,
      error: false,
      errors: {},
    }),
    [types.UPLOAD_CD_USER_IMAGE_REJECTED]: (state, action) => ({
      ...state,
      // loading: true,
      // firstLoading: action.meta.firstLoading,
      // error: false,
      // errors: {},
    }),
  },
  initialState,
);

export default reducer;
