import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  link: '',
  loading: false,
  error: false,
};

const reducer = handleActions(
  {
    [types.EXPORT_USERS]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [types.EXPORT_USERS_SUCCESS]: (state, action) => ({
      ...state,
      link: action.payload.result,
      loading: false,
      error: false,
    }),
    [types.EXPORT_USERS_REJECTED]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

export default reducer;
