export default {
  UPDATE_TEMP_FILE: 'UPDATE_TEMP_FILE',
  UPDATE_TEMP_FILE_SUCCESS: 'UPDATE_TEMP_FILE_SUCCESS',
  UPDATE_TEMP_FILE_REJECTED: 'UPDATE_TEMP_FILE_REJECTED',

  REMOVE_TEMP_FILE: 'REMOVE_TEMP_FILE',
  REMOVE_TEMP_FILE_SUCCESS: 'REMOVE_TEMP_FILE_SUCCESS',
  REMOVE_TEMP_FILE_REJECTED: 'REMOVE_TEMP_FILE_REJECTED',

  CLEAR_ALL_TEMP_FILES: 'CLEAR_ALL_TEMP_FILES',
};
