import React from 'react';
import { compose, lifecycle, withProps, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { uploadActions } from '../../../reducers/upload';
import { userActions } from '../../../reducers/user';
import { roleActions } from '../../../reducers/role';
import { settingsActions } from '../../../reducers/settings';
import ModelService from '../../../services/user.service';
import apiRoutes from './../../../services/api.routes';

const wrapperComponent = (WrappedComponent) => {
  const Component = props => <WrappedComponent {...props} />;

  const mapStateToProps = state => ({
    userId: state.auth.user.data.id,
    image: state.user.data.image,
    user: state.user.data,
    firstLoading: state.user.firstLoading,
    loading: state.user.loading,
    allRoles: state.role.roles,
    reqErrors: state.user.errors,
    languages: state.settings.langs,
  });

  const mapDispatchToProps = {
    removeTempFile: uploadActions.removeTempFile,
    fetchCdUser: userActions.fetchCdUser,
    createCdUser: userActions.createCdUser,
    updateCdUser: userActions.updateCdUser,
    fetchRoles: roleActions.fetchRoles,
    fetchLanguages: settingsActions.fetchLanguages,
  };

  const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    translate('core'),
    defaultProps({
      getAvatar: ModelService.getAvatar,
    }),
    lifecycle({
      componentDidMount() {
        const { fetchRoles } = this.props;

        fetchRoles(null, {
          url: `${apiRoutes.roles}/all`,
          firstLoading: true,
        });
      },
    }),
    withProps(props => ({
      statusList: ModelService.getStatusOptions(props.t),
    })),
  );

  return enhance(Component);
};

export default wrapperComponent;
