import React from 'react';
import PropTypes from 'prop-types';
import { Table, Badge, Button, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import gh from './grid.helper';
import Pagination from '../pagination';
import SelectLimit from './seletLimit';
import ColumnTitle from './columnTitle';
import Confirm from '../../confirm';
import ColumnFilter from './columnFIlter';
import DataCell from './dataCell';
import AddNewButton from './addNewButton';

const Grid = ({
  t, data, showHeader, showFilter, columns, isDefaultCriteria, filter, service, count, resetFilter,
  updateData, goToPage, criteria, changePageSize, destroyItem, sort, hideConfirm, defaultConfirmCallback,
  showConfirm, permissionToDelete, showViewButton, lastPage, loading, page, limit, title, showAddButton,
}) => (
  <Row>
    <Col xl={12}>
      <Card>
        <CardHeader>
          <strong><i className="fa fa-users" /> {title}</strong>
        </CardHeader>
        <CardBody>
          {showAddButton &&
          <div className="pull-right mb-1">
            <AddNewButton
              to={service.getCreateUrl()}
              permissionToCreate={service.getCreatePermissions()}
            />
          </div>}

          {!isDefaultCriteria && (
            <div className="pull-left mr-3 reset-filter">
              <Button
                className="btn-brand btn-sm"
                type="button"
                onClick={() => resetFilter()}
              >
                <i className="fa fa-power-off mr-1" />
                {t('Reset')}
              </Button>
            </div>)}

          <Table
            responsive
            hover
            striped
            className={loading ? 'loading' : ''}
          >
            <thead>
              {showHeader && (
                <tr>
                  {columns.map(column => (
                    <ColumnTitle
                      key={gh.thId(column)}
                      column={column}
                      criteria={criteria}
                      sort={sort}
                    />))}
                </tr>)}
              {showFilter && (
                <tr>
                  {columns.map(column => (
                    <ColumnFilter
                      key={gh.fId(column)}
                      column={column}
                      criteria={criteria}
                      filter={filter}
                    />))}
                </tr>)}
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={gh.trId(item)}>
                  {columns.map(column => (
                    <DataCell
                      key={gh.tdId(item, column)}
                      service={service}
                      column={column}
                      item={item}
                      destroyItem={destroyItem}
                      // updateData={updateData}
                      showViewButton={false}
                    />))}
                </tr>))}
            </tbody>
            <tfoot>
              <tr className="small-tr">
                <td colSpan={columns.length - 1}>
                  <div className="pull-left mr-3 mt-2">
                    <i className="fa fa-database mr-1" />
                    <Badge> {t('Total')}: {count} {t('items')}</Badge>
                  </div>
                </td>
                <td>
                  <div className="pull-right">
                    <SelectLimit
                      changePageSize={changePageSize}
                      limit={parseInt(limit, 10)}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={columns.length} className="no-border">
                  <div className="pull-right">
                    <Pagination
                      goToPage={goToPage}
                      count={count}
                      pageSize={limit}
                      page={page}
                      lastPage={lastPage}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>

          <Confirm
            show={showConfirm}
            cancelCallback={hideConfirm}
            successCallback={defaultConfirmCallback}
          />

        </CardBody>
      </Card>
    </Col>
  </Row>
);

Grid.propTypes = {
  changePageSize: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ])),
  service: PropTypes.object.isRequired,
  criteria: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  destroyItem: PropTypes.func.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  defaultConfirmCallback: PropTypes.func,
  isDefaultCriteria: PropTypes.bool,
  sort: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  filter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  showFilter: PropTypes.bool,
  showHeader: PropTypes.bool,
  permissionToDelete: PropTypes.array,
};

Grid.defaultProps = {
  columns: ['id'],
  showFilter: true,
  showHeader: true,
};

export default Grid;
