import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import Loading from './Loading';

const CardLoading = () => (
  <div>
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong>
              <i className="fa fa-spin fa-circle-o-notch mr-1" />
              Loading...
            </strong>
          </CardHeader>
          <CardBody>
            <Loading />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default CardLoading;
