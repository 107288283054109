import { createSelector } from 'reselect';
import { prop, path, identity } from 'ramda';

const sanctionedUsers = createSelector(
  prop('sanctionedUsers'),
  identity,
);

const getSanctionChecks = createSelector(
  sanctionedUsers,
  path(['checks', 'data']),
);

const getSanctionLoading = createSelector(
  sanctionedUsers,
  path(['checks', 'loading']),
);

export {
  getSanctionChecks,
  getSanctionLoading,
};
