import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Loading from '../components/Loading';

export const checkUser = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => (
    state.auth.user.valid === true && state.auth.code.valid === false
  ),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
  AuthenticatingComponent: Loading,
});

export const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/two-fa-code',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => (
    state.auth.user.valid === true && state.auth.code.valid === true
  ),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,

  // authenticatingSelector: state => !state.user.user && state.auth.token,
  AuthenticatingComponent: Loading,
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => (
    state.auth.user.valid === false && state.auth.code.valid === false
  ),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
});
