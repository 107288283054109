import { handleActions } from 'redux-actions';
import types from './types';

const initialState = {
  data: [],
  total: 0,
  perPage: 10,
  page: 1,
  lastPage: 0,
  loading: true,
  firstLoading: false,
  error: false,
  errors: {},
};

const reducer = handleActions(
  {
    [types.FETCH_TRANSACTIONS_DATA]: (state, action) => ({
      ...state,
      loading: true,
      firstLoading: action.meta.firstLoading,
      error: false,
      errors: {},
    }),
    [types.FETCH_TRANSACTIONS_DATA_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
      total: action.payload.total,
      perPage: action.payload.perPage,
      page: action.payload.page,
      lastPage: action.payload.lastPage,
      loading: false,
      firstLoading: false,
      error: false,
      errors: {},
    }),
    [types.FETCH_TRANSACTIONS_DATA_REJECTED]: (state, action) => ({
      ...state,
      loading: false,
      firstLoading: false,
      error: true,
      errors: action.payload,
    }),


    [types.CHANGE_TRANSACTION_STATUS]: state => ({
      ...state,
      loading: true,
      error: false,
      errors: {},
    }),
    [types.CHANGE_TRANSACTION_STATUS_SUCCESS]: (state, action) => ({
      ...state,
      data: state.data.map((transaction) => {
        if (transaction.trxId === action.payload.trxId) {
          return {
            ...transaction,
            warningStatus: action.payload.warningStatus,
          };
        }
        return transaction;
      }),
      loading: false,
      error: false,
      errors: {},
    }),
    [types.CHANGE_TRANSACTION_STATUS_REJECTED]: (state, action) => ({
      ...state,
      loading: false,
      error: false,
      errors: action.payload,
    }),
  },
  initialState,
);

export default reducer;
