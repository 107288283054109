import { compose, hoistStatics, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ExternalUserView from './ExternalUserView';
import { externalUserActions } from '../../../reducers/externalUser';
import apiRoutes from '../../../services/api.routes';
import FirstLoading from '../../../components/FirstLoading';

const mapStateToProps = state => ({
  title: state.externalUser.data.username,
  externalUser: state.externalUser.data,
  loading: state.externalUser.loading,
  firstLoading: state.externalUser.firstLoading,
});

const mapDispatchToProps = {
  fetchExternalUser: externalUserActions.fetchExternalUser,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  lifecycle({
    componentDidMount() {
      const { fetchExternalUser, match } = this.props;

      fetchExternalUser(match.params, {
        url: apiRoutes.getExtUserData,
        firstLoading: true,
      });
    },
  }),
  FirstLoading,
  withHandlers({}),
);

export default (hoistStatics(enhance)(ExternalUserView));
